import {useState} from 'react'
import {useIncrementCounterTaskOfStudent} from '../../../../admin/hooks/useTaskAndStudents'
import {truncateText} from '../../../../helpers/Helpers'
import {RightTaskProps} from './_model'

const LastTask: React.FC<RightTaskProps> = ({task}) => {
  const [taskStudentId, setTaskStudentId] = useState<number>()
  const {mutate} = useIncrementCounterTaskOfStudent(taskStudentId || 0)
  if (task === undefined) {
    console.log('Undefined Last task')
    return <></>
  }

  const handleUserAction = (taskId: number) => {
    setTaskStudentId(taskId)
    const data = {
      id: taskId,
      isCompleted: false,
      counter: 0,
    } //we can't send it empty
    mutate(data) // parameters  required
  }
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1324 1192'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 13' clip-path='url(#clip0_475_685)'>
        <g id='Frame 15' clip-path='url(#clip1_475_685)'>
          <g id='rod'>
            <path
              id='Rectangle_7-3_00000178898302042835176950000017492108411439301514_'
              d='M633.68 578.772C642.28 589.372 657.88 590.872 668.48 582.272L3225.28 -926.128C3235.88 -934.728 3237.38 -950.328 3228.78 -960.928C3220.18 -971.528 3204.58 -973.028 3193.98 -964.428L637.08 543.972C626.58 552.672 624.98 568.272 633.68 578.772Z'
              fill='#F6F9FD'
            />
            <g id='Group'>
              <g id='Group_2'>
                <g id='Group_3'>
                  <path
                    id='Rectangle_7-4_00000048480217740374218700000008369376055510411681_'
                    d='M633.5 578.832C642.1 589.432 657.6 591.032 668.2 582.432L3225.6 -920.668C3236.2 -929.268 3237.8 -944.768 3229.2 -955.368C3220.6 -965.968 3205.1 -967.568 3194.5 -958.968L637.1 544.132C626.6 552.732 624.9 568.232 633.5 578.832Z'
                    fill='white'
                  />
                </g>
              </g>
              <g id='Group_4'>
                <g id='Group_5'>
                  <path
                    id='Rectangle_7-4_00000070085869070698666220000015600745847735054523_'
                    d='M633.5 578.832C642.1 589.432 657.6 591.032 668.2 582.432L3225.6 -920.668C3236.2 -929.268 3237.8 -944.768 3229.2 -955.368C3220.6 -965.968 3205.1 -967.568 3194.5 -958.968L637.1 544.132C626.6 552.732 624.9 568.232 633.5 578.832Z'
                    fill='#0686EF'
                  />
                </g>
              </g>
            </g>
            <g id='Group_6'>
              <path
                id='Rectangle_7-4_00000072959898392329376720000005484775376303735430_'
                d='M633.5 578.832C642.1 589.432 657.6 591.032 668.2 582.432L3225.6 -920.668C3236.2 -929.268 3237.8 -944.768 3229.2 -955.368C3220.6 -965.968 3205.1 -967.568 3194.5 -958.968L637.1 544.132C626.6 552.732 624.9 568.232 633.5 578.832Z'
                stroke='#39A2EA'
                stroke-width='18'
                stroke-miterlimit='10'
              />
            </g>
          </g>
          <g id='Group_7'>
            {task[0] !== undefined && task[0] !== null && (
              <g id='task-1'>
                <g id='rod-1'>
                  <g id='Rectangle_6-5_00000070096008234557779730000007775761281895671692_'>
                    <g id='Group_8'>
                      <path
                        id='Vector'
                        d='M687.709 616.1C688.309 612.9 691.509 610.8 694.609 611.5L1064.01 685.8C1067.21 686.4 1069.31 689.6 1068.61 692.7C1068.01 695.9 1064.81 698 1061.71 697.3L692.309 623C689.109 622.4 687.009 619.3 687.709 616.1Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_9'>
                      <path
                        id='Vector_2'
                        d='M687.709 616.1C688.309 612.9 691.509 610.8 694.609 611.5L1064.01 685.8C1067.21 686.4 1069.31 689.6 1068.61 692.7C1068.01 695.9 1064.81 698 1061.71 697.3L692.309 623C689.109 622.4 687.009 619.3 687.709 616.1Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_10'>
                    <path
                      id='Rectangle_6-6_00000126288785251545338680000004883818606795449000_'
                      d='M687.699 616.161C688.299 612.961 691.5 610.861 694.6 611.561L1064 685.861C1067.2 686.461 1069.3 689.661 1068.6 692.761C1068 695.961 1064.8 698.061 1061.7 697.361L692.299 623.061C689.099 622.461 686.999 619.361 687.699 616.161Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-1'>
                  <g id='rods'>
                    <g id='Group_11'>
                      <path
                        id='Rectangle_8-13_00000034052580161481247730000002089021037518764454_'
                        d='M1091.01 685.998C1089.21 683.298 1089.91 679.598 1092.61 677.798L1148.51 640.598C1151.21 638.798 1154.91 639.498 1156.71 642.198C1158.51 644.898 1157.81 648.598 1155.11 650.398L1099.21 687.598C1096.41 689.498 1092.81 688.698 1091.01 685.998Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_12'>
                        <path
                          id='Rectangle_8-14_00000137101153395173735190000005940460355052548263_'
                          d='M1091 685.959C1089.2 683.259 1089.9 679.559 1092.6 677.759L1148.5 640.559C1151.2 638.759 1154.9 639.459 1156.7 642.159C1158.5 644.859 1157.8 648.559 1155.1 650.359L1099.2 687.559C1096.4 689.459 1092.8 688.759 1091 685.959Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_13'>
                      <path
                        id='Rectangle_9-13_00000015344919034593173670000017839587373142253714_'
                        d='M1087.71 704.498C1090.41 702.598 1094.11 703.298 1095.91 705.998L1134.11 761.198C1136.01 763.898 1135.31 767.598 1132.61 769.398C1129.91 771.298 1126.21 770.598 1124.41 767.898L1086.21 712.698C1084.41 710.098 1085.01 706.398 1087.71 704.498Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_14'>
                        <path
                          id='Rectangle_9-14_00000019673800255520320620000003342879121762514064_'
                          d='M1087.7 704.559C1090.4 702.659 1094.1 703.359 1095.9 706.059L1134.1 761.259C1136 763.959 1135.3 767.659 1132.6 769.459C1129.9 771.359 1126.2 770.659 1124.4 767.959L1086.2 712.759C1084.4 710.059 1085.1 706.359 1087.7 704.559Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1'>
                    <g id='Group_15'>
                      <path
                        id='Ellipse_16-13_00000027596049906126620710000015185147021101629881_'
                        d='M1080.51 712.499C1090.67 712.499 1098.91 704.261 1098.91 694.099C1098.91 683.937 1090.67 675.699 1080.51 675.699C1070.35 675.699 1062.11 683.937 1062.11 694.099C1062.11 704.261 1070.35 712.499 1080.51 712.499Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_16'>
                        <path
                          id='Ellipse_16-14_00000062158598971590557720000010720332964569200520_'
                          d='M1080.5 712.46C1090.66 712.46 1098.9 704.222 1098.9 694.06C1098.9 683.898 1090.66 675.66 1080.5 675.66C1070.34 675.66 1062.1 683.898 1062.1 694.06C1062.1 704.222 1070.34 712.46 1080.5 712.46Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000125565500701920850400000007810652670242527128_'
                      d='M1067.11 684.1L1064.31 699.3C1063.91 701.7 1061.61 703.3 1059.21 702.8C1056.81 702.4 1055.21 700.1 1055.71 697.7L1058.51 682.5C1058.91 680.1 1061.21 678.5 1063.61 679C1066.01 679.5 1067.51 681.8 1067.11 684.1Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task'
                      transform='translate(1066.5 684.594) rotate(100.369)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_17' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000100348656773454566310000002577341095594420115_'
                        d='M1080.5 706.759C1087.51 706.759 1093.2 701.073 1093.2 694.059C1093.2 687.045 1087.51 681.359 1080.5 681.359C1073.49 681.359 1067.8 687.045 1067.8 694.059C1067.8 701.073 1073.49 706.759 1080.5 706.759Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2'>
                    <g id='Group_18'>
                      <path
                        id='Ellipse_21-13_00000066514836454150709390000012651750937850418602_'
                        d='M1165.21 653.999C1175.37 653.999 1183.61 645.761 1183.61 635.599C1183.61 625.437 1175.37 617.199 1165.21 617.199C1155.05 617.199 1146.81 625.437 1146.81 635.599C1146.81 645.761 1155.05 653.999 1165.21 653.999Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_19'>
                        <path
                          id='Ellipse_21-14_00000021807734514334734970000016735364006423778229_'
                          d='M1165.2 653.96C1175.36 653.96 1183.6 645.722 1183.6 635.56C1183.6 625.398 1175.36 617.16 1165.2 617.16C1155.04 617.16 1146.8 625.398 1146.8 635.56C1146.8 645.722 1155.04 653.96 1165.2 653.96Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000049191865184245665930000013144252002222993599_'
                      d='M1153.81 625.899L1151.01 641.099C1150.61 643.499 1148.31 645.099 1145.91 644.599C1143.51 644.199 1141.91 641.899 1142.41 639.499L1145.21 624.299C1145.61 621.899 1147.91 620.299 1150.31 620.799C1152.61 621.199 1154.21 623.499 1153.81 625.899Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_2'
                      transform='translate(1154.37 625.238) rotate(100.369)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_20' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000053518003853983351980000003769127290620533949_'
                        d='M1165.2 648.361C1172.21 648.361 1177.9 642.675 1177.9 635.661C1177.9 628.647 1172.21 622.961 1165.2 622.961C1158.19 622.961 1152.5 628.647 1152.5 635.661C1152.5 642.675 1158.19 648.361 1165.2 648.361Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3'>
                    <g id='Group_21'>
                      <path
                        id='Ellipse_18-13_00000157277481762533442370000000158111773512623761_'
                        d='M1139.51 797.902C1149.67 797.902 1157.91 789.664 1157.91 779.502C1157.91 769.34 1149.67 761.102 1139.51 761.102C1129.35 761.102 1121.11 769.34 1121.11 779.502C1121.11 789.664 1129.35 797.902 1139.51 797.902Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_22'>
                        <path
                          id='Ellipse_18-14_00000179643254746031824140000011470129397128925876_'
                          d='M1139.5 797.962C1149.66 797.962 1157.9 789.724 1157.9 779.562C1157.9 769.4 1149.66 761.162 1139.5 761.162C1129.34 761.162 1121.1 769.4 1121.1 779.562C1121.1 789.724 1129.34 797.962 1139.5 797.962Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000170258770122595417860000001671254584015109049_'
                      d='M1127.61 769.102L1124.81 784.302C1124.41 786.702 1122.11 788.302 1119.71 787.802C1117.31 787.402 1115.71 785.102 1116.21 782.702L1119.01 767.502C1119.41 765.102 1121.71 763.502 1124.11 764.002C1126.41 764.402 1128.01 766.702 1127.61 769.102Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_3'
                      transform='translate(1128.17 768.441) rotate(100.369)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_23' opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000112632301565165813690000006716365976360689835_'
                        d='M1139.5 792.261C1146.51 792.261 1152.2 786.575 1152.2 779.561C1152.2 772.547 1146.51 766.861 1139.5 766.861C1132.49 766.861 1126.8 772.547 1126.8 779.561C1126.8 786.575 1132.49 792.261 1139.5 792.261Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[0].TasksAndStudents?.id || 0)}>
                  <g id='box-1'>
                    <g id='Group_24'>
                      <g id='Group_25'>
                        <path
                          id='Vector_3'
                          opacity='0.0196078'
                          d='M879.809 678.998C878.709 684.998 882.709 690.798 888.709 691.898L924.809 698.498C930.809 699.598 936.609 695.598 937.709 689.598L944.309 653.498C945.409 647.498 941.409 641.698 935.409 640.598L899.309 633.998C893.309 632.898 887.509 636.898 886.409 642.898L879.809 678.998Z'
                          fill='#FBFCFD'
                        />
                        <path
                          id='Vector_4'
                          opacity='0.0392157'
                          d='M880.009 678.899C878.909 684.899 882.909 690.699 888.909 691.799L925.009 698.399C931.009 699.499 936.809 695.499 937.909 689.499L944.509 653.399C945.609 647.399 941.609 641.599 935.609 640.499L899.509 633.899C893.509 632.799 887.709 636.799 886.609 642.799L880.009 678.899Z'
                          fill='#F8F9FB'
                        />
                        <path
                          id='Vector_5'
                          opacity='0.0588235'
                          d='M880.11 678.899C879.01 684.899 883.01 690.699 889.01 691.799L925.11 698.399C931.11 699.499 936.91 695.499 938.01 689.499L944.61 653.399C945.71 647.399 941.71 641.599 935.71 640.499L899.61 633.899C893.61 632.799 887.81 636.799 886.71 642.799L880.11 678.899Z'
                          fill='#F4F6F9'
                        />
                        <path
                          id='Vector_6'
                          opacity='0.0784314'
                          d='M880.209 678.799C879.109 684.799 883.109 690.599 889.109 691.699L925.209 698.299C931.209 699.399 937.009 695.399 938.109 689.399L944.709 653.299C945.809 647.299 941.809 641.499 935.809 640.399L899.709 633.799C893.709 632.699 887.909 636.699 886.809 642.699L880.209 678.799Z'
                          fill='#F1F3F7'
                        />
                        <path
                          id='Vector_7'
                          opacity='0.0980392'
                          d='M880.309 678.7C879.209 684.7 883.209 690.5 889.209 691.6L925.309 698.2C931.309 699.3 937.109 695.3 938.209 689.3L944.809 653.2C945.909 647.2 941.909 641.4 935.909 640.3L899.809 633.7C893.809 632.6 888.009 636.6 886.909 642.6L880.309 678.7Z'
                          fill='#EDF0F4'
                        />
                        <path
                          id='Vector_8'
                          opacity='0.1176'
                          d='M880.41 678.6C879.31 684.6 883.31 690.4 889.31 691.5L925.41 698.1C931.41 699.2 937.21 695.2 938.31 689.2L944.91 653.1C946.01 647.1 942.01 641.3 936.01 640.2L899.91 633.6C893.91 632.5 888.11 636.5 887.01 642.5L880.41 678.6Z'
                          fill='#EAEDF2'
                        />
                        <path
                          id='Vector_9'
                          opacity='0.1373'
                          d='M880.509 678.6C879.409 684.6 883.409 690.4 889.409 691.5L925.509 698.1C931.509 699.2 937.309 695.2 938.409 689.2L945.009 653.1C946.109 647.1 942.109 641.3 936.109 640.2L900.009 633.6C894.009 632.5 888.209 636.5 887.109 642.5L880.509 678.6Z'
                          fill='#E6EAF0'
                        />
                        <path
                          id='Vector_10'
                          opacity='0.1569'
                          d='M880.61 678.498C879.51 684.498 883.51 690.298 889.51 691.398L925.61 697.998C931.61 699.098 937.41 695.098 938.51 689.098L945.11 652.998C946.21 646.998 942.21 641.198 936.21 640.098L900.11 633.498C894.11 632.398 888.31 636.398 887.21 642.398L880.61 678.498Z'
                          fill='#E3E7EE'
                        />
                        <path
                          id='Vector_11'
                          opacity='0.1765'
                          d='M880.709 678.399C879.609 684.399 883.609 690.199 889.609 691.299L925.709 697.899C931.709 698.999 937.509 694.999 938.609 688.999L945.209 652.899C946.309 646.899 942.309 641.099 936.309 639.999L900.209 633.399C894.209 632.299 888.409 636.299 887.309 642.299L880.709 678.399Z'
                          fill='#DFE3EC'
                        />
                        <path
                          id='Vector_12'
                          opacity='0.1961'
                          d='M880.809 678.299C879.709 684.299 883.709 690.099 889.709 691.199L925.809 697.799C931.809 698.899 937.609 694.899 938.709 688.899L945.309 652.799C946.409 646.799 942.409 640.999 936.409 639.899L900.309 633.299C894.309 632.199 888.509 636.199 887.409 642.199L880.809 678.299Z'
                          fill='#DCE0EA'
                        />
                        <path
                          id='Vector_13'
                          opacity='0.2157'
                          d='M880.91 678.299C879.81 684.299 883.81 690.099 889.81 691.199L925.91 697.799C931.91 698.899 937.71 694.899 938.81 688.899L945.41 652.799C946.51 646.799 942.51 640.999 936.51 639.899L900.41 633.299C894.41 632.199 888.61 636.199 887.51 642.199L880.91 678.299Z'
                          fill='#D8DDE8'
                        />
                        <path
                          id='Vector_14'
                          opacity='0.2353'
                          d='M881.009 678.2C879.909 684.2 883.909 690 889.909 691.1L926.009 697.7C932.009 698.8 937.809 694.8 938.909 688.8L945.509 652.7C946.609 646.7 942.609 640.9 936.609 639.8L900.509 633.2C894.509 632.1 888.709 636.1 887.609 642.1L881.009 678.2Z'
                          fill='#D4DAE6'
                        />
                        <path
                          id='Vector_15'
                          opacity='0.2549'
                          d='M881.11 678.1C880.01 684.1 884.01 689.9 890.01 691L926.11 697.6C932.11 698.7 937.91 694.7 939.01 688.7L945.61 652.6C946.71 646.6 942.71 640.8 936.71 639.7L900.61 633.1C894.61 632 888.81 636 887.71 642L881.11 678.1Z'
                          fill='#D1D7E3'
                        />
                        <path
                          id='Vector_16'
                          opacity='0.2745'
                          d='M881.209 677.998C880.109 683.998 884.109 689.798 890.109 690.898L926.209 697.498C932.209 698.598 938.009 694.598 939.109 688.598L945.709 652.498C946.809 646.498 942.809 640.698 936.809 639.598L900.709 632.998C894.709 631.898 888.909 635.898 887.809 641.898L881.209 677.998Z'
                          fill='#CDD4E1'
                        />
                        <path
                          id='Vector_17'
                          opacity='0.2941'
                          d='M881.41 677.998C880.31 683.998 884.31 689.798 890.31 690.898L926.41 697.498C932.41 698.598 938.21 694.598 939.31 688.598L945.91 652.498C947.01 646.498 943.01 640.698 937.01 639.598L900.91 632.998C894.91 631.898 889.11 635.898 888.01 641.898L881.41 677.998Z'
                          fill='#CAD1DF'
                        />
                        <path
                          id='Vector_18'
                          opacity='0.3137'
                          d='M881.509 677.899C880.409 683.899 884.409 689.699 890.409 690.799L926.509 697.399C932.509 698.499 938.309 694.499 939.409 688.499L946.009 652.399C947.109 646.399 943.109 640.599 937.109 639.499L901.009 632.899C895.009 631.799 889.209 635.799 888.109 641.799L881.509 677.899Z'
                          fill='#C6CEDD'
                        />
                        <path
                          id='Vector_19'
                          opacity='0.3333'
                          d='M881.61 677.799C880.51 683.799 884.51 689.599 890.51 690.699L926.61 697.299C932.61 698.399 938.41 694.399 939.51 688.399L946.11 652.299C947.21 646.299 943.21 640.499 937.21 639.399L901.11 632.799C895.11 631.699 889.31 635.699 888.21 641.699L881.61 677.799Z'
                          fill='#C3CBDB'
                        />
                        <path
                          id='Vector_20'
                          opacity='0.3529'
                          d='M881.709 677.7C880.609 683.7 884.609 689.5 890.609 690.6L926.709 697.2C932.709 698.3 938.509 694.3 939.609 688.3L946.209 652.2C947.309 646.2 943.309 640.4 937.309 639.3L901.209 632.7C895.209 631.6 889.409 635.6 888.309 641.6L881.709 677.7Z'
                          fill='#BFC8D9'
                        />
                        <path
                          id='Vector_21'
                          opacity='0.3725'
                          d='M881.809 677.7C880.709 683.7 884.709 689.5 890.709 690.6L926.809 697.2C932.809 698.3 938.609 694.3 939.709 688.3L946.309 652.2C947.409 646.2 943.409 640.4 937.409 639.3L901.309 632.7C895.309 631.6 889.509 635.6 888.409 641.6L881.809 677.7Z'
                          fill='#BCC5D7'
                        />
                        <path
                          id='Vector_22'
                          opacity='0.3922'
                          d='M881.91 677.6C880.81 683.6 884.81 689.4 890.81 690.5L926.91 697.1C932.91 698.2 938.71 694.2 939.81 688.2L946.41 652.1C947.51 646.1 943.51 640.3 937.51 639.2L901.41 632.6C895.41 631.5 889.61 635.5 888.51 641.5L881.91 677.6Z'
                          fill='#B8C2D5'
                        />
                        <path
                          id='Vector_23'
                          opacity='0.4118'
                          d='M882.009 677.498C880.909 683.498 884.909 689.298 890.909 690.398L927.009 696.998C933.009 698.098 938.809 694.098 939.909 688.098L946.509 651.998C947.609 645.998 943.609 640.198 937.609 639.098L901.509 632.498C895.509 631.398 889.709 635.398 888.609 641.398L882.009 677.498Z'
                          fill='#B4BFD3'
                        />
                        <path
                          id='Vector_24'
                          opacity='0.4314'
                          d='M882.11 677.498C881.01 683.498 885.01 689.298 891.01 690.398L927.11 696.998C933.11 698.098 938.91 694.098 940.01 688.098L946.61 651.998C947.71 645.998 943.71 640.198 937.71 639.098L901.61 632.498C895.61 631.398 889.81 635.398 888.71 641.398L882.11 677.498Z'
                          fill='#B1BCD0'
                        />
                        <path
                          id='Vector_25'
                          opacity='0.451'
                          d='M882.209 677.399C881.109 683.399 885.109 689.199 891.109 690.299L927.209 696.899C933.209 697.999 939.009 693.999 940.109 687.999L946.709 651.899C947.809 645.899 943.809 640.099 937.809 638.999L901.709 632.399C895.709 631.299 889.909 635.299 888.809 641.299L882.209 677.399Z'
                          fill='#ADB9CE'
                        />
                        <path
                          id='Vector_26'
                          opacity='0.4706'
                          d='M882.309 677.299C881.209 683.299 885.209 689.099 891.209 690.199L927.309 696.799C933.309 697.899 939.109 693.899 940.209 687.899L946.809 651.799C947.909 645.799 943.909 639.999 937.909 638.899L901.809 632.299C895.809 631.199 890.009 635.199 888.909 641.199L882.309 677.299Z'
                          fill='#AAB6CC'
                        />
                        <path
                          id='Vector_27'
                          opacity='0.4902'
                          d='M882.41 677.2C881.31 683.2 885.31 689 891.31 690.1L927.41 696.7C933.41 697.8 939.21 693.8 940.31 687.8L946.91 651.7C948.01 645.7 944.01 639.9 938.01 638.8L901.91 632.2C895.91 631.1 890.11 635.1 889.01 641.1L882.41 677.2Z'
                          fill='#A6B3CA'
                        />
                        <path
                          id='Vector_28'
                          opacity='0.5098'
                          d='M882.509 677.2C881.409 683.2 885.409 689 891.409 690.1L927.509 696.7C933.509 697.8 939.309 693.8 940.409 687.8L947.009 651.7C948.109 645.7 944.109 639.9 938.109 638.8L902.009 632.2C896.009 631.1 890.209 635.1 889.109 641.1L882.509 677.2Z'
                          fill='#A3AFC8'
                        />
                        <path
                          id='Vector_29'
                          opacity='0.5294'
                          d='M882.61 677.1C881.51 683.1 885.51 688.9 891.51 690L927.61 696.6C933.61 697.7 939.41 693.7 940.51 687.7L947.11 651.6C948.21 645.6 944.21 639.8 938.21 638.7L902.11 632.1C896.11 631 890.31 635 889.21 641L882.61 677.1Z'
                          fill='#9FACC6'
                        />
                        <path
                          id='Vector_30'
                          opacity='0.549'
                          d='M882.809 676.998C881.709 682.998 885.709 688.798 891.709 689.898L927.809 696.498C933.809 697.598 939.609 693.598 940.709 687.598L947.309 651.498C948.409 645.498 944.409 639.698 938.409 638.598L902.309 631.998C896.309 630.898 890.509 634.898 889.409 640.898L882.809 676.998Z'
                          fill='#9CA9C4'
                        />
                        <path
                          id='Vector_31'
                          opacity='0.5686'
                          d='M882.91 676.899C881.81 682.899 885.81 688.699 891.81 689.799L927.91 696.399C933.91 697.499 939.71 693.499 940.81 687.499L947.41 651.399C948.51 645.399 944.51 639.599 938.51 638.499L902.41 631.899C896.41 630.799 890.61 634.799 889.51 640.799L882.91 676.899Z'
                          fill='#98A6C2'
                        />
                        <path
                          id='Vector_32'
                          opacity='0.5882'
                          d='M883.009 676.899C881.909 682.899 885.909 688.699 891.909 689.799L928.009 696.399C934.009 697.499 939.809 693.499 940.909 687.499L947.509 651.399C948.609 645.399 944.609 639.599 938.609 638.499L902.509 631.799C896.509 630.699 890.709 634.699 889.609 640.699L883.009 676.899Z'
                          fill='#95A3BF'
                        />
                        <path
                          id='Vector_33'
                          opacity='0.6078'
                          d='M883.11 676.799C882.01 682.799 886.01 688.599 892.01 689.699L928.11 696.299C934.11 697.399 939.91 693.399 941.01 687.399L947.61 651.299C948.71 645.299 944.71 639.499 938.71 638.399L902.61 631.799C896.61 630.699 890.81 634.699 889.71 640.699L883.11 676.799Z'
                          fill='#91A0BD'
                        />
                        <path
                          id='Vector_34'
                          opacity='0.6275'
                          d='M883.209 676.7C882.109 682.7 886.109 688.5 892.109 689.6L928.209 696.2C934.209 697.3 940.009 693.3 941.109 687.3L947.709 651.2C948.809 645.2 944.809 639.4 938.809 638.3L902.709 631.7C896.709 630.6 890.909 634.6 889.809 640.6L883.209 676.7Z'
                          fill='#8D9DBB'
                        />
                        <path
                          id='Vector_35'
                          opacity='0.6471'
                          d='M883.309 676.6C882.209 682.6 886.209 688.4 892.209 689.5L928.309 696.1C934.309 697.2 940.109 693.2 941.209 687.2L947.809 651.1C948.909 645.1 944.909 639.3 938.909 638.2L902.809 631.6C896.809 630.5 891.009 634.5 889.909 640.5L883.309 676.6Z'
                          fill='#8A9AB9'
                        />
                        <path
                          id='Vector_36'
                          opacity='0.6667'
                          d='M883.41 676.6C882.31 682.6 886.31 688.4 892.31 689.5L928.41 696.1C934.41 697.2 940.21 693.2 941.31 687.2L947.91 651.1C949.01 645.1 945.01 639.3 939.01 638.2L902.91 631.6C896.91 630.5 891.11 634.5 890.01 640.5L883.41 676.6Z'
                          fill='#8697B7'
                        />
                        <path
                          id='Vector_37'
                          opacity='0.6863'
                          d='M883.509 676.498C882.409 682.498 886.409 688.298 892.409 689.398L928.509 695.998C934.509 697.098 940.309 693.098 941.409 687.098L948.009 650.998C949.109 644.998 945.109 639.198 939.109 638.098L903.009 631.498C897.009 630.398 891.209 634.398 890.109 640.398L883.509 676.498Z'
                          fill='#8394B5'
                        />
                        <path
                          id='Vector_38'
                          opacity='0.7059'
                          d='M883.61 676.399C882.51 682.399 886.51 688.199 892.51 689.299L928.61 695.899C934.61 696.999 940.41 692.999 941.51 686.999L948.11 650.899C949.21 644.899 945.21 639.099 939.21 637.999L903.11 631.399C897.11 630.299 891.31 634.299 890.21 640.299L883.61 676.399Z'
                          fill='#7F91B3'
                        />
                        <path
                          id='Vector_39'
                          opacity='0.7255'
                          d='M883.709 676.299C882.609 682.299 886.609 688.099 892.609 689.199L928.709 695.799C934.709 696.899 940.509 692.899 941.609 686.899L948.209 650.799C949.309 644.799 945.309 638.999 939.309 637.899L903.209 631.299C897.209 630.199 891.409 634.199 890.309 640.199L883.709 676.299Z'
                          fill='#7C8EB1'
                        />
                        <path
                          id='Vector_40'
                          opacity='0.7451'
                          d='M883.809 676.299C882.709 682.299 886.709 688.099 892.709 689.199L928.809 695.799C934.809 696.899 940.609 692.899 941.709 686.899L948.309 650.799C949.409 644.799 945.409 638.999 939.409 637.899L903.309 631.299C897.309 630.199 891.509 634.199 890.409 640.199L883.809 676.299Z'
                          fill='#788BAF'
                        />
                        <path
                          id='Vector_41'
                          opacity='0.7647'
                          d='M883.91 676.2C882.81 682.2 886.81 688 892.81 689.1L928.91 695.7C934.91 696.8 940.71 692.8 941.81 686.8L948.41 650.7C949.51 644.7 945.51 638.9 939.51 637.8L903.41 631.2C897.41 630.1 891.61 634.1 890.51 640.1L883.91 676.2Z'
                          fill='#7588AC'
                        />
                        <path
                          id='Vector_42'
                          opacity='0.7843'
                          d='M884.009 676.1C882.909 682.1 886.909 687.9 892.909 689L929.009 695.6C935.009 696.7 940.809 692.7 941.909 686.7L948.509 650.6C949.609 644.6 945.609 638.8 939.609 637.7L903.509 631.1C897.509 630 891.709 634 890.609 640L884.009 676.1Z'
                          fill='#7185AA'
                        />
                        <path
                          id='Vector_43'
                          opacity='0.8039'
                          d='M884.209 675.998C883.109 681.998 887.109 687.798 893.109 688.898L929.209 695.498C935.209 696.598 941.009 692.598 942.109 686.598L948.709 650.498C949.809 644.498 945.809 638.698 939.809 637.598L903.709 630.998C897.709 629.898 891.909 633.898 890.809 639.898L884.209 675.998Z'
                          fill='#6D82A8'
                        />
                        <path
                          id='Vector_44'
                          opacity='0.8235'
                          d='M884.309 675.998C883.209 681.998 887.209 687.798 893.209 688.898L929.309 695.498C935.309 696.598 941.109 692.598 942.209 686.598L948.809 650.498C949.909 644.498 945.909 638.698 939.909 637.598L903.809 630.998C897.809 629.898 892.009 633.898 890.909 639.898L884.309 675.998Z'
                          fill='#6A7FA6'
                        />
                        <path
                          id='Vector_45'
                          opacity='0.8431'
                          d='M884.41 675.899C883.31 681.899 887.31 687.699 893.31 688.799L929.41 695.399C935.41 696.499 941.21 692.499 942.31 686.499L948.91 650.399C950.01 644.399 946.01 638.599 940.01 637.499L903.91 630.899C897.91 629.799 892.11 633.799 891.01 639.799L884.41 675.899Z'
                          fill='#667BA4'
                        />
                        <path
                          id='Vector_46'
                          opacity='0.8627'
                          d='M884.509 675.799C883.409 681.799 887.409 687.599 893.409 688.699L929.509 695.299C935.509 696.399 941.309 692.399 942.409 686.399L949.009 650.299C950.109 644.299 946.109 638.499 940.109 637.399L904.009 630.799C898.009 629.699 892.209 633.699 891.109 639.699L884.509 675.799Z'
                          fill='#6378A2'
                        />
                        <path
                          id='Vector_47'
                          opacity='0.8824'
                          d='M884.61 675.7C883.51 681.7 887.51 687.5 893.51 688.6L929.61 695.2C935.61 696.3 941.41 692.3 942.51 686.3L949.11 650.2C950.21 644.2 946.21 638.4 940.21 637.3L904.11 630.7C898.11 629.6 892.31 633.6 891.21 639.6L884.61 675.7Z'
                          fill='#5F75A0'
                        />
                        <path
                          id='Vector_48'
                          opacity='0.902'
                          d='M884.709 675.7C883.609 681.7 887.609 687.5 893.609 688.6L929.709 695.2C935.709 696.3 941.509 692.3 942.609 686.3L949.209 650.2C950.309 644.2 946.309 638.4 940.309 637.3L904.209 630.7C898.209 629.6 892.409 633.6 891.309 639.6L884.709 675.7Z'
                          fill='#5C729E'
                        />
                        <path
                          id='Vector_49'
                          opacity='0.9216'
                          d='M884.809 675.6C883.709 681.6 887.709 687.4 893.709 688.5L929.809 695.1C935.809 696.2 941.609 692.2 942.709 686.2L949.309 650.1C950.409 644.1 946.409 638.3 940.409 637.2L904.309 630.6C898.309 629.5 892.509 633.5 891.409 639.5L884.809 675.6Z'
                          fill='#586F9B'
                        />
                        <path
                          id='Vector_50'
                          opacity='0.9412'
                          d='M884.91 675.498C883.81 681.498 887.81 687.298 893.81 688.398L929.91 694.998C935.91 696.098 941.71 692.098 942.81 686.098L949.41 649.998C950.51 643.998 946.51 638.198 940.51 637.098L904.41 630.498C898.41 629.398 892.61 633.398 891.51 639.398L884.91 675.498Z'
                          fill='#556C99'
                        />
                        <path
                          id='Vector_51'
                          opacity='0.9608'
                          d='M885.009 675.399C883.909 681.399 887.909 687.199 893.909 688.299L930.009 694.899C936.009 695.999 941.809 691.999 942.909 685.999L949.509 649.899C950.609 643.899 946.609 638.099 940.609 636.999L904.509 630.399C898.509 629.299 892.709 633.299 891.609 639.299L885.009 675.399Z'
                          fill='#516997'
                        />
                        <path
                          id='Vector_52'
                          opacity='0.9804'
                          d='M885.11 675.399C884.01 681.399 888.01 687.199 894.01 688.299L930.11 694.899C936.11 695.999 941.91 691.999 943.01 685.999L949.61 649.899C950.71 643.899 946.71 638.099 940.71 636.999L904.61 630.399C898.61 629.299 892.81 633.299 891.71 639.299L885.11 675.399Z'
                          fill='#4E6695'
                        />
                        <path
                          id='Vector_53'
                          d='M885.209 675.299C884.109 681.299 888.109 687.099 894.109 688.199L930.209 694.799C936.209 695.899 942.009 691.899 943.109 685.899L949.709 649.799C950.809 643.799 946.809 637.999 940.809 636.899L904.709 630.299C898.709 629.199 892.909 633.199 891.809 639.199L885.209 675.299Z'
                          fill='#4A6393'
                        />
                      </g>
                      <path
                        id='Vector_54'
                        d='M886.51 674.599C885.41 680.499 889.31 686.199 895.21 687.199L930.71 693.699C936.61 694.799 942.31 690.899 943.31 684.999L949.81 649.499C950.91 643.599 947.01 637.899 941.11 636.899L905.61 630.399C899.71 629.299 894.01 633.199 893.01 639.099L886.51 674.599Z'
                        fill='url(#paint0_linear_475_685)'
                      />
                      <path
                        id='Vector_55'
                        d='M886.909 674.7C885.909 680.3 889.609 685.8 895.309 686.8L930.809 693.3C936.409 694.3 941.909 690.6 942.909 684.9L949.409 649.4C950.409 643.8 946.709 638.3 941.009 637.3L905.509 630.8C899.909 629.8 894.409 633.5 893.409 639.2L886.909 674.7Z'
                        fill={task[0].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      />
                    </g>
                    <text
                      id='Task 1'
                      transform='translate(941.135 644.125) rotate(100.369)'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[0].name && task[0].name.length > 3
                          ? truncateText(task[0].name, 4)
                          : task[0].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_56'
                      d='M897.309 671.898L901.909 646.598C902.609 642.998 906.009 640.698 909.509 641.298L911.609 641.698C915.209 642.398 917.509 645.798 916.909 649.298L912.309 674.598C911.609 678.198 908.209 680.498 904.709 679.898L902.609 679.498C899.009 678.898 896.609 675.498 897.309 671.898Z'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <g id='Group_26'>
                      <text
                        id='3/7'
                        transform='translate(915.721 652.572) rotate(100.369)'
                        fill={task[0].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='11.3287'
                        font-weight='bold'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='12.465'>
                          {/* {task[0].counter + '/3'} */}
                          {(task[0]?.TasksAndStudents?.counter || 414) +
                            '/' +
                            (task[0]?.total_counter || 0)}
                        </tspan>
                      </text>
                    </g>
                  </g>
                </a>
              </g>
            )}
            {task[1] !== undefined && task[1] !== null && (
              <g id='task-2'>
                <g id='rod-2'>
                  <g id='Rectangle_6-5_00000183933568374412835990000002269544317382819748_'>
                    <g id='Group_27'>
                      <path
                        id='Vector_57'
                        d='M649.01 674.7C651.71 672.9 655.41 673.7 657.21 676.4L865.01 990.7C866.81 993.4 866.01 997.1 863.31 998.9C860.61 1000.7 856.91 999.9 855.11 997.2L647.31 682.8C645.51 680.1 646.31 676.5 649.01 674.7Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_28'>
                      <path
                        id='Vector_58'
                        d='M649.01 674.7C651.71 672.9 655.41 673.7 657.21 676.4L865.01 990.7C866.81 993.4 866.01 997.1 863.31 998.9C860.61 1000.7 856.91 999.9 855.11 997.2L647.31 682.8C645.51 680.1 646.31 676.5 649.01 674.7Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_29'>
                    <path
                      id='Rectangle_6-6_00000152230309354752342590000014981798398972629917_'
                      d='M649 674.661C651.7 672.861 655.4 673.661 657.2 676.361L865 990.661C866.8 993.361 866 997.061 863.3 998.861C860.6 1000.66 856.9 999.861 855.1 997.161L647.3 682.861C645.5 680.161 646.3 676.461 649 674.661Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-2'>
                  <g id='rods_2'>
                    <g id='Group_30'>
                      <path
                        id='Rectangle_8-13_00000164473629928308856460000011247099953023625648_'
                        d='M883.81 1009.9C884.51 1006.7 887.61 1004.7 890.81 1005.3L956.61 1018.7C959.81 1019.4 961.809 1022.5 961.209 1025.7C960.509 1028.9 957.409 1030.9 954.209 1030.3L888.41 1016.9C885.21 1016.3 883.11 1013.1 883.81 1009.9Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_31'>
                        <path
                          id='Rectangle_8-14_00000037690910830486372170000017553798389723180979_'
                          d='M883.8 1009.96C884.5 1006.76 887.6 1004.76 890.8 1005.36L956.6 1018.76C959.8 1019.46 961.8 1022.56 961.2 1025.76C960.5 1028.96 957.4 1030.96 954.2 1030.36L888.4 1016.96C885.2 1016.26 883.1 1013.16 883.8 1009.96Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_32'>
                      <path
                        id='Rectangle_9-13_00000115484100932636361710000008245571568047869366_'
                        d='M868.31 1020.7C871.51 1021.3 873.61 1024.4 873.01 1027.6L860.71 1093.6C860.11 1096.8 857.01 1098.9 853.81 1098.3C850.61 1097.7 848.51 1094.6 849.11 1091.4L861.41 1025.4C862.11 1022.2 865.11 1020.1 868.31 1020.7Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_33'>
                        <path
                          id='Rectangle_9-14_00000084528775992125384410000001608317820694463935_'
                          d='M868.4 1020.76C871.6 1021.36 873.7 1024.46 873.1 1027.66L860.8 1093.66C860.2 1096.86 857.1 1098.96 853.9 1098.36C850.7 1097.76 848.6 1094.66 849.2 1091.46L861.5 1025.46C862.1 1022.26 865.2 1020.16 868.4 1020.76Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_2'>
                    <g id='Group_34'>
                      <path
                        id='Ellipse_16-13_00000109743893046367940560000001010434729258775454_'
                        d='M870.709 1026.6C880.872 1026.6 889.109 1018.36 889.109 1008.2C889.109 998.039 880.872 989.801 870.709 989.801C860.547 989.801 852.31 998.039 852.31 1008.2C852.31 1018.36 860.547 1026.6 870.709 1026.6Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_35'>
                        <path
                          id='Ellipse_16-14_00000170263632297539199420000006070841209630859648_'
                          d='M870.7 1026.66C880.862 1026.66 889.1 1018.42 889.1 1008.26C889.1 998.099 880.862 989.861 870.7 989.861C860.538 989.861 852.3 998.099 852.3 1008.26C852.3 1018.42 860.538 1026.66 870.7 1026.66Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000113322643537431141640000013175819709163839897_'
                      d='M868.31 991.702L855.61 1000.4C853.61 1001.8 850.91 1001.3 849.51 999.302C848.11 997.302 848.61 994.602 850.61 993.202L863.31 984.502C865.31 983.102 868.01 983.602 869.41 985.602C870.81 987.602 870.31 990.302 868.31 991.702Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_4'
                      transform='translate(867.488 991.584) rotate(145.536)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_36' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000170961910970930891210000014487899334123220371_'
                        d='M870.7 1020.96C877.714 1020.96 883.4 1015.28 883.4 1008.26C883.4 1001.25 877.714 995.562 870.7 995.562C863.686 995.562 858 1001.25 858 1008.26C858 1015.28 863.686 1020.96 870.7 1020.96Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_2'>
                    <g id='Group_37'>
                      <path
                        id='Ellipse_21-13_00000033332116216966847460000003116285000472818606_'
                        d='M971.81 1045.4C981.972 1045.4 990.21 1037.16 990.21 1027C990.21 1016.84 981.972 1008.6 971.81 1008.6C961.648 1008.6 953.41 1016.84 953.41 1027C953.41 1037.16 961.648 1045.4 971.81 1045.4Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_38'>
                        <path
                          id='Ellipse_21-14_00000026848076229738956720000003155256586439405452_'
                          d='M971.8 1045.46C981.962 1045.46 990.2 1037.22 990.2 1027.06C990.2 1016.9 981.962 1008.66 971.8 1008.66C961.638 1008.66 953.4 1016.9 953.4 1027.06C953.4 1037.22 961.638 1045.46 971.8 1045.46Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000059290981891770728870000002003415505813959819_'
                      d='M970.71 1012.1L958.01 1020.8C956.01 1022.2 953.31 1021.7 951.91 1019.7C950.51 1017.7 951.01 1015 953.01 1013.6L965.71 1004.9C967.71 1003.5 970.41 1004 971.81 1006C973.21 1008 972.71 1010.7 970.71 1012.1Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_5'
                      transform='translate(971.533 1012.05) rotate(145.536)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_39' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000137110410320256550950000012647394015691275136_'
                        d='M971.8 1039.76C978.814 1039.76 984.5 1034.08 984.5 1027.06C984.5 1020.05 978.814 1014.36 971.8 1014.36C964.786 1014.36 959.101 1020.05 959.101 1027.06C959.101 1034.08 964.786 1039.76 971.8 1039.76Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_2'>
                    <g id='Group_40'>
                      <path
                        id='Ellipse_18-13_00000150075965196473285570000015147434670223699349_'
                        d='M851.71 1128.7C861.872 1128.7 870.11 1120.46 870.11 1110.3C870.11 1100.14 861.872 1091.9 851.71 1091.9C841.548 1091.9 833.311 1100.14 833.311 1110.3C833.311 1120.46 841.548 1128.7 851.71 1128.7Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_41'>
                        <path
                          id='Ellipse_18-14_00000005987658485139882370000003110877272040177039_'
                          d='M851.701 1128.76C861.863 1128.76 870.101 1120.52 870.101 1110.36C870.101 1100.2 861.863 1091.96 851.701 1091.96C841.539 1091.96 833.301 1100.2 833.301 1110.36C833.301 1120.52 841.539 1128.76 851.701 1128.76Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000129888721307509645030000010179036401816631732_'
                      d='M850.71 1094.5L838.01 1103.2C836.01 1104.6 833.31 1104.1 831.91 1102.1C830.51 1100.1 831.01 1097.4 833.01 1096L845.71 1087.3C847.71 1085.9 850.41 1086.4 851.81 1088.4C853.21 1090.4 852.71 1093.1 850.71 1094.5Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_6'
                      transform='translate(851.507 1094.44) rotate(145.536)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_42' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000137122399580456947970000008604452547737669780_'
                        d='M851.7 1123.06C858.714 1123.06 864.4 1117.38 864.4 1110.36C864.4 1103.35 858.714 1097.66 851.7 1097.66C844.686 1097.66 839 1103.35 839 1110.36C839 1117.38 844.686 1123.06 851.7 1123.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[1].TasksAndStudents?.id || 0)}>
                  <g id='box-2'>
                    <g id='Group_43'>
                      <path
                        id='Vector_59'
                        opacity='0.0196078'
                        d='M739.909 855.3C734.909 858.8 733.609 865.7 737.009 870.7L757.809 901C761.309 906 768.209 907.3 773.209 903.9L803.509 883.1C808.509 879.6 809.809 872.7 806.409 867.7L785.609 837.4C782.109 832.4 775.209 831.1 770.209 834.5L739.909 855.3Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_60'
                        opacity='0.0392157'
                        d='M740.01 855.3C735.01 858.8 733.71 865.7 737.11 870.7L757.91 901C761.41 906 768.31 907.3 773.31 903.9L803.61 883.1C808.61 879.6 809.91 872.7 806.51 867.7L785.71 837.4C782.21 832.4 775.31 831.1 770.31 834.5L740.01 855.3Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_61'
                        opacity='0.0588235'
                        d='M740.109 855.4C735.109 858.9 733.809 865.8 737.209 870.8L758.009 901.1C761.509 906.1 768.409 907.4 773.409 904L803.709 883.2C808.709 879.7 810.009 872.8 806.609 867.8L785.81 837.5C782.31 832.5 775.409 831.2 770.409 834.6L740.109 855.4Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_62'
                        opacity='0.0784314'
                        d='M740.31 855.4C735.31 858.9 734.01 865.8 737.41 870.8L758.21 901.1C761.71 906.1 768.61 907.4 773.61 904L803.91 883.2C808.91 879.7 810.21 872.8 806.81 867.8L786.01 837.5C782.51 832.5 775.61 831.2 770.61 834.6L740.31 855.4Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_63'
                        opacity='0.0980392'
                        d='M740.409 855.4C735.409 858.9 734.109 865.8 737.509 870.8L758.309 901.1C761.809 906.1 768.709 907.4 773.709 904L804.009 883.2C809.009 879.7 810.309 872.8 806.909 867.8L786.109 837.5C782.609 832.5 775.709 831.2 770.709 834.6L740.409 855.4Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_64'
                        opacity='0.1176'
                        d='M740.51 855.4C735.51 858.9 734.21 865.8 737.61 870.8L758.41 901.1C761.91 906.1 768.81 907.4 773.81 904L804.11 883.2C809.11 879.7 810.41 872.8 807.01 867.8L786.21 837.5C782.71 832.5 775.81 831.2 770.81 834.6L740.51 855.4Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_65'
                        opacity='0.1373'
                        d='M740.709 855.4C735.709 858.9 734.409 865.8 737.809 870.8L758.609 901.1C762.109 906.1 769.009 907.4 774.009 904L804.309 883.2C809.309 879.7 810.609 872.8 807.209 867.8L786.409 837.5C782.909 832.5 776.009 831.2 771.009 834.6L740.709 855.4Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_66'
                        opacity='0.1569'
                        d='M740.81 855.5C735.81 859 734.51 865.9 737.91 870.9L758.71 901.2C762.21 906.2 769.11 907.5 774.11 904.1L804.41 883.3C809.41 879.8 810.71 872.9 807.31 867.9L786.51 837.6C783.01 832.6 776.11 831.3 771.11 834.7L740.81 855.5Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_67'
                        opacity='0.1765'
                        d='M740.909 855.5C735.909 859 734.609 865.9 738.009 870.9L758.809 901.2C762.309 906.2 769.209 907.5 774.209 904.1L804.509 883.3C809.509 879.8 810.809 872.9 807.409 867.9L786.609 837.6C783.109 832.6 776.209 831.3 771.209 834.7L740.909 855.5Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_68'
                        opacity='0.1961'
                        d='M741.01 855.5C736.01 859 734.71 865.9 738.11 870.9L758.91 901.2C762.41 906.2 769.31 907.5 774.31 904.1L804.61 883.3C809.61 879.8 810.91 872.9 807.51 867.9L786.71 837.6C783.21 832.6 776.31 831.3 771.31 834.7L741.01 855.5Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_69'
                        opacity='0.2157'
                        d='M741.209 855.5C736.209 859 734.909 865.9 738.309 870.9L759.109 901.2C762.609 906.2 769.509 907.5 774.509 904.1L804.809 883.3C809.809 879.8 811.109 872.9 807.709 867.9L786.909 837.6C783.409 832.6 776.509 831.3 771.509 834.7L741.209 855.5Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_70'
                        opacity='0.2353'
                        d='M741.31 855.601C736.31 859.101 735.01 866.001 738.41 871.001L759.21 901.301C762.71 906.301 769.61 907.601 774.61 904.201L804.91 883.401C809.91 879.901 811.21 873.001 807.81 868.001L787.01 837.701C783.51 832.701 776.61 831.401 771.61 834.801L741.31 855.601Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_71'
                        opacity='0.2549'
                        d='M741.409 855.601C736.409 859.101 735.109 866.001 738.509 871.001L759.309 901.301C762.809 906.301 769.709 907.601 774.709 904.201L805.009 883.401C810.009 879.901 811.309 873.001 807.909 868.001L787.109 837.701C783.609 832.701 776.709 831.401 771.709 834.801L741.409 855.601Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_72'
                        opacity='0.2745'
                        d='M741.609 855.601C736.609 859.101 735.309 866.001 738.709 871.001L759.509 901.301C763.009 906.301 769.909 907.601 774.909 904.201L805.209 883.401C810.209 879.901 811.509 873.001 808.109 868.001L787.31 837.701C783.81 832.701 776.909 831.401 771.909 834.801L741.609 855.601Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_73'
                        opacity='0.2941'
                        d='M741.709 855.601C736.709 859.101 735.409 866.001 738.809 871.001L759.609 901.301C763.109 906.301 770.009 907.601 775.009 904.201L805.309 883.401C810.309 879.901 811.609 873.001 808.209 868.001L787.409 837.701C783.909 832.701 777.009 831.401 772.009 834.801L741.709 855.601Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_74'
                        opacity='0.3137'
                        d='M741.81 855.701C736.81 859.201 735.51 866.101 738.91 871.101L759.71 901.401C763.21 906.401 770.11 907.701 775.11 904.301L805.41 883.501C810.41 880.001 811.71 873.101 808.31 868.101L787.51 837.801C784.01 832.801 777.11 831.501 772.11 834.901L741.81 855.701Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_75'
                        opacity='0.3333'
                        d='M741.909 855.701C736.909 859.201 735.609 866.101 739.009 871.101L759.809 901.401C763.309 906.401 770.209 907.701 775.209 904.301L805.509 883.501C810.509 880.001 811.809 873.101 808.409 868.101L787.609 837.801C784.109 832.801 777.209 831.501 772.209 834.901L741.909 855.701Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_76'
                        opacity='0.3529'
                        d='M742.109 855.701C737.109 859.201 735.809 866.101 739.209 871.101L760.009 901.401C763.509 906.401 770.409 907.701 775.409 904.301L805.709 883.501C810.709 880.001 812.009 873.101 808.609 868.101L787.81 837.801C784.31 832.801 777.409 831.501 772.409 834.901L742.109 855.701Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_77'
                        opacity='0.3725'
                        d='M742.209 855.701C737.209 859.201 735.909 866.101 739.309 871.101L760.109 901.401C763.609 906.401 770.509 907.701 775.509 904.301L805.809 883.501C810.809 880.001 812.109 873.101 808.709 868.101L787.909 837.801C784.409 832.801 777.509 831.501 772.509 834.901L742.209 855.701Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_78'
                        opacity='0.3922'
                        d='M742.31 855.8C737.31 859.3 736.01 866.2 739.41 871.2L760.21 901.5C763.71 906.5 770.61 907.8 775.61 904.4L805.91 883.601C810.91 880.101 812.21 873.2 808.81 868.2L788.01 837.9C784.51 832.9 777.61 831.6 772.61 835L742.31 855.8Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_79'
                        opacity='0.4118'
                        d='M742.51 855.8C737.51 859.3 736.21 866.2 739.61 871.2L760.41 901.5C763.91 906.5 770.81 907.8 775.81 904.4L806.11 883.601C811.11 880.101 812.41 873.2 809.01 868.2L788.21 837.9C784.71 832.9 777.81 831.6 772.81 835L742.51 855.8Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_80'
                        opacity='0.4314'
                        d='M742.609 855.8C737.609 859.3 736.309 866.2 739.709 871.2L760.509 901.5C764.009 906.5 770.909 907.8 775.909 904.4L806.209 883.601C811.209 880.101 812.509 873.2 809.109 868.2L788.31 837.9C784.81 832.9 777.909 831.6 772.909 835L742.609 855.8Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_81'
                        opacity='0.451'
                        d='M742.709 855.8C737.709 859.3 736.409 866.2 739.809 871.2L760.609 901.5C764.109 906.5 771.009 907.8 776.009 904.4L806.309 883.601C811.309 880.101 812.609 873.2 809.209 868.2L788.409 837.9C784.909 832.9 778.009 831.6 773.009 835L742.709 855.8Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_82'
                        opacity='0.4706'
                        d='M742.81 855.9C737.81 859.4 736.51 866.3 739.91 871.3L760.71 901.6C764.21 906.6 771.11 907.9 776.11 904.5L806.41 883.7C811.41 880.2 812.71 873.3 809.31 868.3L788.51 838C785.01 833 778.11 831.7 773.11 835.1L742.81 855.9Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_83'
                        opacity='0.4902'
                        d='M743.01 855.9C738.01 859.4 736.71 866.3 740.11 871.3L760.91 901.6C764.41 906.6 771.31 907.9 776.31 904.5L806.61 883.7C811.61 880.2 812.91 873.3 809.51 868.3L788.71 838C785.21 833 778.31 831.7 773.31 835.1L743.01 855.9Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_84'
                        opacity='0.5098'
                        d='M743.109 855.9C738.109 859.4 736.809 866.3 740.209 871.3L761.009 901.6C764.509 906.6 771.409 907.9 776.409 904.5L806.709 883.7C811.709 880.2 813.009 873.3 809.609 868.3L788.81 838C785.31 833 778.409 831.7 773.409 835.1L743.109 855.9Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_85'
                        opacity='0.5294'
                        d='M743.209 855.9C738.209 859.4 736.909 866.3 740.309 871.3L761.109 901.6C764.609 906.6 771.509 907.9 776.509 904.5L806.809 883.7C811.809 880.2 813.109 873.3 809.709 868.3L788.909 838C785.409 833 778.509 831.7 773.509 835.1L743.209 855.9Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_86'
                        opacity='0.549'
                        d='M743.409 855.9C738.409 859.4 737.109 866.3 740.509 871.3L761.309 901.6C764.809 906.6 771.709 907.9 776.709 904.5L807.009 883.7C812.009 880.2 813.309 873.3 809.909 868.3L789.109 838C785.609 833 778.709 831.7 773.709 835.1L743.409 855.9Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_87'
                        opacity='0.5686'
                        d='M743.51 856C738.51 859.5 737.21 866.4 740.61 871.4L761.41 901.7C764.91 906.7 771.81 908 776.81 904.6L807.11 883.8C812.11 880.3 813.41 873.4 810.01 868.4L789.21 838.1C785.71 833.1 778.81 831.8 773.81 835.2L743.51 856Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_88'
                        opacity='0.5882'
                        d='M743.609 856C738.609 859.5 737.309 866.4 740.709 871.4L761.509 901.7C765.009 906.7 771.909 908 776.909 904.6L807.209 883.8C812.209 880.3 813.509 873.4 810.109 868.4L789.31 838.1C785.81 833.1 778.909 831.8 773.909 835.2L743.609 856Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_89'
                        opacity='0.6078'
                        d='M743.709 856C738.709 859.5 737.409 866.4 740.809 871.4L761.609 901.7C765.109 906.7 772.009 908 777.009 904.6L807.309 883.8C812.309 880.3 813.609 873.4 810.209 868.4L789.409 838.1C785.909 833.1 779.009 831.8 774.009 835.2L743.709 856Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_90'
                        opacity='0.6275'
                        d='M743.909 856C738.909 859.5 737.609 866.4 741.009 871.4L761.809 901.7C765.309 906.7 772.209 908 777.209 904.6L807.509 883.8C812.509 880.3 813.809 873.4 810.409 868.4L789.609 838.1C786.109 833.1 779.209 831.8 774.209 835.2L743.909 856Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_91'
                        opacity='0.6471'
                        d='M744.01 856.101C739.01 859.601 737.71 866.501 741.11 871.501L761.91 901.801C765.41 906.801 772.31 908.101 777.31 904.701L807.61 883.901C812.61 880.401 813.91 873.501 810.51 868.501L789.71 838.201C786.21 833.201 779.31 831.901 774.31 835.301L744.01 856.101Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_92'
                        opacity='0.6667'
                        d='M744.109 856.101C739.109 859.601 737.809 866.501 741.209 871.501L762.009 901.801C765.509 906.801 772.409 908.101 777.409 904.701L807.709 883.901C812.709 880.401 814.009 873.501 810.609 868.501L789.81 838.201C786.31 833.201 779.409 831.901 774.409 835.301L744.109 856.101Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_93'
                        opacity='0.6863'
                        d='M744.31 856.101C739.31 859.601 738.01 866.501 741.41 871.501L762.21 901.801C765.71 906.801 772.61 908.101 777.61 904.701L807.91 883.901C812.91 880.401 814.21 873.501 810.81 868.501L790.01 838.201C786.51 833.201 779.61 831.901 774.61 835.301L744.31 856.101Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_94'
                        opacity='0.7059'
                        d='M744.409 856.101C739.409 859.601 738.109 866.501 741.509 871.501L762.309 901.801C765.809 906.801 772.709 908.101 777.709 904.701L808.009 883.901C813.009 880.401 814.309 873.501 810.909 868.501L790.109 838.201C786.609 833.201 779.709 831.901 774.709 835.301L744.409 856.101Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_95'
                        opacity='0.7255'
                        d='M744.51 856.201C739.51 859.701 738.21 866.601 741.61 871.601L762.41 901.901C765.91 906.901 772.81 908.201 777.81 904.801L808.11 884.001C813.11 880.501 814.41 873.601 811.01 868.601L790.21 838.301C786.71 833.301 779.81 832.001 774.81 835.401L744.51 856.201Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_96'
                        opacity='0.7451'
                        d='M744.609 856.201C739.609 859.701 738.309 866.601 741.709 871.601L762.509 901.901C766.009 906.901 772.909 908.201 777.909 904.801L808.209 884.001C813.209 880.501 814.509 873.601 811.109 868.601L790.31 838.301C786.81 833.301 779.909 832.001 774.909 835.401L744.609 856.201Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_97'
                        opacity='0.7647'
                        d='M744.81 856.201C739.81 859.701 738.51 866.601 741.91 871.601L762.71 901.901C766.21 906.901 773.11 908.201 778.11 904.801L808.41 884.001C813.41 880.501 814.71 873.601 811.31 868.601L790.51 838.301C787.01 833.301 780.11 832.001 775.11 835.401L744.81 856.201Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_98'
                        opacity='0.7843'
                        d='M744.909 856.201C739.909 859.701 738.609 866.601 742.009 871.601L762.809 901.901C766.309 906.901 773.209 908.201 778.209 904.801L808.509 884.001C813.509 880.501 814.809 873.601 811.409 868.601L790.609 838.301C787.109 833.301 780.209 832.001 775.209 835.401L744.909 856.201Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_99'
                        opacity='0.8039'
                        d='M745.01 856.3C740.01 859.8 738.71 866.7 742.11 871.7L762.91 902C766.41 907 773.31 908.3 778.31 904.9L808.61 884.101C813.61 880.601 814.91 873.7 811.51 868.7L790.71 838.4C787.21 833.4 780.31 832.1 775.31 835.5L745.01 856.3Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_100'
                        opacity='0.8235'
                        d='M745.209 856.3C740.209 859.8 738.909 866.7 742.309 871.7L763.109 902C766.609 907 773.509 908.3 778.509 904.9L808.809 884.101C813.809 880.601 815.109 873.7 811.709 868.7L790.909 838.4C787.409 833.4 780.509 832.1 775.509 835.5L745.209 856.3Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_101'
                        opacity='0.8431'
                        d='M745.31 856.3C740.31 859.8 739.01 866.7 742.41 871.7L763.21 902C766.71 907 773.61 908.3 778.61 904.9L808.91 884.101C813.91 880.601 815.21 873.7 811.81 868.7L791.01 838.4C787.51 833.4 780.61 832.1 775.61 835.5L745.31 856.3Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_102'
                        opacity='0.8627'
                        d='M745.409 856.3C740.409 859.8 739.109 866.7 742.509 871.7L763.309 902C766.809 907 773.709 908.3 778.709 904.9L809.009 884.101C814.009 880.601 815.309 873.7 811.909 868.7L791.109 838.4C787.609 833.4 780.709 832.1 775.709 835.5L745.409 856.3Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_103'
                        opacity='0.8824'
                        d='M745.51 856.4C740.51 859.9 739.21 866.8 742.61 871.8L763.41 902.1C766.91 907.1 773.81 908.4 778.81 905L809.11 884.2C814.11 880.7 815.41 873.8 812.01 868.8L791.21 838.5C787.71 833.5 780.81 832.2 775.81 835.6L745.51 856.4Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_104'
                        opacity='0.902'
                        d='M745.709 856.4C740.709 859.9 739.409 866.8 742.809 871.8L763.609 902.1C767.109 907.1 774.009 908.4 779.009 905L809.309 884.2C814.309 880.7 815.609 873.8 812.209 868.8L791.409 838.5C787.909 833.5 781.009 832.2 776.009 835.6L745.709 856.4Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_105'
                        opacity='0.9216'
                        d='M745.81 856.4C740.81 859.9 739.51 866.8 742.91 871.8L763.71 902.1C767.21 907.1 774.11 908.4 779.11 905L809.41 884.2C814.41 880.7 815.71 873.8 812.31 868.8L791.51 838.5C788.01 833.5 781.11 832.2 776.11 835.6L745.81 856.4Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_106'
                        opacity='0.9412'
                        d='M745.909 856.4C740.909 859.9 739.609 866.8 743.009 871.8L763.809 902.1C767.309 907.1 774.209 908.4 779.209 905L809.509 884.2C814.509 880.7 815.809 873.8 812.409 868.8L791.609 838.5C788.109 833.5 781.209 832.2 776.209 835.6L745.909 856.4Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_107'
                        opacity='0.9608'
                        d='M746.109 856.5C741.109 860 739.809 866.9 743.209 871.9L764.009 902.2C767.509 907.2 774.409 908.5 779.409 905.1L809.709 884.3C814.709 880.8 816.009 873.9 812.609 868.9L791.81 838.6C788.31 833.6 781.409 832.3 776.409 835.7L746.109 856.5Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_108'
                        opacity='0.9804'
                        d='M746.209 856.5C741.209 860 739.909 866.9 743.309 871.9L764.109 902.2C767.609 907.2 774.509 908.5 779.509 905.1L809.809 884.3C814.809 880.8 816.109 873.9 812.709 868.9L791.909 838.6C788.409 833.6 781.509 832.3 776.509 835.7L746.209 856.5Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_109'
                        d='M746.31 856.5C741.31 860 740.01 866.9 743.41 871.9L764.21 902.2C767.71 907.2 774.61 908.5 779.61 905.1L809.91 884.3C814.91 880.8 816.21 873.9 812.81 868.9L792.01 838.6C788.51 833.6 781.61 832.3 776.61 835.7L746.31 856.5Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_110'
                      d='M747.709 856.9C742.809 860.3 741.509 867.1 744.909 872L765.309 901.7C768.709 906.6 775.509 907.9 780.409 904.5L810.109 884.1C815.009 880.7 816.309 873.9 812.909 869L792.509 839.3C789.109 834.4 782.309 833.1 777.409 836.5L747.709 856.9Z'
                      fill='url(#paint1_linear_475_685)'
                    />
                    <path
                      id='Vector_111'
                      d='M748.01 857.301C743.31 860.501 742.11 867.001 745.31 871.801L765.71 901.501C768.91 906.201 775.41 907.401 780.21 904.201L809.91 883.801C814.61 880.601 815.81 874.101 812.61 869.301L792.21 839.601C789.01 834.901 782.51 833.701 777.71 836.901L748.01 857.301Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 2'
                      transform='translate(808.004 873.971) rotate(145.536)'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[1].name && task[1].name.length > 3
                          ? truncateText(task[1].name, 4)
                          : task[1].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_112'
                      d='M757.209 862.701L778.409 848.101C781.409 846.101 785.509 846.801 787.509 849.801L788.709 851.501C790.709 854.501 790.009 858.601 787.009 860.601L765.809 875.201C762.809 877.201 758.709 876.501 756.709 873.501L755.509 871.801C753.509 868.801 754.209 864.701 757.209 862.701Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_2'
                      transform='translate(783.892 862.078) rotate(145.536)'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[1].counter + '/3'} */}
                        {(task[1]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[1]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[2] !== undefined && task[2] !== null && (
              <g id='task-3'>
                <g id='rod-3'>
                  <g id='Rectangle_6-5_00000039824698776487891160000003686951501502915003_'>
                    <g id='Group_44'>
                      <path
                        id='Vector_113'
                        d='M581.109 689.599C584.309 690.199 586.409 693.299 585.809 696.499L514.409 1066.5C513.809 1069.7 510.709 1071.8 507.509 1071.2C504.309 1070.6 502.209 1067.5 502.809 1064.3L574.209 694.299C574.809 690.999 577.909 688.899 581.109 689.599Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_45'>
                      <path
                        id='Vector_114'
                        d='M581.109 689.599C584.309 690.199 586.409 693.299 585.809 696.499L514.409 1066.5C513.809 1069.7 510.709 1071.8 507.509 1071.2C504.309 1070.6 502.209 1067.5 502.809 1064.3L574.209 694.299C574.809 690.999 577.909 688.899 581.109 689.599Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_46'>
                    <path
                      id='Rectangle_6-6_00000036235264424536565680000004866904788091686785_'
                      d='M581.1 689.559C584.3 690.159 586.4 693.259 585.8 696.459L514.4 1066.46C513.8 1069.66 510.7 1071.76 507.5 1071.16C504.3 1070.56 502.2 1067.46 502.8 1064.26L574.199 694.259C574.899 691.059 577.9 688.959 581.1 689.559Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-3'>
                  <g id='rods_3'>
                    <g id='Group_47'>
                      <path
                        id='Rectangle_8-13_00000111158580724663684410000014934892638045296571_'
                        d='M514.41 1093.4C517.11 1091.6 520.81 1092.3 522.61 1095L560.21 1150.6C562.01 1153.3 561.31 1157 558.61 1158.8C555.91 1160.6 552.21 1159.9 550.41 1157.2L512.81 1101.6C511.01 1098.9 511.71 1095.2 514.41 1093.4Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_48'>
                        <path
                          id='Rectangle_8-14_00000175308566969649416720000009777499418789982887_'
                          d='M514.4 1093.36C517.1 1091.56 520.801 1092.26 522.601 1094.96L560.2 1150.56C562 1153.26 561.301 1156.96 558.601 1158.76C555.901 1160.56 552.2 1159.86 550.4 1157.16L512.8 1101.56C511 1098.86 511.7 1095.26 514.4 1093.36Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_49'>
                      <path
                        id='Rectangle_9-13_00000175315492195761265200000015170458595227568537_'
                        d='M495.91 1090.3C497.81 1093 497.11 1096.6 494.51 1098.5L439.61 1137.1C436.91 1139 433.31 1138.3 431.41 1135.7C429.51 1133 430.21 1129.4 432.81 1127.5L487.71 1088.9C490.31 1087 494.01 1087.6 495.91 1090.3Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_50'>
                        <path
                          id='Rectangle_9-14_00000181802406556465773130000008956835364933295288_'
                          d='M495.901 1090.26C497.801 1092.96 497.101 1096.56 494.501 1098.46L439.601 1137.06C436.901 1138.96 433.301 1138.26 431.401 1135.66C429.501 1132.96 430.2 1129.36 432.8 1127.46L487.7 1088.86C490.3 1086.96 494.001 1087.66 495.901 1090.26Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_3'>
                    <g id='Group_51'>
                      <path
                        id='Ellipse_16-13_00000029034456860881734290000013866514372994728383_'
                        d='M506.31 1101.4C516.472 1101.4 524.71 1093.16 524.71 1083C524.71 1072.84 516.472 1064.6 506.31 1064.6C496.148 1064.6 487.91 1072.84 487.91 1083C487.91 1093.16 496.148 1101.4 506.31 1101.4Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_52'>
                        <path
                          id='Ellipse_16-14_00000111882498782862316710000007419802490766463135_'
                          d='M506.3 1101.36C516.462 1101.36 524.7 1093.12 524.7 1082.96C524.7 1072.8 516.462 1064.56 506.3 1064.56C496.138 1064.56 487.9 1072.8 487.9 1082.96C487.9 1093.12 496.138 1101.36 506.3 1101.36Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000013156737822699735420000016099310224064333206_'
                      d='M516.11 1069.5L500.91 1066.8C498.51 1066.4 496.91 1064.1 497.31 1061.7C497.71 1059.3 500.01 1057.7 502.41 1058.1L517.61 1060.8C520.01 1061.2 521.61 1063.5 521.21 1065.9C520.71 1068.3 518.51 1069.9 516.11 1069.5Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_7'
                      transform='translate(515.539 1068.9) rotate(-170.078)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_53' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000020390324885674089330000014354430931818690197_'
                        d='M506.3 1095.66C513.314 1095.66 519 1089.97 519 1082.96C519 1075.95 513.314 1070.26 506.3 1070.26C499.286 1070.26 493.601 1075.95 493.601 1082.96C493.601 1089.97 499.286 1095.66 506.3 1095.66Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_3'>
                    <g id='Group_54'>
                      <path
                        id='Ellipse_21-13_00000031912425407292641670000002997959400836110512_'
                        d='M565.41 1185.6C575.572 1185.6 583.81 1177.36 583.81 1167.2C583.81 1157.04 575.572 1148.8 565.41 1148.8C555.248 1148.8 547.01 1157.04 547.01 1167.2C547.01 1177.36 555.248 1185.6 565.41 1185.6Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_55'>
                        <path
                          id='Ellipse_21-14_00000049180002923971137750000018162912967624239530_'
                          d='M565.4 1185.56C575.562 1185.56 583.8 1177.32 583.8 1167.16C583.8 1157 575.562 1148.76 565.4 1148.76C555.238 1148.76 547 1157 547 1167.16C547 1177.32 555.238 1185.56 565.4 1185.56Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000049938679012934589590000008577744786648874394_'
                      d='M575.109 1155.7L559.909 1153C557.509 1152.6 555.909 1150.3 556.309 1147.9C556.709 1145.5 559.009 1143.9 561.409 1144.3L576.609 1147C579.009 1147.4 580.609 1149.7 580.209 1152.1C579.709 1154.6 577.409 1156.2 575.109 1155.7Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_8'
                      transform='translate(575.582 1156.3) rotate(-170.078)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_56' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000088820964509754311030000015186765892898159252_'
                        d='M565.399 1179.86C572.413 1179.86 578.1 1174.17 578.1 1167.16C578.1 1160.15 572.413 1154.46 565.399 1154.46C558.385 1154.46 552.699 1160.15 552.699 1167.16C552.699 1174.17 558.385 1179.86 565.399 1179.86Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_3'>
                    <g id='Group_57'>
                      <path
                        id='Ellipse_18-13_00000125596849461762867520000017627004968092851098_'
                        d='M421.31 1161.1C431.472 1161.1 439.71 1152.86 439.71 1142.7C439.71 1132.54 431.472 1124.3 421.31 1124.3C411.148 1124.3 402.91 1132.54 402.91 1142.7C402.91 1152.86 411.148 1161.1 421.31 1161.1Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_58'>
                        <path
                          id='Ellipse_18-14_00000027589484548406310310000014902422346739933595_'
                          d='M421.3 1161.06C431.462 1161.06 439.7 1152.82 439.7 1142.66C439.7 1132.5 431.462 1124.26 421.3 1124.26C411.138 1124.26 402.9 1132.5 402.9 1142.66C402.9 1152.82 411.138 1161.06 421.3 1161.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000164477925866036289720000015311387076125249954_'
                      d='M431.711 1130.7L416.511 1128C414.111 1127.6 412.511 1125.3 412.911 1122.9C413.311 1120.5 415.611 1118.9 418.011 1119.3L433.211 1122C435.611 1122.4 437.211 1124.7 436.811 1127.1C436.311 1129.5 434.011 1131.1 431.711 1130.7Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_9'
                      transform='translate(432.178 1131.22) rotate(-170.078)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_59' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000084527707375250944870000013478816370779442088_'
                        d='M421.3 1155.36C428.314 1155.36 434 1149.67 434 1142.66C434 1135.65 428.314 1129.96 421.3 1129.96C414.286 1129.96 408.601 1135.65 408.601 1142.66C408.601 1149.67 414.286 1155.36 421.3 1155.36Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[2].TasksAndStudents?.id || 0)}>
                  <g id='box-3'>
                    <g id='Group_60'>
                      <path
                        id='Vector_115'
                        opacity='0.0196078'
                        d='M519.81 882.198C513.81 881.098 508.01 885.198 507.01 891.198L500.71 927.398C499.61 933.398 503.71 939.198 509.71 940.198L545.91 946.498C551.91 947.598 557.71 943.498 558.71 937.498L565.01 901.298C566.11 895.298 562.01 889.498 556.01 888.498L519.81 882.198Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_116'
                        opacity='0.0392157'
                        d='M519.81 882.298C513.81 881.198 508.01 885.298 507.01 891.298L500.71 927.498C499.61 933.498 503.71 939.298 509.71 940.298L545.91 946.598C551.91 947.698 557.71 943.598 558.71 937.598L565.01 901.398C566.11 895.398 562.01 889.598 556.01 888.598L519.81 882.298Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_117'
                        opacity='0.0588235'
                        d='M519.91 882.499C513.91 881.399 508.11 885.499 507.11 891.499L500.81 927.699C499.71 933.699 503.81 939.499 509.81 940.499L546.01 946.799C552.01 947.899 557.81 943.799 558.81 937.799L565.11 901.599C566.21 895.599 562.11 889.799 556.11 888.799L519.91 882.499Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_118'
                        opacity='0.0784314'
                        d='M520.01 882.599C514.01 881.499 508.21 885.599 507.21 891.599L500.91 927.799C499.81 933.799 503.91 939.599 509.91 940.599L546.11 946.898C552.11 947.998 557.91 943.898 558.91 937.898L565.21 901.699C566.31 895.699 562.21 889.898 556.21 888.898L520.01 882.599Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_119'
                        opacity='0.0980392'
                        d='M520.111 882.698C514.111 881.598 508.311 885.698 507.311 891.698L501.011 927.898C499.911 933.898 504.011 939.698 510.011 940.698L546.211 946.998C552.211 948.098 558.011 943.998 559.011 937.998L565.311 901.798C566.411 895.798 562.311 889.998 556.311 888.998L520.111 882.698Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_120'
                        opacity='0.1176'
                        d='M520.111 882.798C514.111 881.698 508.311 885.798 507.311 891.798L501.011 927.998C499.911 933.998 504.011 939.798 510.011 940.798L546.211 947.098C552.211 948.198 558.011 944.098 559.011 938.098L565.311 901.898C566.411 895.898 562.311 890.098 556.311 889.098L520.111 882.798Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_121'
                        opacity='0.1373'
                        d='M520.21 882.899C514.21 881.799 508.41 885.899 507.41 891.899L501.11 928.099C500.01 934.099 504.11 939.899 510.11 940.899L546.31 947.199C552.31 948.299 558.11 944.199 559.11 938.199L565.41 901.999C566.51 895.999 562.41 890.199 556.41 889.199L520.21 882.899Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_122'
                        opacity='0.1569'
                        d='M520.31 882.999C514.31 881.899 508.51 885.999 507.51 891.999L501.21 928.199C500.11 934.199 504.21 939.999 510.21 940.999L546.41 947.299C552.41 948.399 558.21 944.299 559.21 938.299L565.51 902.099C566.61 896.099 562.51 890.299 556.51 889.299L520.31 882.999Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_123'
                        opacity='0.1765'
                        d='M520.41 883.099C514.41 881.999 508.61 886.099 507.61 892.099L501.31 928.299C500.21 934.299 504.31 940.099 510.31 941.099L546.51 947.398C552.51 948.498 558.31 944.398 559.31 938.398L565.61 902.199C566.71 896.199 562.61 890.398 556.61 889.398L520.41 883.099Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_124'
                        opacity='0.1961'
                        d='M520.41 883.198C514.41 882.098 508.61 886.198 507.61 892.198L501.31 928.398C500.21 934.398 504.31 940.198 510.31 941.198L546.51 947.498C552.51 948.598 558.31 944.498 559.31 938.498L565.61 902.298C566.71 896.298 562.61 890.498 556.61 889.498L520.41 883.198Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_125'
                        opacity='0.2157'
                        d='M520.51 883.298C514.51 882.198 508.71 886.298 507.71 892.298L501.41 928.498C500.31 934.498 504.41 940.298 510.41 941.298L546.61 947.598C552.61 948.698 558.41 944.598 559.41 938.598L565.71 902.398C566.81 896.398 562.71 890.598 556.71 889.598L520.51 883.298Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_126'
                        opacity='0.2353'
                        d='M520.611 883.399C514.611 882.299 508.811 886.399 507.811 892.399L501.511 928.599C500.411 934.599 504.511 940.399 510.511 941.399L546.711 947.699C552.711 948.799 558.511 944.699 559.511 938.699L565.811 902.499C566.911 896.499 562.811 890.699 556.811 889.699L520.611 883.399Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_127'
                        opacity='0.2549'
                        d='M520.71 883.499C514.71 882.399 508.91 886.499 507.91 892.499L501.61 928.699C500.51 934.699 504.61 940.499 510.61 941.499L546.81 947.799C552.81 948.899 558.61 944.799 559.61 938.799L565.91 902.599C567.01 896.599 562.91 890.799 556.91 889.799L520.71 883.499Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_128'
                        opacity='0.2745'
                        d='M520.71 883.599C514.71 882.499 508.91 886.599 507.91 892.599L501.61 928.799C500.51 934.799 504.61 940.599 510.61 941.599L546.81 947.898C552.81 948.998 558.61 944.898 559.61 938.898L565.91 902.699C567.01 896.699 562.91 890.898 556.91 889.898L520.71 883.599Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_129'
                        opacity='0.2941'
                        d='M520.81 883.698C514.81 882.598 509.01 886.698 508.01 892.698L501.71 928.898C500.61 934.898 504.71 940.698 510.71 941.698L546.91 947.998C552.91 949.098 558.71 944.998 559.71 938.998L566.01 902.798C567.11 896.798 563.01 890.998 557.01 889.998L520.81 883.698Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_130'
                        opacity='0.3137'
                        d='M520.91 883.798C514.91 882.698 509.11 886.798 508.11 892.798L501.81 928.998C500.71 934.998 504.81 940.798 510.81 941.798L547.01 948.098C553.01 949.198 558.81 945.098 559.81 939.098L566.11 902.898C567.21 896.898 563.11 891.098 557.11 890.098L520.91 883.798Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_131'
                        opacity='0.3333'
                        d='M521.01 883.899C515.01 882.799 509.21 886.899 508.21 892.899L501.91 929.099C500.81 935.099 504.91 940.899 510.91 941.899L547.11 948.199C553.11 949.299 558.91 945.199 559.91 939.199L566.21 902.999C567.31 896.999 563.21 891.199 557.21 890.199L521.01 883.899Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_132'
                        opacity='0.3529'
                        d='M521.01 884.099C515.01 882.999 509.21 887.099 508.21 893.099L501.91 929.299C500.81 935.299 504.91 941.099 510.91 942.099L547.11 948.398C553.11 949.498 558.91 945.398 559.91 939.398L566.21 903.199C567.31 897.199 563.21 891.398 557.21 890.398L521.01 884.099Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_133'
                        opacity='0.3725'
                        d='M521.111 884.198C515.111 883.098 509.311 887.198 508.311 893.198L502.011 929.398C500.911 935.398 505.011 941.198 511.011 942.198L547.211 948.498C553.211 949.598 559.011 945.498 560.011 939.498L566.311 903.298C567.411 897.298 563.311 891.498 557.311 890.498L521.111 884.198Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_134'
                        opacity='0.3922'
                        d='M521.21 884.298C515.21 883.198 509.41 887.298 508.41 893.298L502.11 929.498C501.01 935.498 505.11 941.298 511.11 942.298L547.31 948.598C553.31 949.698 559.11 945.598 560.11 939.598L566.41 903.398C567.51 897.398 563.41 891.598 557.41 890.598L521.21 884.298Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_135'
                        opacity='0.4118'
                        d='M521.31 884.399C515.31 883.299 509.51 887.399 508.51 893.399L502.21 929.599C501.11 935.599 505.21 941.399 511.21 942.399L547.41 948.699C553.41 949.799 559.21 945.699 560.21 939.699L566.51 903.499C567.61 897.499 563.51 891.699 557.51 890.699L521.31 884.399Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_136'
                        opacity='0.4314'
                        d='M521.31 884.499C515.31 883.399 509.51 887.499 508.51 893.499L502.21 929.699C501.11 935.699 505.21 941.499 511.21 942.499L547.41 948.799C553.41 949.899 559.21 945.799 560.21 939.799L566.51 903.599C567.61 897.599 563.51 891.799 557.51 890.799L521.31 884.499Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_137'
                        opacity='0.451'
                        d='M521.41 884.599C515.41 883.499 509.61 887.599 508.61 893.599L502.31 929.799C501.21 935.799 505.31 941.599 511.31 942.599L547.51 948.898C553.51 949.998 559.31 945.898 560.31 939.898L566.61 903.699C567.71 897.699 563.61 891.898 557.61 890.898L521.41 884.599Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_138'
                        opacity='0.4706'
                        d='M521.51 884.698C515.51 883.598 509.71 887.698 508.71 893.698L502.41 929.898C501.31 935.898 505.41 941.698 511.41 942.698L547.61 948.998C553.61 950.098 559.41 945.998 560.41 939.998L566.71 903.798C567.81 897.798 563.71 891.998 557.71 890.998L521.51 884.698Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_139'
                        opacity='0.4902'
                        d='M521.611 884.798C515.611 883.698 509.811 887.798 508.811 893.798L502.511 929.998C501.411 935.998 505.511 941.798 511.511 942.798L547.711 949.098C553.711 950.198 559.511 946.098 560.511 940.098L566.811 903.898C567.911 897.898 563.811 892.098 557.811 891.098L521.611 884.798Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_140'
                        opacity='0.5098'
                        d='M521.611 884.899C515.611 883.799 509.811 887.899 508.811 893.899L502.511 930.099C501.411 936.099 505.511 941.899 511.511 942.899L547.711 949.199C553.711 950.299 559.511 946.199 560.511 940.199L566.811 903.999C567.911 897.999 563.811 892.199 557.811 891.199L521.611 884.899Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_141'
                        opacity='0.5294'
                        d='M521.71 884.999C515.71 883.899 509.91 887.999 508.91 893.999L502.61 930.199C501.51 936.199 505.61 941.999 511.61 942.999L547.81 949.299C553.81 950.399 559.61 946.299 560.61 940.299L566.91 904.099C568.01 898.099 563.91 892.299 557.91 891.299L521.71 884.999Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_142'
                        opacity='0.549'
                        d='M521.81 885.099C515.81 883.999 510.01 888.099 509.01 894.099L502.71 930.299C501.61 936.299 505.71 942.099 511.71 943.099L547.91 949.398C553.91 950.498 559.71 946.398 560.71 940.398L567.01 904.199C568.11 898.199 564.01 892.398 558.01 891.398L521.81 885.099Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_143'
                        opacity='0.5686'
                        d='M521.91 885.198C515.91 884.098 510.11 888.198 509.11 894.198L502.81 930.398C501.71 936.398 505.81 942.198 511.81 943.198L548.01 949.498C554.01 950.598 559.81 946.498 560.81 940.498L567.11 904.298C568.21 898.298 564.11 892.498 558.11 891.498L521.91 885.198Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_144'
                        opacity='0.5882'
                        d='M521.91 885.298C515.91 884.198 510.11 888.298 509.11 894.298L502.81 930.498C501.71 936.498 505.81 942.298 511.81 943.298L548.01 949.598C554.01 950.698 559.81 946.598 560.81 940.598L567.11 904.398C568.21 898.398 564.11 892.598 558.11 891.598L521.91 885.298Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_145'
                        opacity='0.6078'
                        d='M522.01 885.399C516.01 884.299 510.21 888.399 509.21 894.399L502.91 930.599C501.81 936.599 505.91 942.399 511.91 943.399L548.11 949.699C554.11 950.799 559.91 946.699 560.91 940.699L567.21 904.499C568.31 898.499 564.21 892.699 558.21 891.699L522.01 885.399Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_146'
                        opacity='0.6275'
                        d='M522.111 885.599C516.111 884.499 510.311 888.599 509.311 894.599L503.011 930.799C501.911 936.799 506.011 942.599 512.011 943.599L548.211 949.898C554.211 950.998 560.011 946.898 561.011 940.898L567.311 904.699C568.411 898.699 564.311 892.898 558.311 891.898L522.111 885.599Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_147'
                        opacity='0.6471'
                        d='M522.21 885.698C516.21 884.598 510.41 888.698 509.41 894.698L503.11 930.898C502.01 936.898 506.11 942.698 512.11 943.698L548.31 949.998C554.31 951.098 560.11 946.998 561.11 940.998L567.41 904.798C568.51 898.798 564.41 892.998 558.41 891.998L522.21 885.698Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_148'
                        opacity='0.6667'
                        d='M522.21 885.798C516.21 884.698 510.41 888.798 509.41 894.798L503.11 930.998C502.01 936.998 506.11 942.798 512.11 943.798L548.31 950.098C554.31 951.198 560.11 947.098 561.11 941.098L567.41 904.898C568.51 898.898 564.41 893.098 558.41 892.098L522.21 885.798Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_149'
                        opacity='0.6863'
                        d='M522.31 885.899C516.31 884.799 510.51 888.899 509.51 894.899L503.21 931.099C502.11 937.099 506.21 942.899 512.21 943.899L548.41 950.199C554.41 951.299 560.21 947.199 561.21 941.199L567.51 904.999C568.61 898.999 564.51 893.199 558.51 892.199L522.31 885.899Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_150'
                        opacity='0.7059'
                        d='M522.41 885.999C516.41 884.899 510.61 888.999 509.61 894.999L503.31 931.199C502.21 937.199 506.31 942.999 512.31 943.999L548.51 950.299C554.51 951.399 560.31 947.299 561.31 941.299L567.61 905.099C568.71 899.099 564.61 893.299 558.61 892.299L522.41 885.999Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_151'
                        opacity='0.7255'
                        d='M522.51 886.099C516.51 884.999 510.71 889.099 509.71 895.099L503.41 931.299C502.31 937.299 506.41 943.099 512.41 944.099L548.61 950.398C554.61 951.498 560.41 947.398 561.41 941.398L567.71 905.199C568.81 899.199 564.71 893.398 558.71 892.398L522.51 886.099Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_152'
                        opacity='0.7451'
                        d='M522.51 886.198C516.51 885.098 510.71 889.198 509.71 895.198L503.41 931.398C502.31 937.398 506.41 943.198 512.41 944.198L548.61 950.498C554.61 951.598 560.41 947.498 561.41 941.498L567.71 905.298C568.81 899.298 564.71 893.498 558.71 892.498L522.51 886.198Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_153'
                        opacity='0.7647'
                        d='M522.611 886.298C516.611 885.198 510.811 889.298 509.811 895.298L503.511 931.498C502.411 937.498 506.511 943.298 512.511 944.298L548.711 950.598C554.711 951.698 560.511 947.598 561.511 941.598L567.811 905.398C568.911 899.398 564.811 893.598 558.811 892.598L522.611 886.298Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_154'
                        opacity='0.7843'
                        d='M522.71 886.399C516.71 885.299 510.91 889.399 509.91 895.399L503.61 931.599C502.51 937.599 506.61 943.399 512.61 944.399L548.81 950.699C554.81 951.799 560.61 947.699 561.61 941.699L567.91 905.499C569.01 899.499 564.91 893.699 558.91 892.699L522.71 886.399Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_155'
                        opacity='0.8039'
                        d='M522.81 886.499C516.81 885.399 511.01 889.499 510.01 895.499L503.71 931.699C502.61 937.699 506.71 943.499 512.71 944.499L548.91 950.799C554.91 951.899 560.71 947.799 561.71 941.799L568.01 905.599C569.11 899.599 565.01 893.799 559.01 892.799L522.81 886.499Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_156'
                        opacity='0.8235'
                        d='M522.81 886.599C516.81 885.499 511.01 889.599 510.01 895.599L503.71 931.799C502.61 937.799 506.71 943.599 512.71 944.599L548.91 950.898C554.91 951.998 560.71 947.898 561.71 941.898L568.01 905.699C569.11 899.699 565.01 893.898 559.01 892.898L522.81 886.599Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_157'
                        opacity='0.8431'
                        d='M522.91 886.698C516.91 885.598 511.11 889.698 510.11 895.698L503.81 931.898C502.71 937.898 506.81 943.698 512.81 944.698L549.01 950.998C555.01 952.098 560.81 947.998 561.81 941.998L568.11 905.798C569.21 899.798 565.11 893.998 559.11 892.998L522.91 886.698Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_158'
                        opacity='0.8627'
                        d='M523.01 886.798C517.01 885.698 511.21 889.798 510.21 895.798L503.91 931.998C502.81 937.998 506.91 943.798 512.91 944.798L549.11 951.098C555.11 952.198 560.91 948.098 561.91 942.098L568.21 905.898C569.31 899.898 565.21 894.098 559.21 893.098L523.01 886.798Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_159'
                        opacity='0.8824'
                        d='M523.111 886.899C517.111 885.799 511.311 889.899 510.311 895.899L504.011 932.099C502.911 938.099 507.011 943.899 513.011 944.899L549.211 951.199C555.211 952.299 561.011 948.199 562.011 942.199L568.311 905.999C569.411 899.999 565.311 894.199 559.311 893.199L523.111 886.899Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_160'
                        opacity='0.902'
                        d='M523.111 887.099C517.111 885.999 511.311 890.099 510.311 896.099L504.011 932.299C502.911 938.299 507.011 944.099 513.011 945.099L549.211 951.398C555.211 952.498 561.011 948.398 562.011 942.398L568.311 906.199C569.411 900.199 565.311 894.398 559.311 893.398L523.111 887.099Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_161'
                        opacity='0.9216'
                        d='M523.21 887.198C517.21 886.098 511.41 890.198 510.41 896.198L504.11 932.398C503.01 938.398 507.11 944.198 513.11 945.198L549.31 951.498C555.31 952.598 561.11 948.498 562.11 942.498L568.41 906.298C569.51 900.298 565.41 894.498 559.41 893.498L523.21 887.198Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_162'
                        opacity='0.9412'
                        d='M523.31 887.298C517.31 886.198 511.51 890.298 510.51 896.298L504.21 932.498C503.11 938.498 507.21 944.298 513.21 945.298L549.41 951.598C555.41 952.698 561.21 948.598 562.21 942.598L568.51 906.398C569.61 900.398 565.51 894.598 559.51 893.598L523.31 887.298Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_163'
                        opacity='0.9608'
                        d='M523.41 887.399C517.41 886.299 511.61 890.399 510.61 896.399L504.31 932.599C503.21 938.599 507.31 944.399 513.31 945.399L549.51 951.699C555.51 952.799 561.31 948.699 562.31 942.699L568.61 906.499C569.71 900.499 565.61 894.699 559.61 893.699L523.41 887.399Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_164'
                        opacity='0.9804'
                        d='M523.41 887.499C517.41 886.399 511.61 890.499 510.61 896.499L504.31 932.699C503.21 938.699 507.31 944.499 513.31 945.499L549.51 951.799C555.51 952.899 561.31 948.799 562.31 942.799L568.61 906.599C569.71 900.599 565.61 894.799 559.61 893.799L523.41 887.499Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_165'
                        d='M523.51 887.599C517.51 886.499 511.71 890.599 510.71 896.599L504.41 932.799C503.31 938.799 507.41 944.599 513.41 945.599L549.61 951.898C555.61 952.998 561.41 948.898 562.41 942.898L568.71 906.699C569.81 900.699 565.71 894.898 559.71 893.898L523.51 887.599Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_166'
                      d='M524.21 888.799C518.31 887.799 512.71 891.699 511.61 897.599L505.41 933.099C504.41 938.999 508.31 944.599 514.21 945.699L549.71 951.899C555.61 952.899 561.21 948.999 562.31 943.099L568.51 907.599C569.51 901.699 565.61 896.099 559.71 894.999L524.21 888.799Z'
                      fill='url(#paint2_linear_475_685)'
                    />
                    <path
                      id='Vector_167'
                      d='M524.111 889.298C518.411 888.298 513.011 892.098 512.111 897.798L505.911 933.298C504.911 938.998 508.711 944.398 514.411 945.298L549.911 951.498C555.611 952.498 561.011 948.698 561.911 942.998L568.111 907.498C569.111 901.798 565.311 896.398 559.611 895.498L524.111 889.298Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 3'
                      transform='translate(553.93 942.91) rotate(-170.078)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3615'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[2].name && task[2].name.length > 3
                          ? truncateText(task[2].name, 4)
                          : task[2].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_168'
                      d='M527.011 899.598L552.311 903.998C555.911 904.598 558.211 907.998 557.611 911.598L557.211 913.698C556.611 917.298 553.211 919.598 549.611 918.998L524.311 914.598C520.711 913.998 518.411 910.598 519.011 906.998L519.411 904.898C520.011 901.398 523.411 898.998 527.011 899.598Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_3'
                      transform='translate(546.379 917.869) rotate(-170.078)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[2].counter + '/3'} */}
                        {(task[2]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[2]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[3] !== undefined && task[3] !== null && (
              <g id='task-4'>
                <g id='rod-4'>
                  <g id='Rectangle_6-5_00000115475061276288052930000013816934177488816524_'>
                    <g id='Group_61'>
                      <path
                        id='Vector_169'
                        d='M523.31 651.701C525.11 654.401 524.31 658.101 521.61 659.901L207.31 867.701C204.61 869.501 200.91 868.701 199.11 866.001C197.31 863.301 198.11 859.601 200.81 857.801L515.11 650.001C517.91 648.201 521.51 649.001 523.31 651.701Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_62'>
                      <path
                        id='Vector_170'
                        d='M523.31 651.701C525.11 654.401 524.31 658.101 521.61 659.901L207.31 867.701C204.61 869.501 200.91 868.701 199.11 866.001C197.31 863.301 198.11 859.601 200.81 857.801L515.11 650.001C517.91 648.201 521.51 649.001 523.31 651.701Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_63'>
                    <path
                      id='Rectangle_6-6_00000067952708388402155310000015240773969706515595_'
                      d='M523.3 651.661C525.1 654.361 524.301 658.061 521.601 659.861L207.4 867.561C204.7 869.361 201 868.561 199.2 865.861C197.4 863.161 198.2 859.461 200.9 857.661L515.2 649.861C517.9 648.161 521.5 648.961 523.3 651.661Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-4'>
                  <g id='rods_4'>
                    <g id='Group_64'>
                      <path
                        id='Rectangle_8-13_00000167363123788455493420000000975965339582156689_'
                        d='M188.11 886.5C191.31 887.2 193.31 890.3 192.71 893.5L179.31 959.3C178.61 962.5 175.51 964.5 172.31 963.9C169.11 963.2 167.11 960.1 167.71 956.9L181.11 891.1C181.81 887.9 184.91 885.8 188.11 886.5Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_65'>
                        <path
                          id='Rectangle_8-14_00000085945170543542664860000011424581917200868253_'
                          d='M188.1 886.46C191.3 887.16 193.3 890.26 192.7 893.46L179.3 959.26C178.6 962.46 175.5 964.46 172.3 963.86C169.1 963.16 167.1 960.06 167.7 956.86L181.1 891.06C181.8 887.86 184.9 885.86 188.1 886.46Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_66'>
                      <path
                        id='Rectangle_9-13_00000067205113490053259480000017741928050156066949_'
                        d='M177.31 871C176.71 874.2 173.61 876.3 170.41 875.7L104.41 863.4C101.21 862.8 99.1097 859.7 99.7097 856.5C100.31 853.3 103.41 851.2 106.61 851.8L172.61 864.1C175.81 864.8 177.91 867.8 177.31 871Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_67'>
                        <path
                          id='Rectangle_9-14_00000166660888628050124040000001913174682337578894_'
                          d='M177.3 871.06C176.7 874.26 173.6 876.36 170.4 875.76L104.4 863.46C101.2 862.86 99.1 859.76 99.7 856.56C100.3 853.36 103.4 851.26 106.6 851.86L172.6 864.16C175.8 864.76 177.9 867.86 177.3 871.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_4'>
                    <g id='Group_68'>
                      <path
                        id='Ellipse_16-13_00000160878679788287541610000004210777142085248435_'
                        d='M189.81 891.8C199.972 891.8 208.21 883.562 208.21 873.4C208.21 863.238 199.972 855 189.81 855C179.648 855 171.41 863.238 171.41 873.4C171.41 883.562 179.648 891.8 189.81 891.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_69'>
                        <path
                          id='Ellipse_16-14_00000119087814843597142180000018200199453437283209_'
                          d='M189.8 891.761C199.962 891.761 208.2 883.523 208.2 873.361C208.2 863.199 199.962 854.961 189.8 854.961C179.638 854.961 171.4 863.199 171.4 873.361C171.4 883.523 179.638 891.761 189.8 891.761Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000017517164440240913340000000723598815277756090_'
                      d='M206.31 871.001L197.61 858.301C196.21 856.301 196.71 853.601 198.71 852.201C200.71 850.801 203.41 851.301 204.81 853.301L213.51 866.001C214.91 868.001 214.41 870.701 212.41 872.101C210.41 873.501 207.71 873.001 206.31 871.001Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_10'
                      transform='translate(206.342 870.152) rotate(-124.464)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_70' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000005225678759576334470000013704977839216626339_'
                        d='M189.8 886.06C196.814 886.06 202.5 880.374 202.5 873.36C202.5 866.346 196.814 860.66 189.8 860.66C182.786 860.66 177.101 866.346 177.101 873.36C177.101 880.374 182.786 886.06 189.8 886.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask--2'>
                    <g id='Group_71'>
                      <path
                        id='Ellipse_21-13_00000113354264232304266790000003242475504961760680_'
                        d='M171.009 992.9C181.171 992.9 189.409 984.662 189.409 974.5C189.409 964.337 181.171 956.1 171.009 956.1C160.847 956.1 152.609 964.337 152.609 974.5C152.609 984.662 160.847 992.9 171.009 992.9Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_72'>
                        <path
                          id='Ellipse_21-14_00000165945666058337408530000007866499637713271699_'
                          d='M171 992.96C181.162 992.96 189.399 984.722 189.399 974.56C189.399 964.398 181.162 956.16 171 956.16C160.837 956.16 152.6 964.398 152.6 974.56C152.6 984.722 160.837 992.96 171 992.96Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000070818277767706529400000003843774187144066202_'
                      d='M185.91 973.4L177.21 960.7C175.81 958.7 176.31 956 178.31 954.6C180.31 953.2 183.01 953.7 184.41 955.7L193.11 968.4C194.51 970.4 194.01 973.1 192.01 974.5C190.01 975.9 187.31 975.4 185.91 973.4Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_11'
                      transform='translate(185.875 974.195) rotate(-124.464)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_73' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000154403941500726226400000006186785073244087998_'
                        d='M171 987.259C178.014 987.259 183.7 981.573 183.7 974.559C183.7 967.545 178.014 961.859 171 961.859C163.986 961.859 158.3 967.545 158.3 974.559C158.3 981.573 163.986 987.259 171 987.259Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_4'>
                    <g id='Group_74'>
                      <path
                        id='Ellipse_18-13_00000096737539986313420240000005468525080896723374_'
                        d='M87.7095 872.8C97.8715 872.8 106.109 864.562 106.109 854.4C106.109 844.238 97.8715 836 87.7095 836C77.5474 836 69.3096 844.238 69.3096 854.4C69.3096 864.562 77.5474 872.8 87.7095 872.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_75'>
                        <path
                          id='Ellipse_18-14_00000134946001354940842150000005684614742538676869_'
                          d='M87.6997 872.761C97.8617 872.761 106.1 864.523 106.1 854.361C106.1 844.199 97.8617 835.961 87.6997 835.961C77.5377 835.961 69.2998 844.199 69.2998 854.361C69.2998 864.523 77.5377 872.761 87.6997 872.761Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000168107575566830350670000012258581695561882517_'
                      d='M103.51 853.4L94.8096 840.7C93.4096 838.7 93.9096 836 95.9096 834.6C97.9096 833.2 100.61 833.7 102.01 835.7L110.71 848.4C112.11 850.4 111.61 853.1 109.61 854.5C107.61 855.9 104.91 855.4 103.51 853.4Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_12'
                      transform='translate(103.49 854.168) rotate(-124.464)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_76' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000181071828515621015620000003699489351311498642_'
                        d='M87.6997 867.06C94.7137 867.06 100.4 861.374 100.4 854.36C100.4 847.346 94.7137 841.66 87.6997 841.66C80.6857 841.66 75 847.346 75 854.36C75 861.374 80.6857 867.06 87.6997 867.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[3].TasksAndStudents?.id || 0)}>
                  <g id='box-4'>
                    <g id='Group_77'>
                      <path
                        id='Vector_171'
                        opacity='0.0196078'
                        d='M342.71 742.6C339.21 737.6 332.31 736.3 327.31 739.7L297.01 760.5C292.01 764 290.71 770.9 294.11 775.9L314.91 806.2C318.41 811.2 325.31 812.5 330.31 809.1L360.61 788.3C365.61 784.8 366.91 777.9 363.51 772.9L342.71 742.6Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_172'
                        opacity='0.0392157'
                        d='M342.71 742.699C339.21 737.699 332.31 736.399 327.31 739.799L297.01 760.599C292.01 764.099 290.71 770.999 294.11 775.999L314.91 806.299C318.41 811.299 325.31 812.599 330.31 809.199L360.61 788.399C365.61 784.899 366.91 777.999 363.51 772.999L342.71 742.699Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_173'
                        opacity='0.0588235'
                        d='M342.71 742.801C339.21 737.801 332.31 736.501 327.31 739.901L297.01 760.701C292.01 764.201 290.71 771.101 294.11 776.101L314.91 806.401C318.41 811.401 325.31 812.701 330.31 809.301L360.61 788.501C365.61 785.001 366.91 778.101 363.51 773.101L342.71 742.801Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_174'
                        opacity='0.0784314'
                        d='M342.609 743C339.109 738 332.209 736.7 327.209 740.1L296.909 760.9C291.909 764.4 290.609 771.3 294.009 776.3L314.809 806.6C318.309 811.6 325.209 812.9 330.209 809.5L360.509 788.7C365.509 785.2 366.809 778.3 363.409 773.3L342.609 743Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_175'
                        opacity='0.0980392'
                        d='M342.609 743.1C339.109 738.1 332.209 736.8 327.209 740.2L296.909 761C291.909 764.5 290.609 771.4 294.009 776.4L314.809 806.7C318.309 811.7 325.209 813 330.209 809.6L360.509 788.8C365.509 785.3 366.809 778.4 363.409 773.4L342.609 743.1Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_176'
                        opacity='0.1176'
                        d='M342.609 743.199C339.109 738.199 332.209 736.899 327.209 740.299L296.909 761.099C291.909 764.599 290.609 771.499 294.009 776.499L314.809 806.799C318.309 811.799 325.209 813.099 330.209 809.699L360.509 788.899C365.509 785.399 366.809 778.499 363.409 773.499L342.609 743.199Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_177'
                        opacity='0.1373'
                        d='M342.609 743.4C339.109 738.4 332.209 737.1 327.209 740.5L296.909 761.3C291.909 764.8 290.609 771.7 294.009 776.7L314.809 807C318.309 812 325.209 813.3 330.209 809.9L360.509 789.1C365.509 785.6 366.809 778.7 363.409 773.7L342.609 743.4Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_178'
                        opacity='0.1569'
                        d='M342.51 743.5C339.01 738.5 332.11 737.2 327.11 740.6L296.81 761.4C291.81 764.9 290.51 771.8 293.91 776.8L314.71 807.1C318.21 812.1 325.11 813.4 330.11 810L360.41 789.2C365.41 785.7 366.71 778.8 363.31 773.8L342.51 743.5Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_179'
                        opacity='0.1765'
                        d='M342.51 743.6C339.01 738.6 332.11 737.3 327.11 740.7L296.81 761.5C291.81 765 290.51 771.9 293.91 776.9L314.71 807.2C318.21 812.2 325.11 813.5 330.11 810.1L360.41 789.3C365.41 785.8 366.71 778.9 363.31 773.9L342.51 743.6Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_180'
                        opacity='0.1961'
                        d='M342.51 743.699C339.01 738.699 332.11 737.399 327.11 740.799L296.81 761.599C291.81 765.099 290.51 771.999 293.91 776.999L314.71 807.299C318.21 812.299 325.11 813.599 330.11 810.199L360.41 789.399C365.41 785.899 366.71 778.999 363.31 773.999L342.51 743.699Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_181'
                        opacity='0.2157'
                        d='M342.51 743.9C339.01 738.9 332.11 737.6 327.11 741L296.81 761.8C291.81 765.3 290.51 772.2 293.91 777.2L314.71 807.5C318.21 812.5 325.11 813.8 330.11 810.4L360.41 789.6C365.41 786.1 366.71 779.2 363.31 774.2L342.51 743.9Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_182'
                        opacity='0.2353'
                        d='M342.409 744C338.909 739 332.009 737.7 327.009 741.1L296.709 761.9C291.709 765.4 290.409 772.3 293.809 777.3L314.609 807.6C318.109 812.6 325.009 813.9 330.009 810.5L360.309 789.7C365.309 786.2 366.609 779.3 363.209 774.3L342.409 744Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_183'
                        opacity='0.2549'
                        d='M342.409 744.1C338.909 739.1 332.009 737.8 327.009 741.2L296.709 762C291.709 765.5 290.409 772.4 293.809 777.4L314.609 807.7C318.109 812.7 325.009 814 330.009 810.6L360.309 789.8C365.309 786.3 366.609 779.4 363.209 774.4L342.409 744.1Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_184'
                        opacity='0.2745'
                        d='M342.409 744.301C338.909 739.301 332.009 738.001 327.009 741.401L296.709 762.201C291.709 765.701 290.409 772.601 293.809 777.601L314.609 807.901C318.109 812.901 325.009 814.201 330.009 810.801L360.309 790.001C365.309 786.501 366.609 779.601 363.209 774.601L342.409 744.301Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_185'
                        opacity='0.2941'
                        d='M342.409 744.4C338.909 739.4 332.009 738.1 327.009 741.5L296.709 762.3C291.709 765.8 290.409 772.7 293.809 777.7L314.609 808C318.109 813 325.009 814.3 330.009 810.9L360.309 790.1C365.309 786.6 366.609 779.7 363.209 774.7L342.409 744.4Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_186'
                        opacity='0.3137'
                        d='M342.31 744.5C338.81 739.5 331.909 738.2 326.909 741.6L296.609 762.4C291.609 765.9 290.309 772.8 293.709 777.8L314.509 808.1C318.009 813.1 324.909 814.4 329.909 811L360.209 790.2C365.209 786.7 366.509 779.8 363.109 774.8L342.31 744.5Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_187'
                        opacity='0.3333'
                        d='M342.31 744.6C338.81 739.6 331.909 738.3 326.909 741.7L296.609 762.5C291.609 766 290.309 772.9 293.709 777.9L314.509 808.2C318.009 813.2 324.909 814.5 329.909 811.1L360.209 790.3C365.209 786.8 366.509 779.9 363.109 774.9L342.31 744.6Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_188'
                        opacity='0.3529'
                        d='M342.31 744.801C338.81 739.801 331.909 738.501 326.909 741.901L296.609 762.701C291.609 766.201 290.309 773.101 293.709 778.101L314.509 808.401C318.009 813.401 324.909 814.701 329.909 811.301L360.209 790.501C365.209 787.001 366.509 780.101 363.109 775.101L342.31 744.801Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_189'
                        opacity='0.3725'
                        d='M342.31 744.9C338.81 739.9 331.909 738.6 326.909 742L296.609 762.8C291.609 766.3 290.309 773.2 293.709 778.2L314.509 808.5C318.009 813.5 324.909 814.8 329.909 811.4L360.209 790.6C365.209 787.1 366.509 780.2 363.109 775.2L342.31 744.9Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_190'
                        opacity='0.3922'
                        d='M342.21 745C338.71 740 331.81 738.7 326.81 742.1L296.51 762.9C291.51 766.4 290.21 773.3 293.61 778.3L314.41 808.6C317.91 813.6 324.81 814.9 329.81 811.5L360.11 790.7C365.11 787.2 366.41 780.3 363.01 775.3L342.21 745Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_191'
                        opacity='0.4118'
                        d='M342.21 745.199C338.71 740.199 331.81 738.899 326.81 742.299L296.51 763.099C291.51 766.599 290.21 773.499 293.61 778.499L314.51 808.799C318.01 813.799 324.91 815.099 329.91 811.699L360.21 790.899C365.21 787.399 366.51 780.499 363.11 775.499L342.21 745.199Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_192'
                        opacity='0.4314'
                        d='M342.21 745.301C338.71 740.301 331.81 739.001 326.81 742.401L296.51 763.201C291.51 766.701 290.21 773.601 293.61 778.601L314.41 808.901C317.91 813.901 324.81 815.201 329.81 811.801L360.11 791.001C365.11 787.501 366.41 780.601 363.01 775.601L342.21 745.301Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_193'
                        opacity='0.451'
                        d='M342.21 745.4C338.71 740.4 331.81 739.1 326.81 742.5L296.51 763.3C291.51 766.8 290.21 773.7 293.61 778.7L314.41 809C317.91 814 324.81 815.3 329.81 811.9L360.11 791.1C365.11 787.6 366.41 780.7 363.01 775.7L342.21 745.4Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_194'
                        opacity='0.4706'
                        d='M342.109 745.5C338.609 740.5 331.709 739.2 326.709 742.6L296.409 763.4C291.409 766.9 290.109 773.8 293.509 778.8L314.309 809.1C317.809 814.1 324.709 815.4 329.709 812L360.009 791.2C365.009 787.7 366.309 780.8 362.909 775.8L342.109 745.5Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_195'
                        opacity='0.4902'
                        d='M342.109 745.699C338.609 740.699 331.709 739.399 326.709 742.799L296.409 763.599C291.409 767.099 290.109 773.999 293.509 778.999L314.309 809.299C317.809 814.299 324.709 815.599 329.709 812.199L360.009 791.399C365.009 787.899 366.309 780.999 362.909 775.999L342.109 745.699Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_196'
                        opacity='0.5098'
                        d='M342.109 745.801C338.609 740.801 331.709 739.501 326.709 742.901L296.409 763.701C291.409 767.201 290.109 774.101 293.509 779.101L314.309 809.401C317.809 814.401 324.709 815.701 329.709 812.301L360.009 791.501C365.009 788.001 366.309 781.101 362.909 776.101L342.109 745.801Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_197'
                        opacity='0.5294'
                        d='M342.109 745.9C338.609 740.9 331.709 739.6 326.709 743L296.409 763.8C291.409 767.3 290.109 774.2 293.509 779.2L314.309 809.5C317.809 814.5 324.709 815.8 329.709 812.4L360.009 791.6C365.009 788.1 366.309 781.2 362.909 776.2L342.109 745.9Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_198'
                        opacity='0.549'
                        d='M342.109 746.1C338.609 741.1 331.709 739.8 326.709 743.2L296.409 764C291.409 767.5 290.109 774.4 293.509 779.4L314.309 809.7C317.809 814.7 324.709 816 329.709 812.6L360.009 791.8C365.009 788.3 366.309 781.4 362.909 776.4L342.109 746.1Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_199'
                        opacity='0.5686'
                        d='M342.01 746.199C338.51 741.199 331.61 739.899 326.61 743.299L296.31 764.099C291.31 767.599 290.01 774.499 293.41 779.499L314.21 809.799C317.71 814.799 324.61 816.099 329.61 812.699L359.91 791.899C364.91 788.399 366.21 781.499 362.81 776.499L342.01 746.199Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_200'
                        opacity='0.5882'
                        d='M342.01 746.301C338.51 741.301 331.61 740.001 326.61 743.401L296.31 764.201C291.31 767.701 290.01 774.601 293.41 779.601L314.21 809.901C317.71 814.901 324.61 816.201 329.61 812.801L359.91 792.001C364.91 788.501 366.21 781.601 362.81 776.601L342.01 746.301Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_201'
                        opacity='0.6078'
                        d='M342.01 746.4C338.51 741.4 331.61 740.1 326.61 743.5L296.31 764.3C291.31 767.8 290.01 774.7 293.41 779.7L314.21 810C317.71 815 324.61 816.3 329.61 812.9L359.91 792.1C364.91 788.6 366.21 781.7 362.81 776.7L342.01 746.4Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_202'
                        opacity='0.6275'
                        d='M342.01 746.6C338.51 741.6 331.61 740.3 326.61 743.7L296.31 764.5C291.31 768 290.01 774.9 293.41 779.9L314.21 810.2C317.71 815.2 324.61 816.5 329.61 813.1L359.91 792.3C364.91 788.8 366.21 781.9 362.81 776.9L342.01 746.6Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_203'
                        opacity='0.6471'
                        d='M341.909 746.699C338.409 741.699 331.509 740.399 326.509 743.799L296.209 764.599C291.209 768.099 289.909 774.999 293.309 779.999L314.109 810.299C317.609 815.299 324.509 816.599 329.509 813.199L359.809 792.399C364.809 788.899 366.109 781.999 362.709 776.999L341.909 746.699Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_204'
                        opacity='0.6667'
                        d='M341.909 746.801C338.409 741.801 331.509 740.501 326.509 743.901L296.209 764.701C291.209 768.201 289.909 775.101 293.309 780.101L314.109 810.401C317.609 815.401 324.509 816.701 329.509 813.301L359.809 792.501C364.809 789.001 366.109 782.101 362.709 777.101L341.909 746.801Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_205'
                        opacity='0.6863'
                        d='M341.909 747C338.409 742 331.509 740.7 326.509 744.1L296.209 764.9C291.209 768.4 289.909 775.3 293.309 780.3L314.109 810.6C317.609 815.6 324.509 816.9 329.509 813.5L359.809 792.7C364.809 789.2 366.109 782.3 362.709 777.3L341.909 747Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_206'
                        opacity='0.7059'
                        d='M341.909 747.1C338.409 742.1 331.509 740.8 326.509 744.2L296.209 765C291.209 768.5 289.909 775.4 293.309 780.4L314.109 810.7C317.609 815.7 324.509 817 329.509 813.6L359.809 792.8C364.809 789.3 366.109 782.4 362.709 777.4L341.909 747.1Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_207'
                        opacity='0.7255'
                        d='M341.81 747.199C338.31 742.199 331.409 740.899 326.409 744.299L296.109 765.099C291.109 768.599 289.809 775.499 293.209 780.499L314.009 810.799C317.509 815.799 324.409 817.099 329.409 813.699L359.709 792.899C364.709 789.399 366.009 782.499 362.609 777.499L341.81 747.199Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_208'
                        opacity='0.7451'
                        d='M341.81 747.301C338.31 742.301 331.409 741.001 326.409 744.401L296.109 765.201C291.109 768.701 289.809 775.601 293.209 780.601L314.009 810.901C317.509 815.901 324.409 817.201 329.409 813.801L359.709 793.001C364.709 789.501 366.009 782.601 362.609 777.601L341.81 747.301Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_209'
                        opacity='0.7647'
                        d='M341.81 747.5C338.31 742.5 331.409 741.2 326.409 744.6L296.109 765.4C291.109 768.9 289.809 775.8 293.209 780.8L314.009 811.1C317.509 816.1 324.409 817.4 329.409 814L359.709 793.2C364.709 789.7 366.009 782.8 362.609 777.8L341.81 747.5Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_210'
                        opacity='0.7843'
                        d='M341.81 747.6C338.31 742.6 331.409 741.3 326.409 744.7L296.109 765.5C291.109 769 289.809 775.9 293.209 780.9L314.009 811.2C317.509 816.2 324.409 817.5 329.409 814.1L359.709 793.3C364.709 789.8 366.009 782.9 362.609 777.9L341.81 747.6Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_211'
                        opacity='0.8039'
                        d='M341.71 747.699C338.21 742.699 331.31 741.399 326.31 744.799L296.01 765.599C291.01 769.099 289.71 775.999 293.11 780.999L313.91 811.299C317.41 816.299 324.31 817.599 329.31 814.199L359.61 793.399C364.61 789.899 365.91 782.999 362.51 777.999L341.71 747.699Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_212'
                        opacity='0.8235'
                        d='M341.71 747.9C338.21 742.9 331.31 741.6 326.31 745L296.01 765.8C291.01 769.3 289.71 776.2 293.11 781.2L313.91 811.5C317.41 816.5 324.31 817.8 329.31 814.4L359.61 793.6C364.61 790.1 365.91 783.2 362.51 778.2L341.71 747.9Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_213'
                        opacity='0.8431'
                        d='M341.71 748C338.21 743 331.31 741.7 326.31 745.1L296.01 765.9C291.01 769.4 289.71 776.3 293.11 781.3L313.91 811.6C317.41 816.6 324.31 817.9 329.31 814.5L359.61 793.7C364.61 790.2 365.91 783.3 362.51 778.3L341.71 748Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_214'
                        opacity='0.8627'
                        d='M341.71 748.1C338.21 743.1 331.31 741.8 326.31 745.2L296.01 766C291.01 769.5 289.71 776.4 293.11 781.4L313.91 811.7C317.41 816.7 324.31 818 329.31 814.6L359.61 793.8C364.61 790.3 365.91 783.4 362.51 778.4L341.71 748.1Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_215'
                        opacity='0.8824'
                        d='M341.609 748.199C338.109 743.199 331.209 741.899 326.209 745.299L295.909 766.099C290.909 769.599 289.609 776.499 293.009 781.499L313.809 811.799C317.309 816.799 324.209 818.099 329.209 814.699L359.509 793.899C364.509 790.399 365.809 783.499 362.409 778.499L341.609 748.199Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_216'
                        opacity='0.902'
                        d='M341.609 748.4C338.109 743.4 331.209 742.1 326.209 745.5L295.909 766.3C290.909 769.8 289.609 776.7 293.009 781.7L313.809 812C317.309 817 324.209 818.3 329.209 814.9L359.509 794.1C364.509 790.6 365.809 783.7 362.409 778.7L341.609 748.4Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_217'
                        opacity='0.9216'
                        d='M341.609 748.5C338.109 743.5 331.209 742.2 326.209 745.6L295.909 766.4C290.909 769.9 289.609 776.8 293.009 781.8L313.809 812.1C317.309 817.1 324.209 818.4 329.209 815L359.509 794.2C364.509 790.7 365.809 783.8 362.409 778.8L341.609 748.5Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_218'
                        opacity='0.9412'
                        d='M341.609 748.6C338.109 743.6 331.209 742.3 326.209 745.7L295.909 766.5C290.909 770 289.609 776.9 293.009 781.9L313.809 812.2C317.309 817.2 324.209 818.5 329.209 815.1L359.509 794.3C364.509 790.8 365.809 783.9 362.409 778.9L341.609 748.6Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_219'
                        opacity='0.9608'
                        d='M341.609 748.801C338.109 743.801 331.209 742.501 326.209 745.901L295.909 766.701C290.909 770.201 289.609 777.101 293.009 782.101L313.809 812.401C317.309 817.401 324.209 818.701 329.209 815.301L359.509 794.501C364.509 791.001 365.809 784.101 362.409 779.101L341.609 748.801Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_220'
                        opacity='0.9804'
                        d='M341.51 748.9C338.01 743.9 331.11 742.6 326.11 746L295.81 766.8C290.81 770.3 289.51 777.2 292.91 782.2L313.71 812.5C317.21 817.5 324.11 818.8 329.11 815.4L359.41 794.6C364.41 791.1 365.71 784.2 362.31 779.2L341.51 748.9Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_221'
                        d='M341.51 749C338.01 744 331.11 742.7 326.11 746.1L295.81 766.9C290.81 770.4 289.51 777.3 292.91 782.3L313.71 812.6C317.21 817.6 324.11 818.9 329.11 815.5L359.41 794.7C364.41 791.2 365.71 784.3 362.31 779.3L341.51 749Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_222'
                      d='M341.11 750.4C337.71 745.5 330.91 744.2 326.01 747.6L296.31 768C291.41 771.4 290.11 778.2 293.51 783.1L313.91 812.8C317.31 817.7 324.11 819 329.01 815.6L358.71 795.2C363.61 791.8 364.91 785 361.51 780.1L341.11 750.4Z'
                      fill='url(#paint3_linear_475_685)'
                    />
                    <path
                      id='Vector_223'
                      d='M340.71 750.601C337.51 745.901 331.01 744.701 326.21 747.901L296.51 768.301C291.81 771.501 290.61 778.001 293.81 782.801L314.21 812.501C317.41 817.201 323.91 818.401 328.71 815.201L358.41 794.801C363.11 791.601 364.31 785.101 361.11 780.301L340.71 750.601Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 4'
                      transform='translate(323.766 810.391) rotate(-124.464)'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[3].name && task[3].name.length > 3
                          ? truncateText(task[3].name, 4)
                          : task[3].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_224'
                      d='M335.31 759.899L349.91 781.099C351.91 784.099 351.21 788.199 348.21 790.199L346.51 791.399C343.51 793.399 339.41 792.699 337.41 789.699L322.81 768.499C320.81 765.499 321.51 761.399 324.51 759.399L326.21 758.199C329.21 756.199 333.31 756.899 335.31 759.899Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_4'
                      transform='translate(335.846 786.555) rotate(-124.464)'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[3].counter + '/3'} */}
                        {(task[3]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[3]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[4] !== undefined && task[4] !== null && (
              <g id='task-5'>
                <g id='rod-5'>
                  <g id='Rectangle_6-5_00000109006763799978225430000016766636368618805149_'>
                    <g id='Group_78'>
                      <path
                        id='Vector_225'
                        d='M508.71 583.4C509.31 580.2 507.11 577.1 503.91 576.6L133.01 510.2C129.81 509.6 126.71 511.8 126.21 515C125.61 518.2 127.81 521.3 131.01 521.8L501.91 588.2C505.01 588.7 508.11 586.6 508.71 583.4Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_79'>
                      <path
                        id='Vector_226'
                        d='M508.71 583.4C509.31 580.2 507.11 577.1 503.91 576.6L133.01 510.2C129.81 509.6 126.71 511.8 126.21 515C125.61 518.2 127.81 521.3 131.01 521.8L501.91 588.2C505.01 588.7 508.11 586.6 508.71 583.4Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_80'>
                    <path
                      id='Rectangle_6-6_00000032619017582281747930000009377197628381344948_'
                      d='M508.7 583.361C509.3 580.161 507.101 577.061 503.901 576.561L133.001 510.161C129.801 509.561 126.7 511.761 126.2 514.961C125.6 518.161 127.801 521.261 131.001 521.761L501.901 588.161C505.001 588.761 508.1 586.561 508.7 583.361Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-5'>
                  <g id='rods_5'>
                    <g id='Group_81'>
                      <path
                        id='Rectangle_8-13_00000045606107138653663370000012200387380536633277_'
                        d='M107.91 500.501C110.61 498.601 111.21 495.001 109.41 492.301L71.0101 437.201C69.1101 434.501 65.5102 433.901 62.8102 435.701C60.1102 437.601 59.5102 441.201 61.3102 443.901L99.7101 499.001C101.51 501.701 105.21 502.301 107.91 500.501Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_82'>
                        <path
                          id='Rectangle_8-14_00000134223769396068988710000013027233989073319822_'
                          d='M107.9 500.462C110.6 498.562 111.2 494.962 109.4 492.262L71.0006 437.162C69.1006 434.462 65.5004 433.862 62.8004 435.662C60.1004 437.562 59.5004 441.162 61.3004 443.862L99.7003 498.962C101.5 501.662 105.2 502.362 107.9 500.462Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_83'>
                      <path
                        id='Rectangle_9-13_00000119107193059547761270000016636622583779549858_'
                        d='M104.11 518.901C102.31 516.201 98.6104 515.501 95.9104 517.301L40.1103 554.701C37.4103 556.501 36.7104 560.201 38.5104 562.901C40.3104 565.601 44.0103 566.301 46.7103 564.501L102.51 527.101C105.21 525.301 105.91 521.601 104.11 518.901Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_84'>
                        <path
                          id='Rectangle_9-14_00000078752256988453137130000006238979341699516566_'
                          d='M104.101 518.862C102.301 516.162 98.6005 515.462 95.9005 517.262L40.1007 554.662C37.4007 556.462 36.7006 560.162 38.5006 562.862C40.3006 565.562 44.0003 566.262 46.7003 564.462L102.501 527.062C105.201 525.262 105.901 521.662 104.101 518.862Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtasks-1_2'>
                    <g id='Group_85'>
                      <path
                        id='Ellipse_16-13_00000018923850168088123810000015611710361868021141_'
                        d='M114.61 530.2C124.772 530.2 133.01 521.962 133.01 511.8C133.01 501.638 124.772 493.4 114.61 493.4C104.448 493.4 96.21 501.638 96.21 511.8C96.21 521.962 104.448 530.2 114.61 530.2Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_86'>
                        <path
                          id='Ellipse_16-14_00000080926749351402468000000009500865285858713000_'
                          d='M114.601 530.261C124.763 530.261 133 522.023 133 511.861C133 501.699 124.763 493.461 114.601 493.461C104.439 493.461 96.2002 501.699 96.2002 511.861C96.2002 522.023 104.439 530.261 114.601 530.261Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000181782332939079325250000009391823215408675246_'
                      d='M130.71 507.5L127.71 522.6C127.21 525 128.81 527.3 131.11 527.7C133.51 528.2 135.81 526.6 136.21 524.3L139.21 509.2C139.71 506.8 138.11 504.5 135.81 504.1C133.51 503.6 131.21 505.1 130.71 507.5Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_13'
                      transform='translate(128.051 522.596) rotate(-78.0821)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_87' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000142871658856670440890000013442907883684945827_'
                        d='M114.601 524.56C121.615 524.56 127.3 518.874 127.3 511.86C127.3 504.846 121.615 499.16 114.601 499.16C107.587 499.16 101.9 504.846 101.9 511.86C101.9 518.874 107.587 524.56 114.601 524.56Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtasks-2_2'>
                    <g id='Group_88'>
                      <path
                        id='Ellipse_21-13_00000101064374774929345320000004340199387350241678_'
                        d='M57.3102 444.8C67.4722 444.8 75.7101 436.562 75.7101 426.4C75.7101 416.238 67.4722 408 57.3102 408C47.1481 408 38.9102 416.238 38.9102 426.4C38.9102 436.562 47.1481 444.8 57.3102 444.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_89'>
                        <path
                          id='Ellipse_21-14_00000096750979832587681670000012801600999468620942_'
                          d='M57.3003 444.761C67.4623 444.761 75.7002 436.523 75.7002 426.361C75.7002 416.199 67.4623 407.961 57.3003 407.961C47.1383 407.961 38.9004 416.199 38.9004 426.361C38.9004 436.523 47.1383 444.761 57.3003 444.761Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000065769857342570664060000018206349319867486098_'
                      d='M71.5098 421.5L68.5098 436.6C68.0098 439 69.6098 441.3 71.9098 441.7C74.3098 442.2 76.6098 440.6 77.0098 438.3L80.0098 423.2C80.5098 420.8 78.9097 418.5 76.6097 418.1C74.2097 417.6 71.9098 419.1 71.5098 421.5Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_14'
                      transform='translate(68.0947 436.621) rotate(-78.0821)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_90' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000149365643822296740290000000740947498337720229_'
                        d='M57.2993 439.06C64.3133 439.06 69.9995 433.374 69.9995 426.36C69.9995 419.346 64.3133 413.66 57.2993 413.66C50.2853 413.66 44.5996 419.346 44.5996 426.36C44.5996 433.374 50.2853 439.06 57.2993 439.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtasks-3_2'>
                    <g id='Group_91'>
                      <path
                        id='Ellipse_18-13_00000000918431923133180090000014647337375634277010_'
                        d='M28.4098 588.101C38.5718 588.101 46.8098 579.863 46.8098 569.701C46.8098 559.539 38.5718 551.301 28.4098 551.301C18.2478 551.301 10.0098 559.539 10.0098 569.701C10.0098 579.863 18.2478 588.101 28.4098 588.101Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_92'>
                        <path
                          id='Ellipse_18-14_00000062163015175735149220000011983730019761243809_'
                          d='M28.3999 588.062C38.5619 588.062 46.7998 579.824 46.7998 569.662C46.7998 559.5 38.5619 551.262 28.3999 551.262C18.2379 551.262 10 559.5 10 569.662C10 579.824 18.2379 588.062 28.3999 588.062Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000068661059624942814860000009012183264366639768_'
                      d='M43.3096 564.301L40.3096 579.401C39.8096 581.801 41.4095 584.101 43.7095 584.501C46.1095 585.001 48.4096 583.401 48.8096 581.101L51.8096 566.001C52.3096 563.601 50.7096 561.301 48.4096 560.901C46.1096 560.401 43.8096 561.901 43.3096 564.301Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_15'
                      transform='translate(39.7324 579.426) rotate(-78.0821)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_93' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000163058553558074208850000012164031241337786783_'
                        d='M28.3994 582.361C35.4134 582.361 41.0996 576.675 41.0996 569.661C41.0996 562.647 35.4134 556.961 28.3994 556.961C21.3854 556.961 15.6992 562.647 15.6992 569.661C15.6992 576.675 21.3854 582.361 28.3994 582.361Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[4].TasksAndStudents?.id || 0)}>
                  <g id='box-5'>
                    <g id='Group_94'>
                      <path
                        id='Vector_227'
                        opacity='0.0196078'
                        d='M306.81 571.401C305.61 577.401 299.81 581.301 293.81 580.101L257.81 573.001C251.81 571.801 247.909 566.001 249.109 560.001L256.209 524.001C257.409 518.001 263.209 514.101 269.209 515.301L305.209 522.401C311.209 523.601 315.11 529.401 313.91 535.401L306.81 571.401Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_228'
                        opacity='0.0392157'
                        d='M306.71 571.301C305.51 577.301 299.71 581.201 293.71 580.001L257.71 572.901C251.71 571.701 247.81 565.901 249.01 559.901L256.11 523.901C257.31 517.901 263.11 514.001 269.11 515.201L305.11 522.301C311.11 523.501 315.01 529.301 313.81 535.301L306.71 571.301Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_229'
                        opacity='0.0588235'
                        d='M306.71 571.2C305.51 577.2 299.71 581.1 293.71 579.9L257.71 572.8C251.71 571.6 247.81 565.8 249.01 559.8L256.11 523.8C257.31 517.8 263.11 513.9 269.11 515.1L305.11 522.2C311.11 523.4 315.01 529.2 313.81 535.2L306.71 571.2Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_230'
                        opacity='0.0784314'
                        d='M306.61 571.1C305.41 577.1 299.61 581 293.61 579.8L257.61 572.7C251.61 571.5 247.71 565.7 248.91 559.7L256.01 523.7C257.21 517.7 263.01 513.8 269.01 515L305.01 522.1C311.01 523.3 314.91 529.1 313.71 535.1L306.61 571.1Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_231'
                        opacity='0.0980392'
                        d='M306.51 571.001C305.31 577.001 299.51 580.901 293.51 579.701L257.51 572.601C251.51 571.401 247.61 565.601 248.81 559.601L255.91 523.601C257.11 517.601 262.91 513.701 268.91 514.901L304.91 522.001C310.91 523.201 314.81 529.001 313.61 535.001L306.51 571.001Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_232'
                        opacity='0.1176'
                        d='M306.51 570.901C305.31 576.901 299.51 580.801 293.51 579.601L257.51 572.501C251.51 571.301 247.61 565.501 248.81 559.501L255.91 523.501C257.11 517.501 262.91 513.601 268.91 514.801L304.91 521.901C310.91 523.101 314.81 528.901 313.61 534.901L306.51 570.901Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_233'
                        opacity='0.1373'
                        d='M306.41 570.801C305.21 576.801 299.41 580.701 293.41 579.501L257.41 572.401C251.41 571.201 247.51 565.401 248.71 559.401L255.81 523.401C257.01 517.401 262.81 513.501 268.81 514.701L304.81 521.801C310.81 523.001 314.71 528.801 313.51 534.801L306.41 570.801Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_234'
                        opacity='0.1569'
                        d='M306.31 570.6C305.11 576.6 299.31 580.5 293.31 579.3L257.31 572.2C251.31 571 247.409 565.2 248.609 559.2L255.709 523.2C256.909 517.2 262.709 513.3 268.709 514.5L304.709 521.6C310.709 522.8 314.61 528.6 313.41 534.6L306.31 570.6Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_235'
                        opacity='0.1765'
                        d='M306.21 570.501C305.01 576.501 299.21 580.401 293.21 579.201L257.21 572.101C251.21 570.901 247.31 565.101 248.51 559.101L255.61 523.101C256.81 517.101 262.61 513.201 268.61 514.401L304.61 521.501C310.61 522.701 314.51 528.501 313.31 534.501L306.21 570.501Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_236'
                        opacity='0.1961'
                        d='M306.21 570.401C305.01 576.401 299.21 580.301 293.21 579.101L257.21 572.001C251.21 570.801 247.31 565.001 248.51 559.001L255.61 523.001C256.81 517.001 262.61 513.101 268.61 514.301L304.61 521.401C310.61 522.601 314.51 528.401 313.31 534.401L306.21 570.401Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_237'
                        opacity='0.2157'
                        d='M306.11 570.301C304.91 576.301 299.11 580.201 293.11 579.001L257.11 571.901C251.11 570.701 247.21 564.901 248.41 558.901L255.51 522.901C256.71 516.901 262.51 513.001 268.51 514.201L304.51 521.301C310.51 522.501 314.41 528.301 313.21 534.301L306.11 570.301Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_238'
                        opacity='0.2353'
                        d='M306.01 570.2C304.81 576.2 299.01 580.1 293.01 578.9L257.01 571.8C251.01 570.6 247.11 564.8 248.31 558.8L255.41 522.8C256.61 516.8 262.41 512.9 268.41 514.1L304.41 521.2C310.41 522.4 314.31 528.2 313.11 534.2L306.01 570.2Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_239'
                        opacity='0.2549'
                        d='M305.91 570.1C304.71 576.1 298.91 580 292.91 578.8L256.91 571.7C250.91 570.5 247.01 564.7 248.21 558.7L255.31 522.7C256.51 516.7 262.31 512.8 268.31 514L304.31 521.1C310.31 522.3 314.21 528.1 313.01 534.1L305.91 570.1Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_240'
                        opacity='0.2745'
                        d='M305.91 570.001C304.71 576.001 298.91 579.901 292.91 578.701L256.91 571.601C250.91 570.401 247.01 564.601 248.21 558.601L255.31 522.601C256.51 516.601 262.31 512.701 268.31 513.901L304.31 521.001C310.31 522.201 314.21 528.001 313.01 534.001L305.91 570.001Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_241'
                        opacity='0.2941'
                        d='M305.81 569.901C304.61 575.901 298.81 579.801 292.81 578.601L256.81 571.501C250.81 570.301 246.909 564.501 248.109 558.501L255.209 522.501C256.409 516.501 262.209 512.601 268.209 513.801L304.209 520.901C310.209 522.101 314.11 527.901 312.91 533.901L305.81 569.901Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_242'
                        opacity='0.3137'
                        d='M305.71 569.801C304.51 575.801 298.71 579.701 292.71 578.501L256.71 571.401C250.71 570.201 246.81 564.401 248.01 558.401L255.11 522.401C256.31 516.401 262.11 512.501 268.11 513.701L304.11 520.801C310.11 522.001 314.01 527.801 312.81 533.801L305.71 569.801Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_243'
                        opacity='0.3333'
                        d='M305.61 569.7C304.41 575.7 298.61 579.6 292.61 578.4L256.61 571.3C250.61 570.1 246.71 564.3 247.91 558.3L255.01 522.3C256.21 516.3 262.01 512.4 268.01 513.6L304.01 520.7C310.01 521.9 313.91 527.7 312.71 533.7L305.61 569.7Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_244'
                        opacity='0.3529'
                        d='M305.61 569.6C304.41 575.6 298.61 579.5 292.61 578.3L256.61 571.2C250.61 570 246.71 564.2 247.91 558.2L255.01 522.2C256.21 516.2 262.01 512.3 268.01 513.5L304.01 520.6C310.01 521.8 313.91 527.6 312.71 533.6L305.61 569.6Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_245'
                        opacity='0.3725'
                        d='M305.51 569.501C304.31 575.501 298.51 579.401 292.51 578.201L256.51 571.101C250.51 569.901 246.61 564.101 247.81 558.101L254.91 522.101C256.11 516.101 261.91 512.201 267.91 513.401L303.91 520.501C309.91 521.701 313.81 527.501 312.61 533.501L305.51 569.501Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_246'
                        opacity='0.3922'
                        d='M305.41 569.301C304.21 575.301 298.41 579.201 292.41 578.001L256.41 570.901C250.41 569.701 246.51 563.901 247.71 557.901L254.81 521.901C256.01 515.901 261.81 512.001 267.81 513.201L303.81 520.301C309.81 521.501 313.71 527.301 312.51 533.301L305.41 569.301Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_247'
                        opacity='0.4118'
                        d='M305.41 569.2C304.21 575.2 298.41 579.1 292.41 577.9L256.41 570.8C250.41 569.6 246.51 563.8 247.71 557.8L254.81 521.8C256.01 515.8 261.81 511.9 267.81 513.1L303.81 520.2C309.81 521.4 313.71 527.2 312.51 533.2L305.41 569.2Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_248'
                        opacity='0.4314'
                        d='M305.31 569.1C304.11 575.1 298.31 579 292.31 577.8L256.31 570.7C250.31 569.5 246.409 563.7 247.609 557.7L254.709 521.7C255.909 515.7 261.709 511.8 267.709 513L303.709 520.1C309.709 521.3 313.61 527.1 312.41 533.1L305.31 569.1Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_249'
                        opacity='0.451'
                        d='M305.21 569.001C304.01 575.001 298.21 578.901 292.21 577.701L256.21 570.601C250.21 569.401 246.31 563.601 247.51 557.601L254.61 521.601C255.81 515.601 261.61 511.701 267.61 512.901L303.61 520.001C309.61 521.201 313.51 527.001 312.31 533.001L305.21 569.001Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_250'
                        opacity='0.4706'
                        d='M305.11 568.901C303.91 574.901 298.11 578.801 292.11 577.601L256.11 570.501C250.11 569.301 246.21 563.501 247.41 557.501L254.51 521.501C255.71 515.501 261.51 511.601 267.51 512.801L303.51 519.901C309.51 521.101 313.41 526.901 312.21 532.901L305.11 568.901Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_251'
                        opacity='0.4902'
                        d='M305.11 568.801C303.91 574.801 298.11 578.701 292.11 577.501L256.11 570.401C250.11 569.201 246.21 563.401 247.41 557.401L254.51 521.401C255.71 515.401 261.51 511.501 267.51 512.701L303.51 519.801C309.51 521.001 313.41 526.801 312.21 532.801L305.11 568.801Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_252'
                        opacity='0.5098'
                        d='M305.01 568.7C303.81 574.7 298.01 578.6 292.01 577.4L256.01 570.3C250.01 569.1 246.11 563.3 247.31 557.3L254.41 521.3C255.61 515.3 261.41 511.4 267.41 512.6L303.41 519.7C309.41 520.9 313.31 526.7 312.11 532.7L305.01 568.7Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_253'
                        opacity='0.5294'
                        d='M304.91 568.6C303.71 574.6 297.91 578.5 291.91 577.3L255.91 570.2C249.91 569 246.01 563.2 247.21 557.2L254.31 521.2C255.51 515.2 261.31 511.3 267.31 512.5L303.31 519.6C309.31 520.8 313.21 526.6 312.01 532.6L304.91 568.6Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_254'
                        opacity='0.549'
                        d='M304.81 568.501C303.61 574.501 297.81 578.401 291.81 577.201L255.81 570.101C249.81 568.901 245.909 563.101 247.109 557.101L254.209 521.101C255.409 515.101 261.209 511.201 267.209 512.401L303.209 519.501C309.209 520.701 313.11 526.501 311.91 532.501L304.81 568.501Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_255'
                        opacity='0.5686'
                        d='M304.81 568.401C303.61 574.401 297.81 578.301 291.81 577.101L255.81 570.001C249.81 568.801 245.909 563.001 247.109 557.001L254.209 521.001C255.409 515.001 261.209 511.101 267.209 512.301L303.209 519.401C309.209 520.601 313.11 526.401 311.91 532.401L304.81 568.401Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_256'
                        opacity='0.5882'
                        d='M304.71 568.301C303.51 574.301 297.71 578.201 291.71 577.001L255.71 569.901C249.71 568.701 245.81 562.901 247.01 556.901L254.11 520.901C255.31 514.901 261.11 511.001 267.11 512.201L303.11 519.301C309.11 520.501 313.01 526.301 311.81 532.301L304.71 568.301Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_257'
                        opacity='0.6078'
                        d='M304.61 568.1C303.41 574.1 297.61 578 291.61 576.8L255.61 569.7C249.61 568.5 245.71 562.7 246.91 556.7L254.01 520.7C255.21 514.7 261.01 510.8 267.01 512L303.01 519.1C309.01 520.3 312.91 526.1 311.71 532.1L304.61 568.1Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_258'
                        opacity='0.6275'
                        d='M304.61 568.001C303.41 574.001 297.61 577.901 291.61 576.701L255.61 569.601C249.61 568.401 245.71 562.601 246.91 556.601L254.01 520.601C255.21 514.601 261.01 510.701 267.01 511.901L303.01 519.001C309.01 520.201 312.91 526.001 311.71 532.001L304.61 568.001Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_259'
                        opacity='0.6471'
                        d='M304.51 567.901C303.31 573.901 297.51 577.801 291.51 576.601L255.51 569.501C249.51 568.301 245.61 562.501 246.81 556.501L253.91 520.501C255.11 514.501 260.91 510.601 266.91 511.801L302.91 518.901C308.91 520.101 312.81 525.901 311.61 531.901L304.51 567.901Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_260'
                        opacity='0.6667'
                        d='M304.41 567.801C303.21 573.801 297.41 577.701 291.41 576.501L255.41 569.401C249.41 568.201 245.51 562.401 246.71 556.401L253.81 520.401C255.01 514.401 260.81 510.501 266.81 511.701L302.81 518.801C308.81 520.001 312.71 525.801 311.51 531.801L304.41 567.801Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_261'
                        opacity='0.6863'
                        d='M304.31 567.7C303.11 573.7 297.31 577.6 291.31 576.4L255.31 569.3C249.31 568.1 245.409 562.3 246.609 556.3L253.709 520.3C254.909 514.3 260.709 510.4 266.709 511.6L302.709 518.7C308.709 519.9 312.61 525.7 311.41 531.7L304.31 567.7Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_262'
                        opacity='0.7059'
                        d='M304.31 567.6C303.11 573.6 297.31 577.5 291.31 576.3L255.31 569.2C249.31 568 245.409 562.2 246.609 556.2L253.709 520.2C254.909 514.2 260.709 510.3 266.709 511.5L302.709 518.6C308.709 519.8 312.61 525.6 311.41 531.6L304.31 567.6Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_263'
                        opacity='0.7255'
                        d='M304.21 567.501C303.01 573.501 297.21 577.401 291.21 576.201L255.21 569.101C249.21 567.901 245.31 562.101 246.51 556.101L253.61 520.101C254.81 514.101 260.61 510.201 266.61 511.401L302.61 518.501C308.61 519.701 312.51 525.501 311.31 531.501L304.21 567.501Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_264'
                        opacity='0.7451'
                        d='M304.11 567.401C302.91 573.401 297.11 577.301 291.11 576.101L255.11 569.001C249.11 567.801 245.21 562.001 246.41 556.001L253.51 520.001C254.71 514.001 260.51 510.101 266.51 511.301L302.51 518.401C308.51 519.601 312.41 525.401 311.21 531.401L304.11 567.401Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_265'
                        opacity='0.7647'
                        d='M304.01 567.301C302.81 573.301 297.01 577.201 291.01 576.001L255.01 568.901C249.01 567.701 245.11 561.901 246.31 555.901L253.41 519.901C254.61 513.901 260.41 510.001 266.41 511.201L302.41 518.301C308.41 519.501 312.31 525.301 311.11 531.301L304.01 567.301Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_266'
                        opacity='0.7843'
                        d='M304.01 567.2C302.81 573.2 297.01 577.1 291.01 575.9L255.01 568.8C249.01 567.6 245.11 561.8 246.31 555.8L253.41 519.8C254.61 513.8 260.41 509.9 266.41 511.1L302.41 518.2C308.41 519.4 312.31 525.2 311.11 531.2L304.01 567.2Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_267'
                        opacity='0.8039'
                        d='M303.91 567.1C302.71 573.1 296.91 577 290.91 575.8L254.91 568.7C248.91 567.5 245.01 561.7 246.21 555.7L253.31 519.7C254.51 513.7 260.31 509.8 266.31 511L302.31 518.1C308.31 519.3 312.21 525.1 311.01 531.1L303.91 567.1Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_268'
                        opacity='0.8235'
                        d='M303.81 567.001C302.61 573.001 296.81 576.901 290.81 575.701L254.81 568.601C248.81 567.401 244.909 561.601 246.109 555.601L253.209 519.601C254.409 513.601 260.209 509.701 266.209 510.901L302.209 518.001C308.209 519.201 312.11 525.001 310.91 531.001L303.81 567.001Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_269'
                        opacity='0.8431'
                        d='M303.81 566.801C302.61 572.801 296.81 576.701 290.81 575.501L254.81 568.401C248.81 567.201 244.909 561.401 246.109 555.401L253.209 519.401C254.409 513.401 260.209 509.501 266.209 510.701L302.209 517.801C308.209 519.001 312.11 524.801 310.91 530.801L303.81 566.801Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_270'
                        opacity='0.8627'
                        d='M303.71 566.7C302.51 572.7 296.71 576.6 290.71 575.4L254.71 568.3C248.71 567.1 244.81 561.3 246.01 555.3L253.11 519.3C254.31 513.3 260.11 509.4 266.11 510.6L302.11 517.7C308.11 518.9 312.01 524.7 310.81 530.7L303.71 566.7Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_271'
                        opacity='0.8824'
                        d='M303.61 566.6C302.41 572.6 296.61 576.5 290.61 575.3L254.61 568.2C248.61 567 244.71 561.2 245.91 555.2L253.01 519.2C254.21 513.2 260.01 509.3 266.01 510.5L302.01 517.6C308.01 518.8 311.91 524.6 310.71 530.6L303.61 566.6Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_272'
                        opacity='0.902'
                        d='M303.51 566.501C302.31 572.501 296.51 576.401 290.51 575.201L254.51 568.101C248.51 566.901 244.61 561.101 245.81 555.101L252.91 519.101C254.11 513.101 259.91 509.201 265.91 510.401L301.91 517.501C307.91 518.701 311.81 524.501 310.61 530.501L303.51 566.501Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_273'
                        opacity='0.9216'
                        d='M303.51 566.401C302.31 572.401 296.51 576.301 290.51 575.101L254.51 568.001C248.51 566.801 244.61 561.001 245.81 555.001L252.91 519.001C254.11 513.001 259.91 509.101 265.91 510.301L301.91 517.401C307.91 518.601 311.81 524.401 310.61 530.401L303.51 566.401Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_274'
                        opacity='0.9412'
                        d='M303.41 566.301C302.21 572.301 296.41 576.201 290.41 575.001L254.41 567.901C248.41 566.701 244.51 560.901 245.71 554.901L252.81 518.901C254.01 512.901 259.81 509.001 265.81 510.201L301.81 517.301C307.81 518.501 311.71 524.301 310.51 530.301L303.41 566.301Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_275'
                        opacity='0.9608'
                        d='M303.31 566.2C302.11 572.2 296.31 576.1 290.31 574.9L254.31 567.8C248.31 566.6 244.409 560.8 245.609 554.8L252.709 518.8C253.909 512.8 259.709 508.9 265.709 510.1L301.709 517.2C307.709 518.4 311.61 524.2 310.41 530.2L303.31 566.2Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_276'
                        opacity='0.9804'
                        d='M303.21 566.1C302.01 572.1 296.21 576 290.21 574.8L254.21 567.7C248.21 566.5 244.31 560.7 245.51 554.7L252.61 518.7C253.81 512.7 259.61 508.8 265.61 510L301.61 517.1C307.61 518.3 311.51 524.1 310.31 530.1L303.21 566.1Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_277'
                        d='M303.21 566.001C302.01 572.001 296.21 575.901 290.21 574.701L254.21 567.601C248.21 566.401 244.31 560.601 245.51 554.601L252.61 518.601C253.81 512.601 259.61 508.701 265.61 509.901L301.61 517.001C307.61 518.201 311.51 524.001 310.31 530.001L303.21 566.001Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_278'
                      d='M302.31 564.901C301.11 570.801 295.41 574.601 289.51 573.501L254.11 566.501C248.21 565.301 244.41 559.601 245.51 553.701L252.51 518.301C253.71 512.401 259.41 508.601 265.31 509.701L300.71 516.701C306.61 517.901 310.41 523.601 309.31 529.501L302.31 564.901Z'
                      fill='url(#paint4_linear_475_685)'
                    />
                    <path
                      id='Vector_279'
                      d='M301.81 564.801C300.71 570.401 295.21 574.101 289.61 573.001L254.21 566.001C248.61 564.901 244.91 559.401 246.01 553.801L253.01 518.401C254.11 512.801 259.61 509.101 265.21 510.201L300.61 517.201C306.21 518.301 309.91 523.801 308.81 529.401L301.81 564.801Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 5'
                      transform='translate(256.674 558.59) rotate(-80.078)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[4].name && task[4].name.length > 3
                          ? truncateText(task[4].name, 4)
                          : task[4].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_280'
                      d='M293.209 558.402L298.209 533.202C298.909 529.702 296.609 526.202 293.009 525.502L290.909 525.102C287.409 524.402 283.909 526.702 283.209 530.302L278.209 555.502C277.509 559.002 279.809 562.502 283.409 563.202L285.509 563.602C289.109 564.302 292.509 562.002 293.209 558.402Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_5'
                      transform='translate(279.716 551.664) rotate(-80.078)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[4].counter + '/3'} */}
                        {(task[4]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[4]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[5] !== undefined && task[5] !== null && (
              <g id='task-6'>
                <g id='rod-6'>
                  <g id='Rectangle_6-5_00000162334207725439484380000001061687261365598377_'>
                    <g id='Group_95'>
                      <path
                        id='Vector_281'
                        d='M546.51 525.1C549.21 523.2 549.81 519.5 547.91 516.9L329.21 210.1C327.31 207.4 323.61 206.8 321.01 208.7C318.31 210.6 317.71 214.3 319.61 216.9L538.31 523.7C540.11 526.4 543.81 527 546.51 525.1Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_96'>
                      <path
                        id='Vector_282'
                        d='M546.51 525.1C549.21 523.2 549.81 519.5 547.91 516.9L329.21 210.1C327.31 207.4 323.61 206.8 321.01 208.7C318.31 210.6 317.71 214.3 319.61 216.9L538.31 523.7C540.11 526.4 543.81 527 546.51 525.1Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_97'>
                    <path
                      id='Rectangle_6-6_00000038388142594759169860000001874851681951601808_'
                      d='M546.5 525.061C549.2 523.161 549.8 519.461 547.9 516.861L329.2 210.061C327.3 207.361 323.6 206.761 321 208.661C318.3 210.561 317.7 214.261 319.6 216.861L538.3 523.661C540.2 526.361 543.8 526.961 546.5 525.061Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-6'>
                  <g id='rods_6'>
                    <g id='Group_98'>
                      <path
                        id='Rectangle_8-13_00000032636529266300545160000018384641202482347429_'
                        d='M317.911 185.5C321.111 186 324.21 183.9 324.71 180.7L335.811 114.5C336.311 111.3 334.211 108.2 331.011 107.7C327.811 107.2 324.71 109.3 324.21 112.5L313.11 178.7C312.51 182 314.711 185 317.911 185.5Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_99'>
                        <path
                          id='Rectangle_8-14_00000176041813899250793570000009048681737932531338_'
                          d='M317.901 185.561C321.101 186.061 324.2 183.961 324.7 180.761L335.8 114.561C336.3 111.361 334.201 108.261 331.001 107.761C327.801 107.261 324.7 109.361 324.2 112.561L313.101 178.761C312.501 181.961 314.701 184.961 317.901 185.561Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_100'>
                      <path
                        id='Rectangle_9-13_00000142141175300357665090000000001605543290204860_'
                        d='M302.31 196.099C302.91 192.899 300.81 189.799 297.61 189.199L231.61 176.899C228.41 176.299 225.31 178.399 224.71 181.599C224.11 184.799 226.21 187.899 229.41 188.499L295.41 200.799C298.61 201.399 301.71 199.299 302.31 196.099Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_101'>
                        <path
                          id='Rectangle_9-14_00000160869301874983440910000017486757860988169889_'
                          d='M302.3 196.06C302.9 192.86 300.8 189.76 297.6 189.16L231.6 176.86C228.4 176.26 225.3 178.36 224.7 181.56C224.1 184.76 226.2 187.86 229.4 188.46L295.4 200.76C298.7 201.36 301.7 199.26 302.3 196.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_5'>
                    <g id='Group_102'>
                      <path
                        id='Ellipse_16-13_00000018959856826017837100000010779156939685883522_'
                        d='M314.81 216.8C324.972 216.8 333.21 208.562 333.21 198.4C333.21 188.238 324.972 180 314.81 180C304.648 180 296.41 188.238 296.41 198.4C296.41 208.562 304.648 216.8 314.81 216.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_103'>
                        <path
                          id='Ellipse_16-14_00000053536234042952153740000007810914509533102015_'
                          d='M314.8 216.761C324.962 216.761 333.2 208.523 333.2 198.361C333.2 188.199 324.962 179.961 314.8 179.961C304.638 179.961 296.4 188.199 296.4 198.361C296.4 208.523 304.638 216.761 314.8 216.761Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000135651690187971557580000001675605600764267919_'
                      d='M329.41 206.6L316.71 215.3C314.71 216.7 314.21 219.4 315.61 221.4C317.01 223.4 319.71 223.9 321.71 222.5L334.41 213.8C336.41 212.4 336.91 209.7 335.51 207.7C334.11 205.7 331.41 205.2 329.41 206.6Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_16'
                      transform='translate(316.84 215.477) rotate(-33.7171)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_104' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000112613512978391888470000010760221572038005946_'
                        d='M314.8 211.06C321.814 211.06 327.5 205.374 327.5 198.36C327.5 191.346 321.814 185.66 314.8 185.66C307.786 185.66 302.101 191.346 302.101 198.36C302.101 205.374 307.786 211.06 314.8 211.06Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_4'>
                    <g id='Group_105'>
                      <path
                        id='Ellipse_21-13_00000178890594373647932580000004572724076735463826_'
                        d='M333.609 115.601C343.771 115.601 352.009 107.363 352.009 97.2008C352.009 87.0387 343.771 78.8008 333.609 78.8008C323.447 78.8008 315.209 87.0387 315.209 97.2008C315.209 107.363 323.447 115.601 333.609 115.601Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_106'>
                        <path
                          id='Ellipse_21-14_00000061469996901490894620000005702498185025097602_'
                          d='M333.6 115.661C343.762 115.661 352 107.423 352 97.2613C352 87.0993 343.762 78.8613 333.6 78.8613C323.438 78.8613 315.199 87.0993 315.199 97.2613C315.199 107.423 323.438 115.661 333.6 115.661Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000047761137318904433530000008234334428772515249_'
                      d='M347.109 103.601L334.409 112.301C332.409 113.701 331.909 116.401 333.309 118.401C334.709 120.401 337.409 120.901 339.409 119.501L352.109 110.801C354.109 109.401 354.609 106.701 353.209 104.701C351.809 102.801 349.109 102.301 347.109 103.601Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_17'
                      transform='translate(334.092 112.09) rotate(-33.7171)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_107' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000083081364199817190490000006103656503934014886_'
                        d='M333.6 109.961C340.614 109.961 346.299 104.275 346.299 97.2605C346.299 90.2465 340.614 84.5605 333.6 84.5605C326.586 84.5605 320.899 90.2465 320.899 97.2605C320.899 104.275 326.586 109.961 333.6 109.961Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_5'>
                    <g id='Group_108'>
                      <path
                        id='Ellipse_18-13_00000144297906403863621660000004778683347076999564_'
                        d='M212.709 197.8C222.872 197.8 231.109 189.562 231.109 179.4C231.109 169.238 222.872 161 212.709 161C202.547 161 194.31 169.238 194.31 179.4C194.31 189.562 202.547 197.8 212.709 197.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_109'>
                        <path
                          id='Ellipse_18-14_00000096030271344323877810000002885186987564575166_'
                          d='M212.7 197.861C222.862 197.861 231.1 189.623 231.1 179.461C231.1 169.299 222.862 161.061 212.7 161.061C202.538 161.061 194.3 169.299 194.3 179.461C194.3 189.623 202.538 197.861 212.7 197.861Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000088127357337964700440000017854569593599880075_'
                      d='M227.11 186.1L214.41 194.8C212.41 196.2 211.91 198.9 213.31 200.9C214.71 202.9 217.41 203.4 219.41 202L232.11 193.3C234.11 191.9 234.61 189.2 233.21 187.2C231.91 185.2 229.11 184.7 227.11 186.1Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_18'
                      transform='translate(213.967 194.35) rotate(-33.7171)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_110' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000074422701148177138910000017972813109675470520_'
                        d='M212.7 192.16C219.714 192.16 225.4 186.474 225.4 179.46C225.4 172.446 219.714 166.76 212.7 166.76C205.686 166.76 200 172.446 200 179.46C200 186.474 205.686 192.16 212.7 192.16Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[5].TasksAndStudents?.id || 0)}>
                  <g id='box-6'>
                    <g id='Group_111'>
                      <path
                        id='Vector_283'
                        opacity='0.0196078'
                        d='M410.609 375.401C405.609 378.901 398.709 377.601 395.209 372.501L374.409 342.201C370.909 337.201 372.209 330.301 377.309 326.801L407.609 306.001C412.609 302.501 419.509 303.801 423.009 308.901L443.809 339.201C447.309 344.201 446.009 351.101 440.909 354.601L410.609 375.401Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_284'
                        opacity='0.0392157'
                        d='M410.609 375.201C405.609 378.701 398.709 377.401 395.209 372.301L374.409 342.001C370.909 337.001 372.209 330.101 377.309 326.601L407.609 305.801C412.609 302.301 419.509 303.601 423.009 308.701L443.809 339.001C447.309 344.001 446.009 350.901 440.909 354.401L410.609 375.201Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_285'
                        opacity='0.0588235'
                        d='M410.609 375.101C405.609 378.601 398.709 377.301 395.209 372.201L374.409 341.901C370.909 336.901 372.209 330.001 377.309 326.501L407.509 305.801C412.509 302.301 419.409 303.601 422.909 308.701L443.709 339.001C447.209 344.001 445.909 350.901 440.809 354.401L410.609 375.101Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_286'
                        opacity='0.0784314'
                        d='M410.609 375C405.609 378.5 398.709 377.2 395.209 372.1L374.409 341.8C370.909 336.8 372.209 329.9 377.309 326.4L407.609 305.6C412.609 302.1 419.509 303.4 423.009 308.5L443.809 338.8C447.309 343.8 446.009 350.7 440.909 354.2L410.609 375Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_287'
                        opacity='0.0980392'
                        d='M410.609 374.901C405.609 378.401 398.709 377.101 395.209 372.001L374.409 341.701C370.909 336.701 372.209 329.801 377.309 326.301L407.609 305.501C412.609 302.001 419.509 303.301 423.009 308.401L443.809 338.701C447.309 343.701 446.009 350.601 440.909 354.101L410.609 374.901Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_288'
                        opacity='0.1176'
                        d='M410.709 374.701C405.709 378.201 398.809 376.901 395.309 371.801L374.509 341.501C371.009 336.501 372.309 329.601 377.409 326.101L407.709 305.301C412.709 301.801 419.609 303.101 423.109 308.201L443.909 338.501C447.409 343.501 446.109 350.401 441.009 353.901L410.709 374.701Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_289'
                        opacity='0.1373'
                        d='M410.709 374.6C405.709 378.1 398.809 376.8 395.309 371.7L374.509 341.4C371.009 336.4 372.309 329.5 377.409 326L407.709 305.2C412.709 301.7 419.609 303 423.109 308.1L443.909 338.4C447.409 343.4 446.109 350.3 441.009 353.8L410.709 374.6Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_290'
                        opacity='0.1569'
                        d='M410.709 374.5C405.709 378 398.809 376.7 395.309 371.6L374.509 341.3C371.009 336.3 372.309 329.4 377.409 325.9L407.709 305.1C412.709 301.6 419.609 302.9 423.109 308L443.909 338.3C447.409 343.3 446.109 350.2 441.009 353.7L410.709 374.5Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_291'
                        opacity='0.1765'
                        d='M410.709 374.301C405.709 377.801 398.809 376.501 395.309 371.401L374.509 341.101C371.009 336.101 372.309 329.201 377.409 325.701L407.709 304.901C412.709 301.401 419.609 302.701 423.109 307.801L443.909 338.101C447.409 343.101 446.109 350.001 441.009 353.501L410.709 374.301Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_292'
                        opacity='0.1961'
                        d='M410.809 374.201C405.809 377.701 398.909 376.401 395.409 371.301L374.609 341.001C371.109 336.001 372.409 329.101 377.509 325.601L407.809 304.801C412.809 301.301 419.709 302.601 423.209 307.701L444.009 338.001C447.509 343.001 446.209 349.901 441.109 353.401L410.809 374.201Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_293'
                        opacity='0.2157'
                        d='M410.809 374.1C405.809 377.6 398.909 376.3 395.409 371.2L374.609 340.9C371.109 335.9 372.409 329 377.509 325.5L407.809 304.7C412.809 301.2 419.709 302.5 423.209 307.6L444.009 337.9C447.509 342.9 446.209 349.8 441.109 353.3L410.809 374.1Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_294'
                        opacity='0.2353'
                        d='M410.809 374C405.809 377.5 398.909 376.2 395.409 371.1L374.609 340.8C371.109 335.8 372.409 328.9 377.509 325.4L407.809 304.6C412.809 301.1 419.709 302.4 423.209 307.5L444.009 337.8C447.509 342.8 446.209 349.7 441.109 353.2L410.809 374Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_295'
                        opacity='0.2549'
                        d='M410.809 373.801C405.809 377.301 398.909 376.001 395.409 370.901L374.609 340.601C371.109 335.601 372.409 328.701 377.509 325.201L407.809 304.401C412.809 300.901 419.709 302.201 423.209 307.301L444.009 337.601C447.509 342.601 446.209 349.501 441.109 353.001L410.809 373.801Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_296'
                        opacity='0.2745'
                        d='M410.91 373.701C405.91 377.201 399.01 375.901 395.51 370.801L374.71 340.501C371.21 335.501 372.51 328.601 377.61 325.101L407.91 304.301C412.91 300.801 419.81 302.101 423.31 307.201L444.11 337.501C447.61 342.501 446.31 349.401 441.21 352.901L410.91 373.701Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_297'
                        opacity='0.2941'
                        d='M410.91 373.6C405.91 377.1 399.01 375.8 395.51 370.7L374.71 340.4C371.21 335.4 372.51 328.5 377.61 325L407.91 304.2C412.91 300.7 419.81 302 423.31 307.1L444.11 337.4C447.61 342.4 446.31 349.3 441.21 352.8L410.91 373.6Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_298'
                        opacity='0.3137'
                        d='M410.91 373.401C405.91 376.901 399.01 375.601 395.51 370.501L374.71 340.201C371.21 335.201 372.51 328.301 377.61 324.801L407.91 304.001C412.91 300.501 419.81 301.801 423.31 306.901L444.11 337.201C447.61 342.201 446.31 349.101 441.21 352.601L410.91 373.401Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_299'
                        opacity='0.3333'
                        d='M410.91 373.301C405.91 376.801 399.01 375.501 395.51 370.401L374.71 340.101C371.21 335.101 372.51 328.201 377.61 324.701L407.91 303.901C412.91 300.401 419.81 301.701 423.31 306.801L444.11 337.101C447.61 342.101 446.31 349.001 441.21 352.501L410.91 373.301Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_300'
                        opacity='0.3529'
                        d='M411.009 373.201C406.009 376.701 399.109 375.401 395.609 370.301L374.809 340.001C371.309 335.001 372.609 328.101 377.709 324.601L408.009 303.801C413.009 300.301 419.909 301.601 423.409 306.701L444.209 337.001C447.709 342.001 446.409 348.901 441.309 352.401L411.009 373.201Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_301'
                        opacity='0.3725'
                        d='M411.009 373.1C406.009 376.6 399.109 375.3 395.609 370.2L374.809 339.9C371.309 334.9 372.609 328 377.709 324.5L408.009 303.7C413.009 300.2 419.909 301.5 423.409 306.6L444.209 336.9C447.709 341.9 446.409 348.8 441.309 352.3L411.009 373.1Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_302'
                        opacity='0.3922'
                        d='M411.009 372.901C406.009 376.401 399.109 375.101 395.609 370.001L374.809 339.701C371.309 334.701 372.609 327.801 377.709 324.301L408.009 303.501C413.009 300.001 419.909 301.301 423.409 306.401L444.209 336.701C447.709 341.701 446.409 348.601 441.309 352.101L411.009 372.901Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_303'
                        opacity='0.4118'
                        d='M411.009 372.801C406.009 376.301 399.109 375.001 395.609 369.901L374.809 339.601C371.309 334.601 372.609 327.701 377.709 324.201L408.009 303.401C413.009 299.901 419.909 301.201 423.409 306.301L444.209 336.601C447.709 341.601 446.409 348.501 441.309 352.001L411.009 372.801Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_304'
                        opacity='0.4314'
                        d='M411.109 372.701C406.109 376.201 399.209 374.901 395.709 369.801L374.909 339.501C371.409 334.501 372.709 327.601 377.809 324.101L408.109 303.301C413.109 299.801 420.009 301.101 423.509 306.201L444.309 336.501C447.809 341.501 446.509 348.401 441.409 351.901L411.109 372.701Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_305'
                        opacity='0.451'
                        d='M411.109 372.5C406.109 376 399.209 374.7 395.709 369.6L374.909 339.3C371.409 334.3 372.709 327.4 377.809 323.9L408.109 303.1C413.109 299.6 420.009 300.9 423.509 306L444.309 336.3C447.809 341.3 446.509 348.2 441.409 351.7L411.109 372.5Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_306'
                        opacity='0.4706'
                        d='M411.109 372.401C406.109 375.901 399.209 374.601 395.709 369.501L374.909 339.201C371.409 334.201 372.709 327.301 377.809 323.801L408.109 303.001C413.109 299.501 420.009 300.801 423.509 305.901L444.309 336.201C447.809 341.201 446.509 348.101 441.409 351.601L411.109 372.401Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_307'
                        opacity='0.4902'
                        d='M411.109 372.301C406.109 375.801 399.209 374.501 395.709 369.401L374.909 339.101C371.409 334.101 372.709 327.201 377.809 323.701L408.109 302.901C413.109 299.401 420.009 300.701 423.509 305.801L444.309 336.101C447.809 341.101 446.509 348.001 441.409 351.501L411.109 372.301Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_308'
                        opacity='0.5098'
                        d='M411.209 372.201C406.209 375.701 399.309 374.401 395.809 369.301L375.009 339.001C371.509 334.001 372.809 327.101 377.909 323.601L408.209 302.801C413.209 299.301 420.109 300.601 423.609 305.701L444.409 336.001C447.909 341.001 446.609 347.901 441.509 351.401L411.209 372.201Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_309'
                        opacity='0.5294'
                        d='M411.209 372C406.209 375.5 399.309 374.2 395.809 369.1L375.009 338.8C371.509 333.8 372.809 326.9 377.909 323.4L408.209 302.6C413.209 299.1 420.109 300.4 423.609 305.5L444.409 335.8C447.909 340.8 446.609 347.7 441.509 351.2L411.209 372Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_310'
                        opacity='0.549'
                        d='M411.209 371.901C406.209 375.401 399.309 374.101 395.809 369.001L375.009 338.701C371.509 333.701 372.809 326.801 377.909 323.301L408.209 302.501C413.209 299.001 420.109 300.301 423.609 305.401L444.409 335.701C447.909 340.701 446.609 347.601 441.509 351.101L411.209 371.901Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_311'
                        opacity='0.5686'
                        d='M411.209 371.801C406.209 375.301 399.309 374.001 395.809 368.901L375.009 338.601C371.509 333.601 372.809 326.701 377.909 323.201L408.209 302.401C413.209 298.901 420.109 300.201 423.609 305.301L444.409 335.601C447.909 340.601 446.609 347.501 441.509 351.001L411.209 371.801Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_312'
                        opacity='0.5882'
                        d='M411.209 371.6C406.209 375.1 399.309 373.8 395.809 368.7L375.009 338.4C371.509 333.4 372.809 326.5 377.909 323L408.209 302.2C413.209 298.7 420.109 300 423.609 305.1L444.409 335.4C447.909 340.4 446.609 347.3 441.509 350.8L411.209 371.6Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_313'
                        opacity='0.6078'
                        d='M411.309 371.5C406.309 375 399.409 373.7 395.909 368.6L375.109 338.3C371.609 333.3 372.909 326.4 378.009 322.9L408.309 302.1C413.309 298.6 420.209 299.9 423.709 305L444.509 335.3C448.009 340.3 446.709 347.2 441.609 350.7L411.309 371.5Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_314'
                        opacity='0.6275'
                        d='M411.309 371.401C406.309 374.901 399.409 373.601 395.909 368.501L375.109 338.201C371.609 333.201 372.909 326.301 378.009 322.801L408.309 302.001C413.309 298.501 420.209 299.801 423.709 304.901L444.509 335.201C448.009 340.201 446.709 347.101 441.609 350.601L411.309 371.401Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_315'
                        opacity='0.6471'
                        d='M411.309 371.301C406.309 374.801 399.409 373.501 395.909 368.401L375.109 338.101C371.609 333.101 372.909 326.201 378.009 322.701L408.309 301.901C413.309 298.401 420.209 299.701 423.709 304.801L444.509 335.101C448.009 340.101 446.709 347.001 441.609 350.501L411.309 371.301Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_316'
                        opacity='0.6667'
                        d='M411.309 371.1C406.309 374.6 399.409 373.3 395.909 368.2L375.109 337.9C371.609 332.9 372.909 326 378.009 322.5L408.309 301.7C413.309 298.2 420.209 299.5 423.709 304.6L444.509 334.9C448.009 339.9 446.709 346.8 441.609 350.3L411.309 371.1Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_317'
                        opacity='0.6863'
                        d='M411.41 371C406.41 374.5 399.51 373.2 396.01 368.1L375.21 337.8C371.71 332.8 373.01 325.9 378.11 322.4L408.41 301.6C413.41 298.1 420.31 299.4 423.81 304.5L444.51 334.8C448.01 339.8 446.71 346.7 441.61 350.2L411.41 371Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_318'
                        opacity='0.7059'
                        d='M411.41 370.901C406.41 374.401 399.51 373.101 396.01 368.001L375.21 337.701C371.71 332.701 373.01 325.801 378.11 322.301L408.41 301.501C413.41 298.001 420.31 299.301 423.81 304.401L444.61 334.701C448.11 339.701 446.81 346.601 441.71 350.101L411.41 370.901Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_319'
                        opacity='0.7255'
                        d='M411.41 370.701C406.41 374.201 399.51 372.901 396.01 367.801L375.21 337.501C371.71 332.501 373.01 325.601 378.11 322.101L408.41 301.301C413.41 297.801 420.31 299.101 423.81 304.201L444.61 334.501C448.11 339.501 446.81 346.401 441.71 349.901L411.41 370.701Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_320'
                        opacity='0.7451'
                        d='M411.41 370.6C406.41 374.1 399.51 372.8 396.01 367.7L375.21 337.4C371.71 332.4 373.01 325.5 378.11 322L408.41 301.2C413.41 297.7 420.31 299 423.81 304.1L444.61 334.4C448.11 339.4 446.81 346.3 441.71 349.8L411.41 370.6Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_321'
                        opacity='0.7647'
                        d='M411.509 370.5C406.509 374 399.609 372.7 396.109 367.6L375.309 337.3C371.809 332.3 373.109 325.4 378.209 321.9L408.509 301.1C413.509 297.6 420.409 298.9 423.909 304L444.709 334.3C448.209 339.3 446.909 346.2 441.809 349.7L411.509 370.5Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_322'
                        opacity='0.7843'
                        d='M411.509 370.401C406.509 373.901 399.609 372.601 396.109 367.501L375.309 337.201C371.809 332.201 373.109 325.301 378.209 321.801L408.509 301.001C413.509 297.501 420.409 298.801 423.909 303.901L444.709 334.201C448.209 339.201 446.909 346.101 441.809 349.601L411.509 370.401Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_323'
                        opacity='0.8039'
                        d='M411.509 370.201C406.509 373.701 399.609 372.401 396.109 367.301L375.309 337.001C371.809 332.001 373.109 325.101 378.209 321.601L408.509 300.801C413.509 297.301 420.409 298.601 423.909 303.701L444.709 334.001C448.209 339.001 446.909 345.901 441.809 349.401L411.509 370.201Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_324'
                        opacity='0.8235'
                        d='M411.509 370.101C406.509 373.601 399.609 372.301 396.109 367.201L375.309 336.901C371.809 331.901 373.109 325.001 378.209 321.501L408.509 300.801C413.509 297.301 420.409 298.601 423.909 303.701L444.709 334.001C448.209 339.001 446.909 345.901 441.809 349.401L411.509 370.101Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_325'
                        opacity='0.8431'
                        d='M411.609 370C406.609 373.5 399.709 372.2 396.209 367.1L375.409 336.8C371.909 331.8 373.209 324.9 378.309 321.4L408.609 300.6C413.609 297.1 420.509 298.4 424.009 303.5L444.809 333.8C448.309 338.8 447.009 345.7 441.909 349.2L411.609 370Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_326'
                        opacity='0.8627'
                        d='M411.609 369.801C406.609 373.301 399.709 372.001 396.209 366.901L375.409 336.601C371.909 331.601 373.209 324.701 378.309 321.201L408.609 300.401C413.609 296.901 420.509 298.201 424.009 303.301L444.809 333.601C448.309 338.601 447.009 345.501 441.909 349.001L411.609 369.801Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_327'
                        opacity='0.8824'
                        d='M411.609 369.701C406.609 373.201 399.709 371.901 396.209 366.801L375.409 336.501C371.909 331.501 373.209 324.601 378.309 321.101L408.609 300.301C413.609 296.801 420.509 298.101 424.009 303.201L444.809 333.501C448.309 338.501 447.009 345.401 441.909 348.901L411.609 369.701Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_328'
                        opacity='0.902'
                        d='M411.609 369.6C406.609 373.1 399.709 371.8 396.209 366.7L375.409 336.4C371.909 331.4 373.209 324.5 378.309 321L408.609 300.2C413.609 296.7 420.509 298 424.009 303.1L444.809 333.4C448.309 338.4 447.009 345.3 441.909 348.8L411.609 369.6Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_329'
                        opacity='0.9216'
                        d='M411.709 369.5C406.709 373 399.809 371.7 396.309 366.6L375.509 336.3C372.009 331.3 373.309 324.4 378.409 320.9L408.709 300.1C413.709 296.6 420.609 297.9 424.109 303L444.909 333.3C448.409 338.3 447.109 345.2 442.009 348.7L411.709 369.5Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_330'
                        opacity='0.9412'
                        d='M411.709 369.301C406.709 372.801 399.809 371.501 396.309 366.401L375.509 336.101C372.009 331.101 373.309 324.201 378.409 320.701L408.709 299.901C413.709 296.401 420.609 297.701 424.109 302.801L444.909 333.101C448.409 338.101 447.109 345.001 442.009 348.501L411.709 369.301Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_331'
                        opacity='0.9608'
                        d='M411.709 369.201C406.709 372.701 399.809 371.401 396.309 366.301L375.509 336.001C372.009 331.001 373.309 324.101 378.409 320.601L408.709 299.801C413.709 296.301 420.609 297.601 424.109 302.701L444.909 333.001C448.409 338.001 447.109 344.901 442.009 348.401L411.709 369.201Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_332'
                        opacity='0.9804'
                        d='M411.709 369.1C406.709 372.6 399.809 371.3 396.309 366.2L375.509 335.9C372.009 330.9 373.309 324 378.409 320.5L408.709 299.7C413.709 296.2 420.609 297.5 424.109 302.6L444.909 332.9C448.409 337.9 447.109 344.8 442.009 348.3L411.709 369.1Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_333'
                        d='M411.709 368.901C406.709 372.401 399.809 371.101 396.309 366.001L375.509 335.701C372.009 330.701 373.309 323.801 378.409 320.301L408.709 299.501C413.709 296.001 420.609 297.301 424.109 302.401L444.909 332.701C448.409 337.701 447.109 344.601 442.009 348.101L411.709 368.901Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_334'
                      d='M411.909 367.5C407.009 370.9 400.209 369.6 396.809 364.7L376.409 335C373.009 330.1 374.309 323.3 379.209 319.9L408.909 299.5C413.809 296.1 420.609 297.4 424.009 302.3L444.409 332C447.809 336.9 446.509 343.7 441.609 347.1L411.909 367.5Z'
                      fill='url(#paint5_linear_475_685)'
                    />
                    <path
                      id='Vector_335'
                      d='M411.609 367.101C406.909 370.301 400.409 369.101 397.109 364.401L376.709 334.701C373.509 330.001 374.709 323.501 379.409 320.201L409.109 299.801C413.809 296.601 420.309 297.801 423.609 302.501L444.009 332.201C447.209 336.901 446.009 343.401 441.309 346.701L411.609 367.101Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 6'
                      transform='translate(382.535 331.473) rotate(-34.4846)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[5].name && task[5].name.length > 3
                          ? truncateText(task[5].name, 4)
                          : task[5].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_336'
                      d='M409.909 356.599L431.109 342C434.109 340 434.809 335.899 432.809 332.899L431.609 331.2C429.609 328.2 425.509 327.5 422.509 329.5L401.309 344.099C398.309 346.099 397.609 350.2 399.609 353.2L400.809 354.899C402.909 357.799 406.909 358.599 409.909 356.599Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_6'
                      transform='translate(404.901 342.229) rotate(-35.7182)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[5].counter + '/3'} */}
                        {(task[5]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[5]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[6] !== undefined && task[6] !== null && (
              <g id='task-7'>
                <g id='rod-7'>
                  <g id='Rectangle_6-5_00000086658946618824760940000010670346040320941228_'>
                    <g id='Group_112'>
                      <path
                        id='Vector_337'
                        d='M615.509 510.101C612.309 509.501 610.209 506.401 610.809 503.201L682.009 133.201C682.609 130.001 685.709 127.901 688.909 128.501C692.109 129.101 694.209 132.201 693.609 135.401L622.409 505.401C621.809 508.601 618.709 510.701 615.509 510.101Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_113'>
                      <path
                        id='Vector_338'
                        d='M615.509 510.101C612.309 509.501 610.209 506.401 610.809 503.201L682.009 133.201C682.609 130.001 685.709 127.901 688.909 128.501C692.109 129.101 694.209 132.201 693.609 135.401L622.409 505.401C621.809 508.601 618.709 510.701 615.509 510.101Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_114'>
                    <path
                      id='Rectangle_6-6_00000029742956359504335160000015473618221618502023_'
                      d='M615.5 510.06C612.3 509.46 610.2 506.36 610.8 503.16L682 133.16C682.6 129.96 685.7 127.86 688.9 128.46C692.1 129.06 694.2 132.16 693.6 135.36L622.4 505.36C621.8 508.56 618.7 510.66 615.5 510.06Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-7'>
                  <g id='rods_7'>
                    <g id='Group_115'>
                      <path
                        id='Rectangle_8-13_00000010294333792747981940000008486171476098804142_'
                        d='M682.109 106.199C679.409 107.999 675.709 107.299 673.909 104.599L636.309 48.9994C634.509 46.2994 635.209 42.5994 637.909 40.7994C640.609 38.9994 644.309 39.6994 646.109 42.3994L683.709 97.9994C685.509 100.699 684.809 104.399 682.109 106.199Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_116'>
                        <path
                          id='Rectangle_8-14_00000075858824427830374150000015828866607287044760_'
                          d='M682.1 106.16C679.4 107.96 675.699 107.26 673.899 104.56L636.299 48.9604C634.499 46.2604 635.199 42.5604 637.899 40.7604C640.599 38.9604 644.3 39.6604 646.1 42.3604L683.699 97.9604C685.499 100.76 684.8 104.36 682.1 106.16Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_117'>
                      <path
                        id='Rectangle_9-13_00000052806079851731911630000001725999190299187611_'
                        d='M700.61 109.3C698.71 106.6 699.41 103 702.01 101.1L756.91 62.5002C759.61 60.6002 763.21 61.3002 765.11 63.9002C767.01 66.6002 766.31 70.2002 763.71 72.1002L708.81 110.7C706.21 112.6 702.51 112 700.61 109.3Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_118'>
                        <path
                          id='Rectangle_9-14_00000153699658238266753030000002232953460652171932_'
                          d='M700.699 109.261C698.799 106.561 699.5 102.961 702.1 101.061L757 62.4611C759.7 60.5611 763.299 61.2611 765.199 63.8611C767.099 66.5611 766.399 70.1611 763.799 72.0611L708.899 110.661C706.199 112.561 702.499 111.961 700.699 109.261Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_6'>
                    <g id='Group_119'>
                      <path
                        id='Ellipse_16-13_00000064348494761096162060000002183268296176680083_'
                        d='M690.208 134.999C700.371 134.999 708.609 126.761 708.609 116.599C708.609 106.437 700.371 98.1992 690.208 98.1992C680.046 98.1992 671.809 106.437 671.809 116.599C671.809 126.761 680.046 134.999 690.208 134.999Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_120'>
                        <path
                          id='Ellipse_16-14_00000173138642787067725340000012391754743288445329_'
                          d='M690.199 134.96C700.361 134.96 708.599 126.722 708.599 116.56C708.599 106.398 700.361 98.1602 690.199 98.1602C680.037 98.1602 671.799 106.398 671.799 116.56C671.799 126.722 680.037 134.96 690.199 134.96Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000160188271755281055070000004481878000305946288_'
                      d='M680.409 130.1L695.609 132.8C698.009 133.2 699.609 135.5 699.209 137.9C698.809 140.3 696.509 141.9 694.109 141.5L678.909 138.8C676.509 138.4 674.909 136.1 675.309 133.7C675.809 131.3 678.009 129.7 680.409 130.1Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_19'
                      transform='translate(680.875 130.582) rotate(9.89845)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_121' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000109729417346960665220000000914852986557542528_'
                        d='M690.299 129.259C697.313 129.259 703 123.573 703 116.559C703 109.545 697.313 103.859 690.299 103.859C683.285 103.859 677.6 109.545 677.6 116.559C677.6 123.573 683.285 129.259 690.299 129.259Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_5'>
                    <g id='Group_122'>
                      <path
                        id='Ellipse_21-13_00000173866122298775164790000017044099969139372979_'
                        d='M631.109 50.8C641.271 50.8 649.509 42.562 649.509 32.4C649.509 22.238 641.271 14 631.109 14C620.947 14 612.709 22.238 612.709 32.4C612.709 42.562 620.947 50.8 631.109 50.8Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_123'>
                        <path
                          id='Ellipse_21-14_00000151523848825028033300000001536948738606088078_'
                          d='M631.1 50.8605C641.262 50.8605 649.5 42.6226 649.5 32.4606C649.5 22.2985 641.262 14.0605 631.1 14.0605C620.938 14.0605 612.699 22.2985 612.699 32.4606C612.699 42.6226 620.938 50.8605 631.1 50.8605Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000159444230320139871660000001561257027304675727_'
                      d='M621.409 43.9011L636.609 46.6011C639.009 47.0011 640.609 49.3011 640.209 51.7011C639.809 54.1011 637.509 55.7011 635.109 55.3011L619.909 52.6011C617.509 52.2011 615.909 49.9011 616.309 47.5011C616.809 45.1011 619.009 43.5011 621.409 43.9011Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_20'
                      transform='translate(620.802 43.2031) rotate(9.89845)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_124' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000079470354101580870300000004440937182004951968_'
                        d='M631.1 45.1598C638.114 45.1598 643.799 39.4738 643.799 32.4598C643.799 25.4458 638.114 19.7598 631.1 19.7598C624.086 19.7598 618.399 25.4458 618.399 32.4598C618.399 39.4738 624.086 45.1598 631.1 45.1598Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_6'>
                    <g id='Group_125'>
                      <path
                        id='Ellipse_18-13_00000118394207375657752390000006657081514672674477_'
                        d='M775.208 75.3C785.371 75.3 793.609 67.062 793.609 56.9C793.609 46.738 785.371 38.5 775.208 38.5C765.046 38.5 756.809 46.738 756.809 56.9C756.809 67.062 765.046 75.3 775.208 75.3Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_126'>
                        <path
                          id='Ellipse_18-14_00000128481142384698631900000010377134240156026531_'
                          d='M775.199 75.2609C785.361 75.2609 793.599 67.023 793.599 56.8609C793.599 46.6989 785.361 38.4609 775.199 38.4609C765.037 38.4609 756.799 46.6989 756.799 56.8609C756.799 67.023 765.037 75.2609 775.199 75.2609Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000078035048675440542640000004500452633219797652_'
                      d='M764.809 68.9011L780.009 71.6011C782.409 72.0011 784.009 74.3011 783.609 76.7011C783.209 79.1011 780.909 80.7011 778.509 80.3011L763.309 77.6011C760.909 77.2011 759.309 74.9011 759.709 72.5011C760.209 70.1011 762.509 68.5011 764.809 68.9011Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_21'
                      transform='translate(764.214 68.2266) rotate(9.89845)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_127' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000046305514886335555250000017841052093611223216_'
                        d='M775.199 69.5602C782.213 69.5602 787.899 63.8742 787.899 56.8602C787.899 49.8462 782.213 44.1602 775.199 44.1602C768.185 44.1602 762.499 49.8462 762.499 56.8602C762.499 63.8742 768.185 69.5602 775.199 69.5602Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[6].TasksAndStudents?.id || 0)}>
                  <g id='box-7'>
                    <g id='Group_128'>
                      <g id='Group_129'>
                        <path
                          id='Vector_339'
                          opacity='0.0196078'
                          d='M676.81 317.4C682.81 318.4 688.61 314.4 689.61 308.4L695.91 272.2C696.91 266.2 692.91 260.4 686.91 259.4L650.71 253.1C644.71 252.1 638.91 256.1 637.91 262.1L631.61 298.3C630.61 304.3 634.61 310.1 640.61 311.1L676.81 317.4Z'
                          fill='#FBFCFD'
                        />
                        <path
                          id='Vector_340'
                          opacity='0.0392157'
                          d='M676.71 317.301C682.71 318.301 688.51 314.301 689.51 308.301L695.81 272.101C696.81 266.101 692.81 260.301 686.81 259.301L650.61 253.001C644.61 252.001 638.81 256.001 637.81 262.001L631.51 298.201C630.51 304.201 634.51 310.001 640.51 311.001L676.71 317.301Z'
                          fill='#F8F9FB'
                        />
                        <path
                          id='Vector_341'
                          opacity='0.0588235'
                          d='M676.71 317.199C682.71 318.199 688.51 314.199 689.51 308.199L695.81 271.999C696.81 265.999 692.81 260.199 686.81 259.199L650.61 252.899C644.61 251.899 638.81 255.899 637.81 261.899L631.51 298.099C630.51 304.099 634.51 309.899 640.51 310.899L676.71 317.199Z'
                          fill='#F4F6F9'
                        />
                        <path
                          id='Vector_342'
                          opacity='0.0784314'
                          d='M676.61 317.1C682.61 318.1 688.41 314.1 689.41 308.1L695.71 271.9C696.71 265.9 692.71 260.1 686.71 259.1L650.51 252.8C644.51 251.8 638.71 255.8 637.71 261.8L631.41 298C630.41 304 634.41 309.8 640.41 310.8L676.61 317.1Z'
                          fill='#F1F3F7'
                        />
                        <path
                          id='Vector_343'
                          opacity='0.0980392'
                          d='M676.51 316.9C682.51 317.9 688.31 313.9 689.31 307.9L695.61 271.7C696.61 265.7 692.61 259.9 686.61 258.9L650.41 252.6C644.41 251.6 638.61 255.6 637.61 261.6L631.31 297.8C630.31 303.8 634.31 309.6 640.31 310.6L676.51 316.9Z'
                          fill='#EDF0F4'
                        />
                        <path
                          id='Vector_344'
                          opacity='0.1176'
                          d='M676.41 316.801C682.41 317.801 688.21 313.801 689.21 307.801L695.51 271.601C696.51 265.601 692.51 259.801 686.51 258.801L650.31 252.501C644.31 251.501 638.51 255.501 637.51 261.501L631.21 297.701C630.21 303.701 634.21 309.501 640.21 310.501L676.41 316.801Z'
                          fill='#EAEDF2'
                        />
                        <path
                          id='Vector_345'
                          opacity='0.1373'
                          d='M676.41 316.699C682.41 317.699 688.21 313.699 689.21 307.699L695.51 271.499C696.51 265.499 692.51 259.699 686.51 258.699L650.31 252.399C644.31 251.399 638.51 255.399 637.51 261.399L631.21 297.599C630.21 303.599 634.21 309.399 640.21 310.399L676.41 316.699Z'
                          fill='#E6EAF0'
                        />
                        <path
                          id='Vector_346'
                          opacity='0.1569'
                          d='M676.31 316.6C682.31 317.6 688.11 313.6 689.11 307.6L695.41 271.4C696.41 265.4 692.41 259.6 686.41 258.6L650.21 252.3C644.21 251.3 638.41 255.3 637.41 261.3L631.11 297.5C630.11 303.5 634.11 309.3 640.11 310.3L676.31 316.6Z'
                          fill='#E3E7EE'
                        />
                        <path
                          id='Vector_347'
                          opacity='0.1765'
                          d='M676.21 316.5C682.21 317.5 688.01 313.5 689.01 307.5L695.31 271.3C696.31 265.3 692.31 259.5 686.31 258.5L650.11 252.2C644.11 251.2 638.31 255.2 637.31 261.2L631.01 297.4C630.01 303.4 634.01 309.2 640.01 310.2L676.21 316.5Z'
                          fill='#DFE3EC'
                        />
                        <path
                          id='Vector_348'
                          opacity='0.1961'
                          d='M676.11 316.4C682.11 317.4 687.91 313.4 688.91 307.4L695.21 271.2C696.21 265.2 692.21 259.4 686.21 258.4L650.01 252.1C644.01 251.1 638.21 255.1 637.21 261.1L630.91 297.3C629.91 303.3 633.91 309.1 639.91 310.1L676.11 316.4Z'
                          fill='#DCE0EA'
                        />
                        <path
                          id='Vector_349'
                          opacity='0.2157'
                          d='M676.11 316.301C682.11 317.301 687.91 313.301 688.91 307.301L695.21 271.101C696.21 265.101 692.21 259.301 686.21 258.301L650.01 252.001C644.01 251.001 638.21 255.001 637.21 261.001L630.91 297.201C629.91 303.201 633.91 309.001 639.91 310.001L676.11 316.301Z'
                          fill='#D8DDE8'
                        />
                        <path
                          id='Vector_350'
                          opacity='0.2353'
                          d='M676.01 316.199C682.01 317.199 687.81 313.199 688.81 307.199L695.11 270.999C696.11 264.999 692.11 259.199 686.11 258.199L649.91 251.899C643.91 250.899 638.11 254.899 637.11 260.899L630.81 297.099C629.81 303.099 633.81 308.899 639.81 309.899L676.01 316.199Z'
                          fill='#D4DAE6'
                        />
                        <path
                          id='Vector_351'
                          opacity='0.2549'
                          d='M675.91 316.1C681.91 317.1 687.71 313.1 688.71 307.1L695.01 270.9C696.01 264.9 692.01 259.1 686.01 258.1L649.81 251.8C643.81 250.8 638.01 254.8 637.01 260.8L630.71 297C629.71 303 633.71 308.8 639.71 309.8L675.91 316.1Z'
                          fill='#D1D7E3'
                        />
                        <path
                          id='Vector_352'
                          opacity='0.2745'
                          d='M675.81 316C681.81 317 687.61 313 688.61 307L694.91 270.8C695.91 264.8 691.91 259 685.91 258L649.71 251.7C643.71 250.7 637.91 254.7 636.91 260.7L630.61 296.9C629.61 302.9 633.61 308.7 639.61 309.7L675.81 316Z'
                          fill='#CDD4E1'
                        />
                        <path
                          id='Vector_353'
                          opacity='0.2941'
                          d='M675.81 315.9C681.81 316.9 687.61 312.9 688.61 306.9L694.91 270.7C695.91 264.7 691.91 258.9 685.91 257.9L649.71 251.6C643.71 250.6 637.91 254.6 636.91 260.6L630.61 296.8C629.61 302.8 633.61 308.6 639.61 309.6L675.81 315.9Z'
                          fill='#CAD1DF'
                        />
                        <path
                          id='Vector_354'
                          opacity='0.3137'
                          d='M675.71 315.801C681.71 316.801 687.51 312.801 688.51 306.801L694.81 270.601C695.81 264.601 691.81 258.801 685.81 257.801L649.61 251.501C643.61 250.501 637.81 254.501 636.81 260.501L630.51 296.701C629.51 302.701 633.51 308.501 639.51 309.501L675.71 315.801Z'
                          fill='#C6CEDD'
                        />
                        <path
                          id='Vector_355'
                          opacity='0.3333'
                          d='M675.61 315.699C681.61 316.699 687.41 312.699 688.41 306.699L694.71 270.499C695.71 264.499 691.71 258.699 685.71 257.699L649.51 251.399C643.51 250.399 637.71 254.399 636.71 260.399L630.41 296.599C629.41 302.599 633.41 308.399 639.41 309.399L675.61 315.699Z'
                          fill='#C3CBDB'
                        />
                        <path
                          id='Vector_356'
                          opacity='0.3529'
                          d='M675.51 315.6C681.51 316.6 687.31 312.6 688.31 306.6L694.61 270.4C695.61 264.4 691.61 258.6 685.61 257.6L649.41 251.3C643.41 250.3 637.61 254.3 636.61 260.3L630.31 296.5C629.31 302.5 633.31 308.3 639.31 309.3L675.51 315.6Z'
                          fill='#BFC8D9'
                        />
                        <path
                          id='Vector_357'
                          opacity='0.3725'
                          d='M675.51 315.4C681.51 316.4 687.31 312.4 688.31 306.4L694.61 270.2C695.61 264.2 691.61 258.4 685.61 257.4L649.41 251.1C643.41 250.1 637.61 254.1 636.61 260.1L630.31 296.3C629.31 302.3 633.31 308.1 639.31 309.1L675.51 315.4Z'
                          fill='#BCC5D7'
                        />
                        <path
                          id='Vector_358'
                          opacity='0.3922'
                          d='M675.41 315.301C681.41 316.301 687.21 312.301 688.21 306.301L694.51 270.101C695.51 264.101 691.51 258.301 685.51 257.301L649.31 251.001C643.31 250.001 637.51 254.001 636.51 260.001L630.21 296.201C629.21 302.201 633.21 308.001 639.21 309.001L675.41 315.301Z'
                          fill='#B8C2D5'
                        />
                        <path
                          id='Vector_359'
                          opacity='0.4118'
                          d='M675.31 315.199C681.31 316.199 687.11 312.199 688.11 306.199L694.41 269.999C695.41 263.999 691.41 258.199 685.41 257.199L649.21 250.899C643.21 249.899 637.41 253.899 636.41 259.899L630.11 296.099C629.11 302.099 633.11 307.899 639.11 308.899L675.31 315.199Z'
                          fill='#B4BFD3'
                        />
                        <path
                          id='Vector_360'
                          opacity='0.4314'
                          d='M675.21 315.1C681.21 316.1 687.01 312.1 688.01 306.1L694.31 269.9C695.31 263.9 691.31 258.1 685.31 257.1L649.11 250.8C643.11 249.8 637.31 253.8 636.31 259.8L630.01 296C629.01 302 633.01 307.8 639.01 308.8L675.21 315.1Z'
                          fill='#B1BCD0'
                        />
                        <path
                          id='Vector_361'
                          opacity='0.451'
                          d='M675.21 315C681.21 316 687.01 312 688.01 306L694.31 269.8C695.31 263.8 691.31 258 685.31 257L649.11 250.7C643.11 249.7 637.31 253.7 636.31 259.7L630.01 295.9C629.01 301.9 633.01 307.7 639.01 308.7L675.21 315Z'
                          fill='#ADB9CE'
                        />
                        <path
                          id='Vector_362'
                          opacity='0.4706'
                          d='M675.11 314.9C681.11 315.9 686.91 311.9 687.91 305.9L694.21 269.7C695.21 263.7 691.21 257.9 685.21 256.9L649.01 250.6C643.01 249.6 637.21 253.6 636.21 259.6L629.91 295.8C628.91 301.8 632.91 307.6 638.91 308.6L675.11 314.9Z'
                          fill='#AAB6CC'
                        />
                        <path
                          id='Vector_363'
                          opacity='0.4902'
                          d='M675.01 314.801C681.01 315.801 686.81 311.801 687.81 305.801L694.11 269.601C695.11 263.601 691.11 257.801 685.11 256.801L648.91 250.501C642.91 249.501 637.11 253.501 636.11 259.501L629.81 295.701C628.81 301.701 632.81 307.501 638.81 308.501L675.01 314.801Z'
                          fill='#A6B3CA'
                        />
                        <path
                          id='Vector_364'
                          opacity='0.5098'
                          d='M674.91 314.699C680.91 315.699 686.71 311.699 687.71 305.699L694.01 269.499C695.01 263.499 691.01 257.699 685.01 256.699L648.81 250.399C642.81 249.399 637.01 253.399 636.01 259.399L629.71 295.599C628.71 301.599 632.71 307.399 638.71 308.399L674.91 314.699Z'
                          fill='#A3AFC8'
                        />
                        <path
                          id='Vector_365'
                          opacity='0.5294'
                          d='M674.91 314.6C680.91 315.6 686.71 311.6 687.71 305.6L694.01 269.4C695.01 263.4 691.01 257.6 685.01 256.6L648.81 250.3C642.81 249.3 637.01 253.3 636.01 259.3L629.71 295.5C628.71 301.5 632.71 307.3 638.71 308.3L674.91 314.6Z'
                          fill='#9FACC6'
                        />
                        <path
                          id='Vector_366'
                          opacity='0.549'
                          d='M674.81 314.5C680.81 315.5 686.61 311.5 687.61 305.5L693.91 269.3C694.91 263.3 690.91 257.5 684.91 256.5L648.71 250.2C642.71 249.2 636.91 253.2 635.91 259.2L629.61 295.4C628.61 301.4 632.61 307.2 638.61 308.2L674.81 314.5Z'
                          fill='#9CA9C4'
                        />
                        <path
                          id='Vector_367'
                          opacity='0.5686'
                          d='M674.71 314.4C680.71 315.4 686.51 311.4 687.51 305.4L693.81 269.2C694.81 263.2 690.81 257.4 684.81 256.4L648.61 250.1C642.61 249.1 636.81 253.1 635.81 259.1L629.51 295.3C628.51 301.3 632.51 307.1 638.51 308.1L674.71 314.4Z'
                          fill='#98A6C2'
                        />
                        <path
                          id='Vector_368'
                          opacity='0.5882'
                          d='M674.61 314.301C680.61 315.301 686.41 311.301 687.41 305.301L693.71 269.101C694.71 263.101 690.71 257.301 684.71 256.301L648.51 250.001C642.51 249.001 636.71 253.001 635.71 259.001L629.41 295.201C628.41 301.201 632.41 307.001 638.41 308.001L674.61 314.301Z'
                          fill='#95A3BF'
                        />
                        <path
                          id='Vector_369'
                          opacity='0.6078'
                          d='M674.61 314.2C680.61 315.2 686.41 311.2 687.41 305.2L693.71 269C694.71 263 690.71 257.2 684.71 256.2L648.51 249.8C642.51 248.8 636.71 252.8 635.71 258.8L629.41 295C628.41 301 632.41 306.8 638.41 307.8L674.61 314.2Z'
                          fill='#91A0BD'
                        />
                        <path
                          id='Vector_370'
                          opacity='0.6275'
                          d='M674.51 314.1C680.51 315.1 686.31 311.1 687.31 305.1L693.61 268.9C694.61 262.9 690.61 257.1 684.61 256.1L648.41 249.8C642.41 248.8 636.61 252.8 635.61 258.8L629.31 295C628.31 301 632.31 306.8 638.31 307.8L674.51 314.1Z'
                          fill='#8D9DBB'
                        />
                        <path
                          id='Vector_371'
                          opacity='0.6471'
                          d='M674.41 313.9C680.41 314.9 686.21 310.9 687.21 304.9L693.51 268.7C694.51 262.7 690.51 256.9 684.51 255.9L648.31 249.6C642.31 248.6 636.51 252.6 635.51 258.6L629.21 294.8C628.21 300.8 632.21 306.6 638.21 307.6L674.41 313.9Z'
                          fill='#8A9AB9'
                        />
                        <path
                          id='Vector_372'
                          opacity='0.6667'
                          d='M674.31 313.801C680.31 314.801 686.11 310.801 687.11 304.801L693.41 268.601C694.41 262.601 690.41 256.801 684.41 255.801L648.21 249.501C642.21 248.501 636.41 252.501 635.41 258.501L629.11 294.701C628.11 300.701 632.11 306.501 638.11 307.501L674.31 313.801Z'
                          fill='#8697B7'
                        />
                        <path
                          id='Vector_373'
                          opacity='0.6863'
                          d='M674.31 313.699C680.31 314.699 686.11 310.699 687.11 304.699L693.41 268.499C694.41 262.499 690.41 256.699 684.41 255.699L648.21 249.399C642.21 248.399 636.41 252.399 635.41 258.399L629.11 294.599C628.11 300.599 632.11 306.399 638.11 307.399L674.31 313.699Z'
                          fill='#8394B5'
                        />
                        <path
                          id='Vector_374'
                          opacity='0.7059'
                          d='M674.21 313.6C680.21 314.6 686.01 310.6 687.01 304.6L693.31 268.4C694.31 262.4 690.31 256.6 684.31 255.6L648.11 249.3C642.11 248.3 636.31 252.3 635.31 258.3L629.01 294.5C628.01 300.5 632.01 306.3 638.01 307.3L674.21 313.6Z'
                          fill='#7F91B3'
                        />
                        <path
                          id='Vector_375'
                          opacity='0.7255'
                          d='M674.11 313.5C680.11 314.5 685.91 310.5 686.91 304.5L693.21 268.3C694.21 262.3 690.21 256.5 684.21 255.5L648.01 249.2C642.01 248.2 636.21 252.2 635.21 258.2L628.91 294.4C627.91 300.4 631.91 306.2 637.91 307.2L674.11 313.5Z'
                          fill='#7C8EB1'
                        />
                        <path
                          id='Vector_376'
                          opacity='0.7451'
                          d='M674.01 313.4C680.01 314.4 685.81 310.4 686.81 304.4L693.11 268.2C694.11 262.2 690.11 256.4 684.11 255.4L647.91 249.1C641.91 248.1 636.11 252.1 635.11 258.1L628.81 294.3C627.81 300.3 631.81 306.1 637.81 307.1L674.01 313.4Z'
                          fill='#788BAF'
                        />
                        <path
                          id='Vector_377'
                          opacity='0.7647'
                          d='M674.01 313.301C680.01 314.301 685.81 310.301 686.81 304.301L693.11 268.101C694.11 262.101 690.11 256.301 684.11 255.301L647.91 249.001C641.91 248.001 636.11 252.001 635.11 258.001L628.81 294.201C627.81 300.201 631.81 306.001 637.81 307.001L674.01 313.301Z'
                          fill='#7588AC'
                        />
                        <path
                          id='Vector_378'
                          opacity='0.7843'
                          d='M673.91 313.199C679.91 314.199 685.71 310.199 686.71 304.199L693.01 267.999C694.01 261.999 690.01 256.199 684.01 255.199L647.81 248.899C641.81 247.899 636.01 251.899 635.01 257.899L628.71 294.099C627.71 300.099 631.71 305.899 637.71 306.899L673.91 313.199Z'
                          fill='#7185AA'
                        />
                        <path
                          id='Vector_379'
                          opacity='0.8039'
                          d='M673.81 313.1C679.81 314.1 685.61 310.1 686.61 304.1L692.91 267.9C693.91 261.9 689.91 256.1 683.91 255.1L647.71 248.8C641.71 247.8 635.91 251.8 634.91 257.8L628.61 294C627.61 300 631.61 305.8 637.61 306.8L673.81 313.1Z'
                          fill='#6D82A8'
                        />
                        <path
                          id='Vector_380'
                          opacity='0.8235'
                          d='M673.71 313C679.71 314 685.51 310 686.51 304L692.81 267.8C693.81 261.8 689.81 256 683.81 255L647.61 248.7C641.61 247.7 635.81 251.7 634.81 257.7L628.51 293.9C627.51 299.9 631.51 305.7 637.51 306.7L673.71 313Z'
                          fill='#6A7FA6'
                        />
                        <path
                          id='Vector_381'
                          opacity='0.8431'
                          d='M673.71 312.9C679.71 313.9 685.51 309.9 686.51 303.9L692.81 267.7C693.81 261.7 689.81 255.9 683.81 254.9L647.61 248.6C641.61 247.6 635.81 251.6 634.81 257.6L628.51 293.8C627.51 299.8 631.51 305.6 637.51 306.6L673.71 312.9Z'
                          fill='#667BA4'
                        />
                        <path
                          id='Vector_382'
                          opacity='0.8627'
                          d='M673.61 312.801C679.61 313.801 685.41 309.801 686.41 303.801L692.71 267.601C693.71 261.601 689.71 255.801 683.71 254.801L647.51 248.501C641.51 247.501 635.71 251.501 634.71 257.501L628.41 293.701C627.41 299.701 631.41 305.501 637.41 306.501L673.61 312.801Z'
                          fill='#6378A2'
                        />
                        <path
                          id='Vector_383'
                          opacity='0.8824'
                          d='M673.51 312.699C679.51 313.699 685.31 309.699 686.31 303.699L692.61 267.499C693.61 261.499 689.61 255.699 683.61 254.699L647.41 248.399C641.41 247.399 635.61 251.399 634.61 257.399L628.31 293.599C627.31 299.599 631.31 305.399 637.31 306.399L673.51 312.699Z'
                          fill='#5F75A0'
                        />
                        <path
                          id='Vector_384'
                          opacity='0.902'
                          d='M673.41 312.6C679.41 313.6 685.21 309.6 686.21 303.6L692.51 267.4C693.51 261.4 689.51 255.6 683.51 254.6L647.31 248.3C641.31 247.3 635.51 251.3 634.51 257.3L628.21 293.5C627.21 299.5 631.21 305.3 637.21 306.3L673.41 312.6Z'
                          fill='#5C729E'
                        />
                        <path
                          id='Vector_385'
                          opacity='0.9216'
                          d='M673.41 312.5C679.41 313.5 685.21 309.5 686.21 303.5L692.51 267.3C693.51 261.3 689.51 255.5 683.51 254.5L647.31 248.2C641.31 247.2 635.51 251.2 634.51 257.2L628.21 293.4C627.21 299.4 631.21 305.2 637.21 306.2L673.41 312.5Z'
                          fill='#586F9B'
                        />
                        <path
                          id='Vector_386'
                          opacity='0.9412'
                          d='M673.31 312.301C679.31 313.301 685.11 309.301 686.11 303.301L692.41 267.101C693.41 261.101 689.41 255.301 683.41 254.301L647.21 248.001C641.21 247.001 635.41 251.001 634.41 257.001L628.11 293.201C627.11 299.201 631.11 305.001 637.11 306.001L673.31 312.301Z'
                          fill='#556C99'
                        />
                        <path
                          id='Vector_387'
                          opacity='0.9608'
                          d='M673.21 312.199C679.21 313.199 685.01 309.199 686.01 303.199L692.31 266.999C693.31 260.999 689.31 255.199 683.31 254.199L647.11 247.899C641.11 246.899 635.31 250.899 634.31 256.899L628.01 293.099C627.01 299.099 631.01 304.899 637.01 305.899L673.21 312.199Z'
                          fill='#516997'
                        />
                        <path
                          id='Vector_388'
                          opacity='0.9804'
                          d='M673.11 312.1C679.11 313.1 684.91 309.1 685.91 303.1L692.21 266.9C693.21 260.9 689.21 255.1 683.21 254.1L647.01 247.8C641.01 246.8 635.21 250.8 634.21 256.8L627.91 293C626.91 299 630.91 304.8 636.91 305.8L673.11 312.1Z'
                          fill='#4E6695'
                        />
                        <path
                          id='Vector_389'
                          d='M673.11 312C679.11 313 684.91 309 685.91 303L692.21 266.8C693.21 260.8 689.21 255 683.21 254L647.01 247.7C641.01 246.7 635.21 250.7 634.21 256.7L627.91 292.9C626.91 298.9 630.91 304.7 636.91 305.7L673.11 312Z'
                          fill='#4A6393'
                        />
                      </g>
                      <path
                        id='Vector_390'
                        d='M672.41 310.799C678.31 311.799 683.91 307.899 685.01 301.999L691.21 266.499C692.21 260.599 688.31 254.999 682.41 253.899L646.91 247.699C641.01 246.699 635.41 250.599 634.31 256.499L628.11 291.999C627.11 297.899 631.01 303.499 636.91 304.599L672.41 310.799Z'
                        fill='url(#paint6_linear_475_685)'
                      />
                      <path
                        id='Vector_391'
                        d='M672.41 310.299C678.11 311.299 683.51 307.499 684.41 301.799L690.61 266.299C691.61 260.599 687.81 255.199 682.11 254.299L646.61 248.099C640.91 247.099 635.51 250.899 634.61 256.599L628.41 292.099C627.41 297.799 631.21 303.199 636.91 304.099L672.41 310.299Z'
                        fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      />
                    </g>
                    <text
                      id='Task 7'
                      transform='translate(641.497 256.316) rotate(9.89845)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[6].name && task[6].name.length > 3
                          ? truncateText(task[6].name, 4)
                          : task[6].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_392'
                      d='M669.61 299.999L644.31 295.599C640.71 294.999 638.41 291.599 639.01 287.999L639.41 285.899C640.01 282.299 643.41 279.999 647.01 280.599L672.31 284.999C675.91 285.599 678.21 288.999 677.61 292.599L677.21 294.699C676.51 298.199 673.11 300.599 669.61 299.999Z'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_7'
                      transform='translate(650.091 281.619) rotate(9.89845)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[6].counter + '/3'} */}
                        {(task[6]?.TasksAndStudents?.counter || 414) +
                          '/' +
                          (task[6]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            <g id='center'>
              <path
                id='Vector_393'
                d='M579.81 698.7L655.11 682.7L697.11 618.1L681.11 542.8L616.51 500.9L541.11 516.9L499.21 581.5L515.21 656.8L579.81 698.7Z'
                fill='url(#paint7_radial_475_685)'
              />
              <path
                id='Vector_394'
                d='M581.51 689.401L649.71 674.901L687.71 616.401L673.21 548.201L614.71 510.201L546.51 524.701L508.61 583.201L523.11 651.401L581.51 689.401Z'
                fill='url(#paint8_radial_475_685)'
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_475_685'
          x1='924.052'
          y1='633.622'
          x2='913.648'
          y2='690.481'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_475_685'
          x1='803.055'
          y1='854.383'
          x2='755.398'
          y2='887.092'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_475_685'
          x1='565.706'
          y1='925.874'
          x2='508.766'
          y2='915.915'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_475_685'
          x1='343.791'
          y1='805.495'
          x2='311.081'
          y2='757.838'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_475_685'
          x1='283.396'
          y1='513.416'
          x2='272.217'
          y2='570.125'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_475_685'
          x1='434.779'
          y1='316.681'
          x2='387.133'
          y2='349.408'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_475_685'
          x1='631.273'
          y1='274.493'
          x2='688.213'
          y2='284.429'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_475_685'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(598.081 599.833) rotate(167.994) scale(92.962 92.962)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_475_685'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(598.081 599.834) rotate(167.994) scale(84.1541 84.1541)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <clipPath id='clip0_475_685'>
          <rect width='1324' height='1192' fill='white' />
        </clipPath>
        <clipPath id='clip1_475_685'>
          <rect width='1582' height='1334' fill='white' transform='translate(10 -28)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LastTask
